import { DataSourceParams } from '../interfaces/data-source-params.interface';
import { TableDataSource } from './table-data-source.class';
import { HttpClient } from '@angular/common/http';
import { DataService } from '../../../services/data/data.service';
import { OfflineTableFilterService } from '../../../services/filters/offline-table-filter.service';

export class OfflineDataSource extends TableDataSource {
  constructor(protected http: HttpClient, protected dataService: DataService, protected offlineTableFilterService: OfflineTableFilterService) {
    super(http, dataService);
  }

  public loadData(params: DataSourceParams) {
    this.checkParams(params);

    if (params.sortProperty) {
      console.warn('A sort functionality is not implemented by OfflineDataSource yet');
    }

    this.loadingSubject.next(true);

    this.dataService
      .getPage(params.offlineStorageKey, params.page * params.size, params.size)
      .then((elements) => {
        const isArray = Array.isArray(elements);
        let data = isArray ? elements : elements.content;

        const filters = params.filters;
        if (filters && filters.length) {
          data = this.offlineTableFilterService.filterElements(data, filters);
        }

        this.dataSubject.next(data);
        if (!isArray) {
          this.totalNumberOfElementsSubject.next(elements.totalElements);
        }
        this.loadingSubject.next(false);
        this.dataInitializedSubject.next(true);
      })
      .catch((error) => {
        this.dataSubject.next([]);
        this.totalNumberOfElementsSubject.next(0);
        this.loadingSubject.next(false);

        throw `Error fetching online data. ${error}`;
      });
  }

  private checkParams(params: DataSourceParams) {
    if (params.page == undefined) throw 'No page provided in params!';
    if (params.size == undefined) throw 'No size provided in params!';
    if (params.offlineStorageKey == undefined) throw 'No offlineStorageKey provided in params!';
  }
}
