import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SortService {
  sortArrayOfObjectsAlphabetically(array: any[], parameterName: string): any[] {
    return array.sort((a, b) =>
      a[parameterName].localeCompare(b[parameterName]),
    );
  }
}
