import { Component, ComponentFactoryResolver, ComponentRef, forwardRef, Injector, Input, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ComponentTypesMap } from './component-types.map';
import { ControlValueAccessor, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FormField } from '../../interfaces/form-field.interface';
import { FormFieldTypeOptions } from '../../types/form-field-type-options.type';

@Component({
  selector: 'forms-control-factory',
  templateUrl: './controls-factory.component.html',
  entryComponents: Object.keys(ComponentTypesMap).map((key) => ComponentTypesMap[key]),
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ControlsFactoryComponent),
      multi: true,
    },
  ],
})
export class ControlsFactoryComponent implements OnInit, ControlValueAccessor {
  @ViewChild('controlContainer', { read: ViewContainerRef }) controlContainer: ViewContainerRef;
  @Input() type: FormFieldTypeOptions;
  @Input() form: FormGroup;
  @Input() formField: FormField;
  @Input() floatLabel: 'auto' | 'always' = 'auto';
  @Input() formValid: boolean;
  @Input() formControlName: string;
  @Input() set disabled(value: boolean) {
    this._disabled = value;
    this.setDisabledState(value);
  }
  public _disabled: boolean = false;
  private component: ComponentRef<any>;

  constructor(private resolver: ComponentFactoryResolver) {}

  ngOnInit() {
    let componentName = ComponentTypesMap[this.type];
    if (!componentName) return;

    let injector = Injector.create({ providers: [], parent: this.controlContainer.parentInjector });
    let factory = this.resolver.resolveComponentFactory(componentName);
    this.component = factory.create(injector);

    this.component.instance.form = this.form;
    this.component.instance.formField = this.formField;
    this.component.instance.floatLabel = this.floatLabel;
    this.component.instance.formValid = this.formValid;
    this.setDisabledState(this._disabled);

    this.controlContainer.insert(this.component.hostView);
  }

  writeValue(value: any) {
    if (!this.component) return;
    this.component.instance.writeValue(value);
  }

  registerOnChange(fn: any) {
    if (!this.component) return;
    this.component.instance.registerOnChange(fn);
  }

  registerOnTouched(fn: any) {
    if (!this.component) return;
    this.component.instance.registerOnTouched(fn);
  }

  setDisabledState(isDisabled: boolean) {
    if (!this.component) return;
    this.component.instance.setDisabledState(isDisabled);
  }
}
