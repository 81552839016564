import { Injectable } from '@angular/core';
import { BusinessRuleEditPage } from './business-rule-edit.page';
import { CanDeactivate } from '@angular/router';
import { AlertController } from '@ionic/angular';

@Injectable()
export class BusinessRuleEditCanDeactivate
  implements CanDeactivate<BusinessRuleEditPage>
{
  constructor(private alertController: AlertController) {}

  async canDeactivate(component: BusinessRuleEditPage): Promise<boolean> {
    if (await component.needsSave()) {
      return await new Promise<boolean>((accept) => {
        this.alertController
          .create({
            message: `Twoje zmiany nie zostały zapisane. Czy na pewno chcesz wyjść i je utracić?`,
            buttons: [
              {
                text: 'Nie',
                role: 'cancel',
                cssClass: 'secondary',
                handler: () => accept(false),
              },
              {
                text: 'Tak',
                handler: () => accept(true),
              },
            ],
          })
          .then((it) => it.present());
      });
    }
    return true;
  }
}
