import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login/login.component';
import { AuthService } from './auth.service';
import { LoginPage } from './page/login.page';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoginService } from './login/login.service';
import { IonicStorageModule } from '@ionic/storage';
import { AuthServerProvider } from './auth-oauth2.service';
import { Principal } from './principal.service';
import { StateStorageService } from './state-storage.service';
import { UserRouteAccessService } from './user-route-access-service';
import { AccountService } from './account.service';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    IonicStorageModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forChild([
      {
        path: '',
        component: LoginPage,
      },
    ]),
    SharedModule,
  ],
  declarations: [LoginComponent, LoginPage],
  entryComponents: [LoginPage],
  providers: [
    LoginService,
    AuthService,
    Principal,
    StateStorageService,
    UserRouteAccessService,
    AccountService,
    AuthServerProvider,
  ],
})
export class AuthModule {}
