import {FormField} from '../interfaces/form-field.interface';
import {ReferenceField} from "../interfaces/reference-field.interface";
import {ReferenceFieldRowFormat} from "../interfaces/reference-field-row-format.interface";

export class Utils {

  public static evaluateReferenceDisplayStrings(object: any, field: FormField): ReferenceField {
    const referenceField = new ReferenceField();
    referenceField.id = object.id;
    if (field.rowFormat) {
      referenceField.rowFormat = new ReferenceFieldRowFormat();
      referenceField.rowFormat.title = Utils.evaluateString(field.rowFormat.title, object);
      referenceField.rowFormat.subtitle = Utils.evaluateString(field.rowFormat.subtitle, object);
      referenceField.rowFormat.additionalInformation = Utils.evaluateString(field.rowFormat.additionalInformation, object);
    }
    if (field.dataViewColumn) {
      referenceField.dataViewColumn = object[field.dataViewColumn];
    }
    return referenceField;
  }

  public static evaluateString(str: string, object: Object): string {
    str = this.evaluateVariablesString(str, object);
    return this.evaluateConditionString(str);
  }

  public static evaluateDisplayStrings(object: ReferenceField, field: FormField): ReferenceField {
    if (field.rowFormat) {

      object.rowFormat.title = Utils.evaluateString(field.rowFormat.title, object);
      object.rowFormat.subtitle = Utils.evaluateString(field.rowFormat.subtitle, object);
      object.rowFormat.additionalInformation = Utils.evaluateString(field.rowFormat.additionalInformation, object);
    }

    return object;
  }

  public static extractTextFromHtmlTag(s: string): string {
    const span = document.createElement('span');
    span.innerHTML = s;
    return span.textContent || span.innerText;
  }

  private static evaluateVariablesString(str: string, object: Object): string {
    const VARIABLE_REG_EXP = /\$\{[a-zA-Z0-9._]*\}/g;

    const literals = (str || '').match(VARIABLE_REG_EXP);

    if (!literals) {
      return str;
    }

    literals.forEach((literal: string) => {
      const variablePath = literal.replace('${', '').replace('}', '');
      let variable = object;
      variablePath.split('.').forEach((param) => {
        if (variable[param] !== undefined) {
          variable = variable[param];
        }
      });
      str = str.replace(literal, variable as string);
    });

    return str;
  }

  private static evaluateConditionString(str: string): string {
    if (!str) {
      return '';
    }

    const CONDITION_REG_EXP = /IF\s*\(.*\)\s*THEN/;
    const IF_TRUE_REG_EXP = /THEN\s*\(.*\)\s*ELSE/;
    const IF_FALSE_REG_EXP = /ELSE\s*\(.*\)$/;

    let condition = str.match(CONDITION_REG_EXP) || '';
    condition = condition && condition[0];

    if (!condition) {
      return str;
    }

    condition = condition.replace(/IF\s*\(/, '').replace(/\)\s*THEN/, '');

    let strIfTrue = str.match(IF_TRUE_REG_EXP) || '';
    strIfTrue = strIfTrue && strIfTrue[0].replace(/THEN\s*\(/, '').replace(/\)\s*ELSE/, '');

    let strIfFalse = str.match(IF_FALSE_REG_EXP) || '';
    strIfFalse = strIfFalse && strIfFalse[0].replace(/ELSE\s*\(/, '').replace(/\)$/, '');

    return condition != 'undefined' ? strIfTrue : strIfFalse;
  }

  public static extractFieldNameFromStringLiteralValue(value: string) {
    const values = value.replace('${', '').replace('}', '').split('.');
    return values[values.length - 1];
  }

  public static copyArray(array: any[]): any[] {
    return [...array];
  }
}
