import { Component, Input, OnInit } from '@angular/core';
import { map, startWith } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-visibility-select',
  templateUrl: './visibility-select.component.html',
  styleUrls: ['./visibility-select.component.scss'],
})
export class VisibilitySelectComponent implements OnInit {
  @Input() public type: string;
  @Input() private entriesInput;
  @Input() private entries;
  public searchControl = new FormControl();
  public selectedEntries = [];
  public filteredEntries: Observable<any>;

  constructor(private modalCtrl: ModalController) {}

  ngOnInit() {
    this.setSelectedEntries();
    this.setFilterUpate();
  }

  private setFilterUpate() {
    this.filteredEntries = this.searchControl.valueChanges.pipe(
      startWith(''),
      map((filter) => this.filter(filter)),
    );
  }

  private setSelectedEntries() {
    if (this.entriesInput && this.entriesInput.length) {
      this.selectedEntries = [...this.entriesInput];
    }
  }

  private filter(filter: string) {
    if (filter) {
      const searchFraze = this.adjustStringToSearch(filter);
      return this.entries.filter((option) => {
        if (this.type === 'users' && option.firstName && option.lastName) {
          const name = this.adjustStringToSearch(option.firstName);
          const lastName = this.adjustStringToSearch(option.lastName);
          const fullName = name + lastName;
          return fullName.indexOf(searchFraze) >= 0;
        } else if (this.type === 'groups' && option.name) {
          return this.adjustStringToSearch(option.name).indexOf(searchFraze) >= 0;
        } else {
          return false;
        }
      });
    } else {
      return this.entries && this.entries.slice();
    }
  }

  adjustStringToSearch(string): string {
    return string
      .toLowerCase()
      .replace('ę', 'e')
      .replace('ó', 'o')
      .replace('ą', 'a')
      .replace('ś', 's')
      .replace('ł', 'l')
      .replace('ż', 'z')
      .replace('ź', 'z')
      .replace('ć', 'c')
      .replace('ń', 'n')
      .replace(' ', '');
  }

  public displayFn(): string | undefined {
    return this.searchControl ? this.searchControl.value : '';
  }

  optionClicked(event: Event, entry) {
    event.stopPropagation();
    this.toggleSelection(entry);
  }

  toggleSelection(entry) {
    if (this.isEntrySelected(entry)) {
      const i = this.selectedEntries.findIndex((selectedEntry) => selectedEntry.id === entry.id);
      this.selectedEntries.splice(i, 1);
    } else {
      this.selectedEntries.push(entry);
    }
  }

  public isEntrySelected(entry) {
    return this.selectedEntries.findIndex((selectedEntry) => selectedEntry.id === entry.id) !== -1;
  }

  getEntryName(entry): string {
    if (this.type === 'users') {
      return entry.firstName + ' ' + entry.lastName;
    } else if (this.type === 'groups') {
      return entry.name;
    }
  }

  public cancel() {
    this.modalCtrl.dismiss();
  }

  public save() {
    this.modalCtrl.dismiss({ selected: this.selectedEntries });
  }
}
