import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SimpleFilterSet } from '../../../interfaces/simple-filter-set.interface';
import { SimpleFilter } from '../../../interfaces/simple-filter.interface';

@Component({
  selector: 'sellions-layout-renderer-simple-filters-dialog',
  templateUrl: './simple-filters-dialog.component.html',
})
export class SimpleFiltersDialogComponent implements OnInit {
  public form: FormGroup;
  public filters: { [name: string]: SimpleFilter } = {};
  public controlNames: string[] = [];

  constructor(public dialogRef: MatDialogRef<SimpleFiltersDialogComponent>, @Inject(MAT_DIALOG_DATA) public set: SimpleFilterSet) {}

  ngOnInit() {
    this.createForm(this.set);
  }

  private createForm(set: SimpleFilterSet) {
    this.form = new FormGroup({});
    this.controlNames = Object.keys(set);

    for (let name of this.controlNames) {
      this.filters[name] = set[name];
      this.form.addControl(name, new FormControl(set[name].value));
    }
  }

  public applyFilters() {
    this.dialogRef.close(this.mapFormToSimpleFilterSet());
  }

  private mapFormToSimpleFilterSet(): SimpleFilterSet {
    const filterSet: SimpleFilterSet = {};
    const formValue = this.form.getRawValue();

    for (let name of this.controlNames) {
      const filter = this.filters[name];

      filterSet[name] = {
        ...filter,
        value: this.getValueAsString(formValue[name]),
      };
    }

    return filterSet;
  }

  public clearControl(name: string) {
    this.form.get(name).patchValue('');
  }

  private getValueAsString(value: any): string {
    if (value.toISOString) return value.toISOString();
    return value.toString();
  }

  public close() {
    this.dialogRef.close();
  }
}
