import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';

import { AuthService } from './auth.service';
import { Observable } from 'rxjs';

@Injectable()
export class UserRouteAccessService implements CanActivate, CanActivateChild {
  constructor(private router: Router, private auth: AuthService) {}

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.auth.authorize(false);
  }

  canActivate(route: any): Promise<boolean> {
    return new Promise((resolve) => this.auth.authorize(false).then((r) => resolve(r && this.hasAuthorities(route))));
  }

  private hasAuthorities(route: ActivatedRouteSnapshot) {
    return !route || !route.data.authorities || typeof route.data.authorities.find((a) => this.auth.identity.authorities.includes(a)) !== 'undefined';
  }
}
