import { ReferenceFieldRowFormat } from './reference-field-row-format.interface';
import { SubForm } from './sub-form.interface';

export class ReferenceField {
  id: number | string;
  dataViewColumn: string;
  rowFormat: ReferenceFieldRowFormat;
  subForm?: SubForm;
  disabled?: boolean;
}
