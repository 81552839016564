const typeLabels = {
  textarea: 'Pole tekstowe',
  radio: 'Jednokrotny wybór',
  checkbox: 'Wielokrotny wybór',
  dropdown: 'Wybór z listy',
  number: 'Liczba',
  dataId: 'Dana z procesu',
  multipartFiles: 'Plik',
  list: 'Formularz zagnieżdżony',
  bool: 'Wartość logiczna',
  datepicker: 'Data',
  text: 'Tekst',
  integer: 'Liczba całkowita',
  floating: 'Liczba zmiennoprzecinkowa',
  phoneNumber: 'Numer telefonu',
  email: 'Email',
  link: 'Link',
  nip: 'NIP',
  currency: 'Kwota',
  timepicker: 'Godzina',
  localization: 'Lokalizacja',
};

export { typeLabels };
