import { Injectable } from '@angular/core';
import { MailTemplateService } from '../mail-template.service';
import { Router } from '@angular/router';

declare var grapesjs: any;

@Injectable({
  providedIn: 'root',
})
export class PluginService {
  constructor(
    private mailTemplateService: MailTemplateService,
    private router: Router,
  ) {}

  editor: any;

  registerPlugin() {
    grapesjs.plugins.add('engine', (editor, options) => {
      this.editor = editor;
    });
  }

  save(name: string, plainText: string, html: string, id?: number) {
    this.mailTemplateService
      .createMailTemplate(name, this.editor.getHtml(), html, plainText, id)
      .subscribe(() => this.router.navigate(['/mail']));
  }

  load(mjml: string) {}
}
