export class DataLayout {
  public constructor(
    public displayedFields: Array<Field>,
    public name?: string,
    public imageUrl?: (data) => any,
    public grayed?: (data) => boolean,
    public imageBytes?: (data) => any,
  ) {}
}

export class Field {
  public constructor(
    public title: string,
    public field: string,
    public func?: (data) => any,
  ) {}
}
