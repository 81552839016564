import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CopyService {
  copy(subject: any): any {
    return JSON.parse(JSON.stringify(subject));
  }
}
