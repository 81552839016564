import { Component, OnInit } from '@angular/core';

import { Platform } from '@ionic/angular';
import { Router, RoutesRecognized } from '@angular/router';
import { StateStorageService } from './auth/state-storage.service';
import { PluginService } from './mail/grapejs/plugin.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
})
export class AppComponent implements OnInit {
  constructor(
    private platform: Platform,
    private router: Router,
    private storageService: StateStorageService,
    private grapejsPlugin: PluginService,
  ) {
    this.grapejsPlugin.registerPlugin();
  }

  ngOnInit() {
    this.router.events.subscribe((event) => {
      if (event instanceof RoutesRecognized) {
        let params = {};
        let destinationData = {};
        let destinationEvent = event.state.root.firstChild.children[0];
        if (destinationEvent !== undefined) {
          params = destinationEvent.params;
          destinationData = destinationEvent.data;
        }
        let from = { name: this.router.url.slice(1) };
        let destination = { name: event.url, data: destinationData };
        this.storageService.storeDestinationState(destination, params, from);
      }
    });
  }
}
