import { Group } from '../interfaces/group.interface';
import { HttpClient } from '../../../../node_modules/@angular/common/http';
import { HttpService } from '../../shared/http.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class GroupsService {
  constructor(private http: HttpClient, private httpService: HttpService) {}

  getGroups(request) {
    return this.http.get<Array<Group>>('api/group', {
      params: this.httpService.getParams(request),
      observe: 'response',
    });
  }

  public getGroupsNoParams() {
    return this.http.get<Array<Group>>('api/group');
  }
}
