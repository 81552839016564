import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { BusinessRuleDTO } from './business-rule.dto';

@Injectable({
  providedIn: 'root',
})
export class BusinessRuleService {
  constructor(private http: HttpClient) {
    (window as any).businessRuleService = this;
  }

  getBusinessRules(): Observable<Array<BusinessRuleDTO>> {
    return this.http.get<Array<BusinessRuleDTO>>('api/business-rule');
  }

  getNewBusinessRuleDmn(): Observable<string> {
    return this.http.get('/assets/dmn_template.xml', { responseType: 'text' });
  }

  getBusinessRuleDmn(key: string): Observable<string> {
    return this.http.get<string>(
      `api/business-rule/${encodeURIComponent(key)}/dmn`,
      { responseType: 'text' as 'json' },
    );
  }

  saveBusinessRuleDmn(key: string, dmn: string): Observable<void> {
    return this.http.post<void>(
      `api/business-rule/${encodeURIComponent(key)}/dmn`,
      dmn,
    );
  }

  deleteBusinessRule(key: string): Observable<void> {
    return this.http.delete<void>(
      `api/business-rule/${encodeURIComponent(key)}`,
    );
  }

  isBusinessRuleExists(key: string): Observable<boolean> {
    return this.http.get<boolean>(
      `api/business-rule/${encodeURIComponent(key)}/exists`,
    );
  }
}
