import * as moment from 'moment';

export class DisplayFunctions {
  public static getRemainingDays(str: string): string {
    const diff = new Date(str).getTime() - new Date().getTime();
    const days = Math.ceil(diff / (24 * 60 * 60 * 1000));
    let daysWord = 'dni';
    if (days === 1) {
      daysWord = 'dzień';
    }
    return `${days} ${daysWord}`;
  }

  public static getDateWithoutHours(str: string): string {
    return str.substring(0, 10);
  }

  public static getStatus(str: string): string {
    switch (str.toUpperCase()) {
      case 'PENDING': {
        return 'oczekujące';
      }
      case 'SENT': {
        return 'wysłane';
      }
      case 'CANCELED': {
        return 'anulowane';
      }
      default: {
        return 'oczekujące';
      }
    }
  }

  public static getStatusStyle(str: string): string {
    switch (str.toUpperCase()) {
      case 'PENDING': {
        return 'waiting';
      }
      case 'SENT': {
        return 'sent';
      }
      case 'CANCELED': {
        return 'canceled';
      }
      default: {
        return 'waiting';
      }
    }
  }

  public static getTypeStyle(str: string): string {
    switch (str.toUpperCase()) {
      case 'TOP SELLER': {
        return 'top-seller';
      }
      case 'NOWOŚĆ': {
        return 'novelty';
      }
      case 'PROMOCJA': {
        return 'promotion';
      }
      case 'KOMUNIKAT': {
        return 'announcement';
      }
      default: {
        return '';
      }
    }
  }

  public static getDisplayValue(param: any, type = 'inline'): string {
    return !param || ['NaN', 'undefined', 'null', '0'].indexOf(param) > -1 ? 'none' : type;
  }

  public static getPolishPriceFormat(str: string): string {
    if (!str) {
      return '';
    }

    const formatter = new Intl.NumberFormat('pl-PL', {
      style: 'currency',
      currency: 'PLN',
    });

    return formatter.format(Number(str));
  }

  public static formatDateDDMMYYY(date: string): string {
    if (moment(date).isValid()) return moment(date).format('DD.MM.YYYY').toString();
    else return '-';
  }

  public static isDateGreaterThanNow(date: string): boolean {
    return moment(date).isAfter(moment());
  }
}
