import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { BusinessRuleListPage } from './business-rule-list.page';
import { IonicModule } from '@ionic/angular';
import { SharedModule } from '../shared/shared.module';
import { CardViewModule } from '../card-view/card-view.module';
import { BusinessRuleEditPage } from './business-rule-edit.page';
import { BusinessRuleEditCanDeactivate } from './business-rule-edit-can-deactivate';

@NgModule({
  imports: [
    IonicModule,
    SharedModule,
    CardViewModule,
    RouterModule.forChild([
      {
        path: '',
        component: BusinessRuleListPage,
      },
      {
        path: 'create',
        component: BusinessRuleEditPage,
        canDeactivate: [BusinessRuleEditCanDeactivate],
      },
      {
        path: ':id/edit',
        component: BusinessRuleEditPage,
        canDeactivate: [BusinessRuleEditCanDeactivate],
      },
    ]),
  ],
  declarations: [BusinessRuleListPage, BusinessRuleEditPage],
  providers: [BusinessRuleEditCanDeactivate],
  entryComponents: [BusinessRuleEditPage],
  exports: [BusinessRuleEditPage],
})
export class BusinessRulesModule {}
