import { BehaviorSubject, Observable } from 'rxjs';
import { DataService } from '../../../services/data/data.service';
import { DataSource } from '@angular/cdk/table';
import { HttpClient } from '@angular/common/http';
import { TableColumn } from '../table-column.model';
import { CollectionViewer } from '@angular/cdk/collections';
import { DataSourceParams } from '../interfaces/data-source-params.interface';

export abstract class TableDataSource implements DataSource<any> {
  protected dataSubject = new BehaviorSubject<any[]>([]);
  protected loadingSubject = new BehaviorSubject<boolean>(false);
  protected totalNumberOfElementsSubject = new BehaviorSubject<number>(0);
  protected dataInitializedSubject = new BehaviorSubject<boolean>(false);

  public loading$ = this.loadingSubject.asObservable();
  public totalNumberOfElements$ = this.totalNumberOfElementsSubject.asObservable();
  public dataInitialized$ = this.dataInitializedSubject.asObservable();

  constructor(protected http: HttpClient, protected dataService: DataService) {}

  public connect(collectionViewer: CollectionViewer): Observable<any[]> {
    return this.dataSubject.asObservable();
  }

  public disconnect(collectionViewer: CollectionViewer): void {
    this.dataSubject.complete();
    this.loadingSubject.complete();
    this.totalNumberOfElementsSubject.complete();
  }

  public loadData(params: DataSourceParams): void {
    //implementations for each type (input, mixed, offline, online) are available in child classes
    throw 'loadData needs an implementation within a child class!';
  }

  public getData(): any[] {
    return this.dataSubject.value;
  }

  public getDataElement(index: number): any {
    return this.dataSubject.value[index];
  }

  public addEmptyRow() {
    this.dataSubject.next([...this.dataSubject.value, {}]);
  }

  public remove(index: number) {
    const data = this.dataSubject.value;
    data.splice(index, 1);
    this.dataSubject.next(data);
  }

  public initializeUneditableFields(uneditableFieldNames: string[]) {
    const data = this.dataSubject.value;

    data.forEach((element) => {
      element.initialValues = [];
      uneditableFieldNames.forEach((field) => {
        if (element[field]) element.initialValues.push(field);
      });
    });

    this.dataSubject.next(data);
  }

  public getDropdownValues(column: TableColumn): { value: any }[] {
    const data = this.dataSubject.value;

    if (column.type === 'single_dropdown') {
      return column.cell.filter((cell: { value: any }) => data.map((element: any) => element[column.field]).indexOf(cell.value) === -1);
    }

    return column.cell;
  }

  public updateNestedData(nestedData: any, nestedRowIndex: number): void {
    const data = this.dataSubject.value;
    data[nestedRowIndex].fields = nestedData;
    this.dataSubject.next(data);
  }
}
