export const environment = {
  production: true,
  realmUrl: 'https://crh-kc.sellions.com/auth/realms/crh',
  authUrl: 'https://crh-kc.sellions.com/auth/realms/crh/protocol/openid-connect/token',
  consoleUrl: 'https://crh-kc.sellions.com/auth/admin/crh/console',
  secret: '1c32143a-03e4-49b4-93c2-c5293fbfd132',
  authClient: 'sellions-ionic',
  engineUrl: 'https://crh-engine.app.sellions.com',
  backendUrl: 'https://crh.app.sellions.com',
  scope: null
};
