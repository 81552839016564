import { BaseFieldComponent } from '../base-field/base-field.component';
import { Component, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import * as moment from 'moment';
import { DATE_FORMATS } from '../../../constants/date-format';

@Component({
  selector: 'forms-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatepickerComponent),
      multi: true,
    },
  ],
})
export class DatepickerComponent extends BaseFieldComponent {
  writeValue(value: any): void {
    // Workaround for lack of milliseconds for existing dates due to changes in Engine -> introduction of date pattern: "yyyy-MM-dd'T'HH:mm:ss.SSSX"
    // e.g.: Engine has already date: 2022-09-20T00:00:00Z which is used as input for editing better offer.
    // Because the user doesn't change the value, sellions_forms will try to send an unchanged value which from Engine perspective is incorrect
    const iso8601Date = moment(value).format(DATE_FORMATS.parse.dateInput);
    super.writeValue(iso8601Date);
    setTimeout(() => this.onChange(iso8601Date));
  }
}
