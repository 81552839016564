import { Action } from '../interfaces/action.interface';
import { Component, ComponentFactoryResolver, ElementRef, EventEmitter, Input, Output, Type } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { ReportService } from '../../../services/report/report.service';
import { Router } from '@angular/router';
import { TableColumn } from '../table-column.model';
import { TableDataSource } from '../data-sources/table-data-source.class';
import { take } from 'rxjs/operators';
import { Utils } from '../../../utils/utils.class';

@Component({
  selector: 'sellions-layout-renderer-table-cell',
  templateUrl: './cell.component.html',
  styleUrls: ['./cell.component.scss'],
})
export class TableCellComponent {
  @Input() column: TableColumn;
  @Input() row: any;
  @Input() rowIndex: number; // only used once
  @Input() element: any;
  @Input() disabled: boolean = false;
  @Input() dataSource: TableDataSource;
  @Input() editable: boolean = false;
  @Input() expandedRow: any;
  @Output() onChange = new EventEmitter<string>();
  @Output() onRemove = new EventEmitter<number>();
  @Output() onRemoveWithPopup = new EventEmitter<number>();
  @Output() onAction = new EventEmitter<Action>();
  @Output() reload = new EventEmitter<void>();
  @Output() expand = new EventEmitter<number>();

  public editing: boolean = false;
  public maxTextLength: number = 25;

  private filteredDropdownValues = {}

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private dialog: MatDialog,
    private reportService: ReportService,
    private host: ElementRef,
    private router: Router,
    private http: HttpClient,
  ) {}

  ngAfterContentInit() {
    this.calculateMaxTextLength();
    window.addEventListener('resize', () => this.calculateMaxTextLength(), true);
  }

  // I'm worried about this eval. Isn't it a security threat?
  public evaluateWithReturn(command: string, row: any) {
    if (!command) return;

    return eval(command)(row);
  }

  public reloadData() {
    this.reload.emit();
  }

  // the same concerns as with evaluateWithReturn
  public evaluate(command: string, row: any, rowIndex: number, processKey: string) {
    if (this.isCellMethod(command)) eval(command)(row, rowIndex);
    else this.onAction.emit({ name: command, row, processKey });
  }

  private isCellMethod(command: string): boolean {
    return command.startsWith('this.') || this.isBigArrowMethodThatReferenceCellMethod(command);
  }

  private isBigArrowMethodThatReferenceCellMethod(command: string): boolean {
    const regex = /\(.+\) => this\./;
    return command.match(regex) !== null;
  }

  public shouldBlock(row: any, column: TableColumn) {
    if (!column.block) return false;

    return this.evaluateWithReturn(column.block, row);
  }

  public toggleEditing() {
    if (this.disabled || this.shouldBlock(this.element, this.column) || this.shouldBeUneditable()) return;

    this.editing = !this.editing;
  }

  public shouldBeUneditable(): boolean {
    return !this.editable || (this.column.uneditableIfInitialValue && this.isInitialValue(this.column.field));
  }

  private isInitialValue(fieldName: string): boolean {
    const initialValues = this.element.initialValues;
    return initialValues && initialValues.includes(fieldName);
  }

  public focusChildInput(node: HTMLElement) {
    setTimeout(() => {
      const parent = node.parentNode as HTMLElement;
      const siblings = parent.firstElementChild.childNodes;
      const input = Array.from(siblings).find((node) => node['classList'] && node['classList'].contains('input')) as HTMLInputElement;

      if (input) input.focus();
    });
  }

  // what are values and value types?
  public getDropdownLabel(values: any[], value: any): string {
    let element = values.filter((v) => v.value === value)[0];
    return element && element.label;
  }

  // todo: types for parameters
  public openModal(cellDefinition, row) {
    if (cellDefinition.component) {
      const factories = Array.from(this.componentFactoryResolver['_factories'].keys());
      const factoryClass = <Type<any>>factories.find((x: any) => x.name === cellDefinition.component);
      const data = {};
      data[cellDefinition.input] = { ...row };
      const dialogRef = this.dialog.open(factoryClass, {
        data: data,
      });
      dialogRef
        .afterClosed()
        .pipe(take(1))
        .subscribe((data) => {
          if (data) eval(cellDefinition.process)(data, row);
        });
    }
  }

  public getDropdownValues(column: TableColumn): any[] {
    return this.dataSource.getDropdownValues(column);
  }

  public setFilteredDropdownValues(column: TableColumn, value: any) {
    if(typeof value === 'string' || value instanceof String) {
      this.filteredDropdownValues[column.field] = this.getDropdownValues(column).filter(v => v.label.toLowerCase().includes(value.toLowerCase()))
      return;
    }

    this.filteredDropdownValues[column.field] = this.getDropdownValues(column).filter(v => v.label.toLowerCase().includes(value.target.value.toLowerCase()))
  }

  public getFilteredDropdownValues(column: TableColumn): any[] {
    let filteredDropdownValue = this.filteredDropdownValues[column.field];

    if (!filteredDropdownValue)
      return this.getDropdownValues(column);

    return filteredDropdownValue;
  }

  public setElement(field: any, option: any) {
    this.column[field] = option.value
    this.row[field] = option.value
  }

  // can we establish parameter types?
  public getComponentButtonLabel(value, data) {
    const labelEnding = value.componentButtonSymbol ? value.componentButtonSymbol : '...';
    let labelContent = value.componentButtonValues ? Utils.evaluateVariablesString(value.componentButtonValues, data) : '';
    if (
      labelContent.toLowerCase().includes('[object object]') ||
      labelContent.toLowerCase().includes('null') ||
      labelContent.toLowerCase().includes('undefined')
    ) {
      labelContent = value.componentButtonNoValuesMessage;
    }
    return '<span class="label-content">' + labelContent + '</span> ' + labelEnding;
  }

  public navigate(commands: string[], opts = {}) {
    this.router.navigate(commands, opts);
  }

  public remove(index: number) {
    this.onRemove.emit(index);
  }

  public removeWithPopup(index: number) {
    this.onRemoveWithPopup.emit(index);
  }

  public download(report: { url: string; name: string }, headers = { Accept: 'text/csv' }, popup: { errorHeader: string; errorMessage: string }) {
    this.reportService.download(report, headers, popup);
  }

  public calculateMaxTextLength() {
    let hostElement: HTMLElement = this.host.nativeElement;
    let containers = hostElement.getElementsByClassName('cell-container');
    let container: HTMLElement = containers && (containers[0] as HTMLElement);
    let width = container && container.clientWidth;
    let fontSize: number = (container && Number(container.style.fontSize)) || 12;

    // multiplying by a factor of 1.5 established experimentally, feel free to change
    this.maxTextLength = Math.floor((width / fontSize) * 1.5) || 25;
  }

  public onExpand() {
    this.expand.emit(this.row);
  }
}
