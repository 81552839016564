import { HttpClient } from '../../../../node_modules/@angular/common/http';
import { HttpService } from '../../shared/http.service';
import { Injectable } from '@angular/core';
import { User } from '../interfaces/user.interface';

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  constructor(private http: HttpClient, private httpService: HttpService) {}

  getUsers(request) {
    return this.http.get<Array<User>>('api/users', {
      params: this.httpService.getParams(request),
      observe: 'response',
    });
  }

  public getUsersNoParams() {
    return this.http.get<Array<User>>('api/users');
  }
}
