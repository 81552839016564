import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { MainComponent } from './main.component';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  imports: [CommonModule, RouterModule, IonicModule, SharedModule],
  declarations: [MainComponent],
  entryComponents: [MainComponent],
  exports: [],
})
export class MenuModule {}
