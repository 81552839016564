import { BaseFieldErrorStateMatcher } from './base-field-error-state-matcher';
import { ControlValueAccessor, FormGroup } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { FormField } from '../../../interfaces/form-field.interface';
import { Input } from '@angular/core';

export abstract class BaseFieldComponent implements ControlValueAccessor {
  @Input() form: FormGroup;
  @Input() formField!: FormField;
  @Input() floatLabel: 'auto' | 'always' = 'auto';
  @Input() formValid: boolean;

  protected onChange: any = () => {};
  protected onTouch: any = () => {};
  protected isActive: boolean = false;

  protected errorStateMatcher: ErrorStateMatcher = new BaseFieldErrorStateMatcher(() => !this.isValidField());

  public disabled = false;
  public _value: any;
  public set value(val: any) {
    if (val !== undefined && this._value !== val) {
      this._value = val;
      this.onChange(val);
      this.onTouch(val);
    }
  }

  public get value(): any {
    return this._value;
  }

  isValidField(): boolean {
    if (this.isActive || !this.formField) {
      return true;
    }

    const id = this.formField.id;
    if (this.isFormControlNotExists(id) || this.form.controls[id].untouched) {
      return true;
    }

    return this.form.controls[id].valid;
  }

  private isFormControlNotExists(id: string): boolean {
    return !this.form || !this.form.controls || !this.form.controls[id];
  }

  setActive() {
    this.isActive = true;
  }

  setInactive() {
    this.isActive = false;
  }

  writeValue(value: any): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  getErrorMessage(): string {
    const formControl = this.form.controls[this.formField.id];
    if (formControl.hasError('required')) {
      return 'Pole jest wymagane';
    }

    if (formControl.hasError('pattern')) {
      return `Pole jest niezgodne ze wzorcem (wyrażeniem regularnym): ${formControl.errors.pattern.requiredPattern}`;
    }

    if (formControl.hasError('minlength')) {
      return `Pole powinno zawierać więcej znaków niż: ${formControl.errors.minlength.requiredLength}. Aktualna długość: ${formControl.errors.minlength.actualLength}`;
    }

    if (formControl.hasError('maxlength')) {
      return `Pole powinno zawierać mniej znaków niż: ${formControl.errors.maxlength.requiredLength}. Aktualna długość: ${formControl.errors.maxlength.actualLength}`;
    }

    if (formControl.hasError('min')) {
      return `Wartość powinna być większa niż: ${formControl.errors.min.min}. Aktualnie wynosi: ${formControl.errors.min.actual}`;
    }

    if (formControl.hasError('max')) {
      return `Wartość powinna być mniejsza niż: ${formControl.errors.max.max}. Aktualnie wynosi: ${formControl.errors.max.actual}`;
    }

    return 'Pole wypełniono niepoprawnie';
  }
}
