import { Credentials } from './credentials';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Storage } from '@ionic/storage';

@Injectable()
export class AuthServerProvider {
  private authUrl = environment.authUrl;
  private secret = environment.secret;
  private authClient = environment.authClient;
  private scope = environment.scope;

  constructor(private http: HttpClient, private storage: Storage) {
  }

  public getAccessToken() {
    return this.storage.get('authenticationToken');
  }

  public login(credentials: Credentials): Observable<any> {
    let data =
        'username=' +
        encodeURIComponent(credentials.username) +
        '&password=' +
        encodeURIComponent(credentials.password) +
        '&grant_type=password&' +
        'client_secret=' +
        this.secret +
        '&client_id=' +
        this.authClient +
        (this.scope ? '&scope=' + this.scope : '');

    let headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      Accept: 'application/json',
      Authorization: 'Basic ' + btoa('sellionsapp' + ':' + this.secret),
    });

    return this.http.post(this.authUrl, data, {headers: headers}).pipe(map((token) => this.saveToken(token)));
  }

  private saveToken(token) {
    const expiredAt = new Date();
    expiredAt.setSeconds(expiredAt.getSeconds() + token.expires_in);
    token.expires_at = expiredAt.getTime();
    this.storage.set('authenticationToken', token);

    const credentialsData = {
      accessToken: token.access_token,
      expiresAt: token.expires_at,
      engineUrl: environment.engineUrl,
      backendUrl: environment.backendUrl || undefined
    } as CredentialsData;
    localStorage.setItem('credentialsData', JSON.stringify(credentialsData));

    return token;
  }

  logout(): Observable<any> {
    return new Observable((observer) => {
      this.http.post('api/logout', {});
      this.storage
          .remove('authenticationToken')
          .then(() => observer.complete());
      localStorage.removeItem('credentialsData');
    });
  }
}

export interface CredentialsData {
  accessToken: string;
  expiresAt: number;
  engineUrl: string;
  backendUrl: string;
}

