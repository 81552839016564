import { AnalyticalSpaceCreateAndEditComponent } from './create/analytical-space-create-and-edit.component';
import { AuthServerProvider } from '../auth/auth-oauth2.service';
import { Component, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Router } from '@angular/router';
import { TableConfig } from '../../../sellions_layout_renderer/projects/sellions-layout-renderer-lib/src/lib/components/table/interfaces/table-config.interface';
import { TableRendererComponent } from '../../../sellions_layout_renderer/projects/sellions-layout-renderer-lib/src/lib/components/table/table.component';

@Component({
  selector: 'analytical-spaces',
  templateUrl: './analytical-spaces.page.html',
  styleUrls: ['./analytical-spaces.page.scss'],
})
export class AnalyticalSpacesPage {
  @ViewChild('table') table: TableRendererComponent;
  public config: TableConfig;
  public buttonList = [{ object: this, name: 'Utwórz', callback: this.create, id: 'analyticalSpacesCreate' }];

  constructor(private authServerProvider: AuthServerProvider, private modalController: ModalController, private router: Router) {}

  ngOnInit() {
    this.authServerProvider.getAccessToken().then((token) => {
      this.config = {
        dataSourceConfig: {
          operationType: 'ONLINE',
          onlineDataSourceUrl: 'api/analytical-spaces/page',
          onlineDataSourceDeleteKey: '${element.id}',
          offlineStorageKey: 'analytical-spaces',
          token: token,
        },
        displayConfig: {
          rowLayoutType: 'SimpleTextRowSmallImg',
          columns: [
            {
              label: 'Nazwa',
              value: '${element.name}',
              type: 'string',
              filter: 'name',
              filterPlaceholder: 'Nazwa',
              sortId: 'name',
              id: 'analyticalSpacesName',
            },
            {
              label: 'Opis',
              value: '${element.description}',
              type: 'string',
              sortId: 'description',
              id: 'analyticalSpacesDescription',
            },
            {
              label: 'Grupy',
              value: '${element.groups}',
              type: 'string',
              id: 'analyticalSpacesGroup',
            },
            {
              label: 'Użytkownicy',
              value: '${element.people}',
              type: 'string',
              filter: 'login',
              filterPlaceholder: 'Użytkownik',
              id: 'analyticalSpacesUser',
            },
            {
              label: 'Akcje',
              value: {
                Edytuj: "(row, rindex) => this.navigate(['analytical-spaces', row.id, 'edit'])",
                Usuń: '(row, rowIndex) => this.removeWithPopup(rowIndex)',
              },
              type: 'action',
              id: 'analyticalSpacesAction',
            },
          ],
        },
        screenActions: {
          processList: [],
        },
      };
    });

    this.router.events.subscribe((data) => {
      if (this.table && data && data['url'] && data['url'].includes('refresh=true') && data['restoredState'] === null) {
        this.reloadTable();
      }
    });
  }

  private reloadTable() {
    const config = this.config.dataSourceConfig;
    this.table.dataSource.loadData({
      page: this.table.paginator.pageIndex,
      size: this.table.paginator.pageSize,
      url: config.onlineDataSourceUrl,
      token: config.token,
      offlineStorageKey: config.offlineStorageKey,
      data: config.data,
    });
  }

  public async create() {
    const form = await this.modalController.create({
      component: AnalyticalSpaceCreateAndEditComponent,
    });

    form.onDidDismiss().then(() => this.reloadTable());

    await form.present();
  }
}
