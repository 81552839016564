import * as moment from 'moment';
import { DictionaryEntry } from '../dictionary.interface';
import { DisplayFunctions } from './display-functions.class';

export class Utils {
  public static pad(number: number, size: number): string {
    let numString = `${number}`;

    while (numString.length < size) {
      numString = '0' + numString;
    }

    return numString;
  }

  public static evaluateString(str: string, object: Object): string {
    str = this.evaluateConditionString(str, object);
    str = this.evaluateLoopsString(str, object);
    str = this.evaluateVariablesString(str, object);
    return this.evaluateFunctionsString(str);
  }

  public static extractTextFromHtmlTag(s: string): string {
    const span = document.createElement('span');
    span.innerHTML = s;
    return span.textContent || span.innerText;
  }

  private static evaluateLoopsString(str: string, object: Object): string {
    const ARRAY_REG_EXP = /\#\[[a-zA-Z0-9._]*\]\[[a-zA-Z0-9._\<\s\=\\\"\>\$\{\}\/]*\]/g;
    const literals = (str || '').match(ARRAY_REG_EXP);

    if (!literals) {
      return str;
    }

    literals.forEach((literal: string) => {
      let arrayPath, element;
      [arrayPath, element] = literal.split('][');
      arrayPath = arrayPath.replace('#[', '');
      element = element.replace(']', '');

      let array = object;
      (arrayPath as string).split('.').forEach((param) => {
        if (array[param] !== undefined) {
          array = array[param];
        }
      });

      let repeatingText = '';
      if (array !== null && array !== undefined) {
        element = element.replace('${', '${' + arrayPath + '.{index}.');
        for (let i = 0; i < (array as Array<any>).length; i++) {
          repeatingText += element.replace('.{index}.', '.' + i + '.');
        }
      }

      str = str.replace(literal, repeatingText);
    });

    return str;
  }

  public static evaluateVariablesString(str: string, object: Object): string {
    const VARIABLE_REG_EXP = /\$\{[a-zA-Z0-9._]*\}/g;
    const literals = (str || '').match(VARIABLE_REG_EXP);

    if (!literals) {
      return str;
    }

    literals.forEach((literal: string) => {
      const variablePath = literal.replace('${', '').replace('}', '');
      let variable = object;

      if (variablePath.indexOf('.') > -1) {
        for (const param of variablePath.split('.')) {
          variable = variable[param];
          if (variable === null || variable === undefined) {
            variable = '';
            break;
          }
        }
      } else variable = variable[variablePath];

      str = str.replace(literal, this.getVariableAsString(variable));
    });

    return str;
  }

  private static getVariableAsString(variable: any): string {
    if (variable === null || variable === undefined) {
      return '';
    }
    return variable.toString();
  }

  private static evaluateFunctionsString(str: string): string {
    const FUNCTION_REG_EXP = /\$\[(.*?)\]/g;
    const literals = (str || '').match(FUNCTION_REG_EXP);
    if (!literals) {
      return str;
    }

    literals.forEach((literal: string) => {
      const PARAMETER_REF_EXP = /\(([^)]+)\)/;
      let functionParameters = literal.match(PARAMETER_REF_EXP);
      let functionParameter = functionParameters ? functionParameters[0] : '';
      const functionPath = literal.replace(functionParameter, '').replace('$[', '').replace(']', '');
      functionParameter = functionParameter.replace('(', '').replace(')', '');

      // FixMe: https://sellions.atlassian.net/browse/AS-3201
      try {
        const newValue = DisplayFunctions[functionPath](functionParameter);
        str = str.replace(literal, newValue as string);
      } catch (error) {
        console.warn(`An error occured. Cannot found 'DisplayFunctions.${functionPath}'. Probably a DataView not loaded yet.`);
      }
    });

    return str;
  }

  private static evaluateConditionString(str: string, object: Object): string {
    if (!str) {
      return '';
    }

    const CONDITION_REG_EXP = /IF\s*\(.*\)\s*THEN/;
    const IF_TRUE_REG_EXP = /THEN\s*\(.*\)\s*ELSE/;
    const IF_FALSE_REG_EXP = /ELSE\s*\(.*\)$/;

    let condition = str.match(CONDITION_REG_EXP) || '';
    condition = condition && condition[0];

    if (!condition) {
      return str;
    }

    condition = condition.replace(/IF\s*\(\${/, '').replace(/}\)\s*THEN/, '');

    let strIfTrue = str.match(IF_TRUE_REG_EXP) || '';
    strIfTrue = strIfTrue && strIfTrue[0].replace(/THEN\s*\(/, '').replace(/\)\s*ELSE/, '');

    let strIfFalse = str.match(IF_FALSE_REG_EXP) || '';
    strIfFalse = strIfFalse && strIfFalse[0].replace(/ELSE\s*\(/, '').replace(/\)$/, '');

    return object[condition] ? strIfTrue : strIfFalse;
  }

  public static extractFieldNameFromStringLiteralValue(value: string) {
    const values = value.replace('${', '').replace('}', '').split('.');
    return values[values.length - 1];
  }

  public static checkAgainstQuery(obj: Object, query: string): boolean {
    for (let key in obj) {
      if (this.checkForStringMatch(obj[key], query)) return true;
    }
    return false;
  }

  public static checkForStringMatch(str1: string, str2: string, replaceDiacritics = false): boolean {
    if (replaceDiacritics) {
      str1 = this.replaceDiacritics(str1);
      str2 = this.replaceDiacritics(str2);
    }
    return Utils.extractTextFromHtmlTag(str1).toLocaleLowerCase().indexOf(str2.toLocaleLowerCase()) > -1;
  }

  public static checkAgainstDate(str: string, date: Date | string | moment.Moment): boolean {
    return moment(str).isSame(date);
  }

  public static checkAgainstDictionaryEntry(query: DictionaryEntry | Array<DictionaryEntry>, entry: DictionaryEntry): boolean {
    if (Array.isArray(query)) {
      if (!query[0]) {
        return true;
      }

      for (let str of query as Array<DictionaryEntry>) {
        if (this.checkForStringMatch(str.name, entry.name)) {
          return true;
        }
      }
      return false;
    } else {
      return this.checkForStringMatch(query.name, entry.name);
    }
  }

  public static replaceDiacritics(str: string): string {
    return str
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .replace('ł', 'l')
      .replace('Ł', 'L');
  }

  public static checkIfArrayContains(array: Array<any>, elem: any): boolean {
    return array.map((el) => JSON.stringify(el)).indexOf(JSON.stringify(elem)) > -1;
  }

  public static currentDate() {
    let date = new Date();
    return (
      date.getFullYear() +
      '_' +
      this.pad(date.getMonth() + 1, 2) +
      '_' +
      this.pad(date.getDate(), 2) +
      '_' +
      this.pad(date.getHours(), 2) +
      '_' +
      this.pad(date.getMinutes(), 2) +
      '_' +
      this.pad(date.getSeconds(), 2)
    );
  }
}
