import { AccountService } from './account.service';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { AuthServerProvider } from './auth-oauth2.service';
import { JsonConfigService } from '../config/json-config.service';
import {ErrorTranslationService} from "../config/error-translation.service";
import {TranslateService} from "@ngx-translate/core";

@Injectable()
export class Principal {
  private _identity: any;
  private authenticated: boolean = false;
  private authenticationState = new Subject<any>();

  constructor(private account: AccountService,
              private auth: AuthServerProvider,
              private jsonConfigService: JsonConfigService,
              private translateService: TranslateService) {}

  authenticate(_identity) {
    this._identity = _identity;
    this.authenticated = _identity !== null;
  }

  hasAnyAuthority(authorities) {
    if (!this.authenticated || !this._identity || !this._identity.authorities) {
      return false;
    }

    for (let i = 0; i < authorities.length; i++) {
      if (this._identity.authorities.indexOf(authorities[i]) !== -1) {
        return true;
      }
    }

    return false;
  }

  hasAuthority(authority): Promise<any> {
    if (!this.authenticated) {
      return Promise.resolve(false);
    }

    return this.identity().then(
      (id) => {
        return id.authorities && id.authorities.indexOf(authority) !== -1;
      },
      () => {
        return false;
      },
    );
  }

  identity(force?: boolean): Promise<any> {
    if (force === true) this._identity = undefined;
    if (this._identity) return Promise.resolve(this._identity);

    return this.account
      .get()
      .toPromise()
      .then((account) => {
        this.jsonConfigService.loadCurrentUserLayout(account);
        this.translateService.setDefaultLang('pl')
        this.translateService.use(account.langKey || 'pl')

        if (account) {
          this._identity = account;
          this.authenticated = true;
        } else {
          this._identity = null;
          this.authenticated = false;
        }
        this.authenticationState.next(this._identity);
        return this._identity;
      })
      .catch((err) => {
        this._identity = null;
        this.authenticated = false;
        this.authenticationState.next(this._identity);
        return null;
      });
  }

  isAuthenticated(): boolean {
    return this.authenticated;
  }

  isIdentityResolved(): boolean {
    return this._identity !== undefined;
  }

  getAuthenticationState(): Observable<any> {
    return this.authenticationState.asObservable();
  }

  getImageUrl(): String {
    return this.isIdentityResolved() ? this._identity.imageUrl : null;
  }

  getIdentity(): any {
    return this.isIdentityResolved() ? this._identity : null;
  }
}
