import { DataView } from './data-view';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root',
})
export class DataViewService {
  constructor(private http: HttpClient) {}

  getAll(): Observable<DataView[]> {
    return this.http.get<DataView[]>('api/data-views');
  }

  getAllValid(): Observable<DataView[]> {
    return this.http.get<DataView[]>('api/data-views/valid');
  }

  getDefinition(id: number): Observable<DataView> {
    return this.http.get<DataView>('api/data-views/' + id);
  }

  getData(name: string): Observable<any> {
    return this.http.get<any>('api/data-view/' + name);
  }

  create(dataView: DataView): Observable<any> {
    return this.http.post(`api/data-views`, dataView, {});
  }

  update(id: number, dataView: DataView): Observable<any> {
    return this.http.put(`api/data-views/${id}`, dataView, {});
  }
}
