import { Injectable } from '@angular/core';
import { Principal } from './principal.service';
import { Router } from '@angular/router';
import { StateStorageService } from './state-storage.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  identity;

  constructor(private principal: Principal, private stateStorageService: StateStorageService, private router: Router) {}

  authorize(force) {
    return this.principal.identity(force).then(() => this.authThen());
  }

  authThen() {
    let isAuthenticated = this.principal.isAuthenticated();
    let canActivate = true;

    return Promise.all([this.stateStorageService.getDestinationState(), this.stateStorageService.getPreviousState()]).then((values) => {
      if (isAuthenticated && values[0].destination.name === 'register') {
        this.router.navigate(['']);
        canActivate = false;
      }

      if (isAuthenticated && !values[0].from.name && values[1]) {
        this.stateStorageService.resetPreviousState().then(() => {
          this.router.navigate([values[1].name], {
            queryParams: values[1].params,
          });
          canActivate = false;
        });
      }

      if (!isAuthenticated) {
        canActivate = false;
        this.stateStorageService.storePreviousState(values[0].destination.name, values[0].params).then(() => this.router.navigate(['login']));
      }

      if (
        values[0].destination.data.authorities &&
        values[0].destination.data.authorities.length > 0 &&
        !this.principal.hasAnyAuthority([...values[0].destination.data.authorities, 'ROLE_ADMIN'])
      ) {
        if (isAuthenticated) {
          this.router.navigate(['accessdenied']);
          canActivate = false;
        } else {
          canActivate = false;
          this.stateStorageService.storePreviousState(values[0].destination.name, values[0].params);
          this.router.navigate(['login']);
        }
      }

      this.identity = this.principal.getIdentity();
      if (canActivate) {
        this.stateStorageService.storePreviousLoggedUser(this.identity);
      }
      return canActivate;
    });
  }
}
