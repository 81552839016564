import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import Form from './form';

@Injectable({
  providedIn: 'root',
})
export class FormService {
  constructor(private http: HttpClient) {}

  get(id: number): Observable<Form> {
    return this.http.get<Form>(`api/form/${id}`);
  }

  update(id: number, form: Form) {
    return this.http.put(`api/form/${id}`, form, {});
  }
}
