import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpService } from '../shared/http.service';
import {DictionaryEntry} from '../../../sellions_layout_renderer/projects/sellions-layout-renderer-lib/src/lib/dictionary.interface';

@Injectable({
  providedIn: 'root',
})
export class DictionaryDefinitionService {
  constructor(private http: HttpClient, private httpService: HttpService) {}

  getDictionaryDefinitions(request) {
    return this.http.get<Array<DictionaryEntry>>('api/dictionary-definitions', {
      params: this.httpService.getParams(request),
      observe: 'response',
    });
  }

  getAll() {
    return this.http.get<DictionaryEntry[]>('api/dictionary-definitions');
  }

  getDictionaryEntries(id) {
    return this.http.get('api/dictionary-definitions/' + id);
  }

  delete(id) {
    return this.http.delete('api/dictionary-definitions/' + id);
  }

  save(id, items) {
    return this.http.put(
      `api/dictionary-definitions/${id}/dictionary-items`,
      items,
      {},
    );
  }

  create(name) {
    return this.http.post(
      `api/dictionary-definitions`,
      { name, items: [] },
      {},
    );
  }
}
