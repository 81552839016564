import { ErrorStateMatcher } from '@angular/material/core';
import { FormControl, FormGroupDirective, NgForm } from '@angular/forms';

export class BaseFieldErrorStateMatcher implements ErrorStateMatcher {
  constructor(private validationFn: () => boolean) {}

  isErrorState(
    control: FormControl,
    formDirective: FormGroupDirective | NgForm
  ): boolean {
    return this.validationFn();
  }
}
