import { Action } from '../table/interfaces/action.interface';
import { Component, EventEmitter, Input, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { TableConfig } from '../table/interfaces/table-config.interface';
import { TableRendererComponent } from '../table/table.component';

@Component({
  selector: 'sellions-layout-renderer-table-with-floating-action-button',
  templateUrl: './table-with-floating-action-button.component.html',
})
export class TableWithFloatingActionButtonComponent {
  @ViewChild(TableRendererComponent) table!: TableRendererComponent;
  @Input() config: TableConfig;
  @Output() clickedButton = new EventEmitter<any>();
  @Output() onAction = new EventEmitter<Action>();

  public onButtonClick(buttonClicked: any) {
    this.clickedButton.emit(buttonClicked);
  }

  public updateData() {
    const config = this.config.dataSourceConfig;
    this.table.dataSource.loadData({
      page: 0,
      size: this.table.defaultPageSize,
      url: config.onlineDataSourceUrl,
      token: config.token,
      offlineStorageKey: config.offlineStorageKey,
      data: config.data,
    });
  }

  public resetPaginator() {
    this.table.resetPaginator();
  }
}
