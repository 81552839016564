import { BoolComponent } from './components/controls/bool/bool.component';
import { CheckboxComponent } from './components/controls/checkbox/checkbox.component';
import { CommonModule } from '@angular/common';
import { ConfigServiceProvider } from './services/config/config.service';
import { ControlsFactoryComponent } from './components/controls-factory/controls-factory.component';
import { CurrencyComponent } from './components/controls/currency/currency.component';
import { DatepickerComponent } from './components/controls/datepicker/datepicker.component';
import { DictionaryComponent } from './components/controls/dictionary/dictionary.component';
import { DropdownComponent } from './components/controls/dropdown/dropdown.component';
import { EmailComponent } from './components/controls/email/email.component';
import { FloatingComponent } from './components/controls/floating/floating.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { IntegerComponent } from './components/controls/integer/integer.component';
import { LinkComponent } from './components/controls/link/link.component';
import { LocalizationComponent } from './components/controls/localization/localization.component';
import { MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MultipartFilesComponent } from './components/controls/multipart-files/multipart-files.component';
import { NgModule } from '@angular/core';
import { NipComponent } from './components/controls/nip/nip.component';
import { NumberComponent } from './components/controls/number/number.component';
import { PhoneInputComponent } from './components/controls/phone-input/phone-input.component';
import { RadioComponent } from './components/controls/radio/radio.component';
import { ReferenceComponent } from './components/controls/reference/reference.component';
import { ReferencesService } from './services/reference/reference.service';
import { SellionsFormsRendererComponent } from './components/renderer/renderer.component';
import { TextAreaComponent } from './components/controls/textarea/textarea.component';
import { TextComponent } from './components/controls/text/text.component';
import { TimepickerComponent } from './components/controls/timepicker/timepicker.component';
import { SmartFilterComponent } from './components/smart-component/smart-filter.component';
import { MatDialogModule } from '@angular/material/dialog';
import { DataSourceOptionsService } from './services/data-source-options/data-source-options.service';
import { DataSourceFieldOptionComponent } from './components/controls/data-source/data-source/data-source-field-option/data-source-field-option.component';
import { GeolocalizationDialogComponent } from './components/controls/localization/no-geolocation-dialog/no-geolocation-dialog.component';
import { DATE_FORMATS } from './constants/date-format';

@NgModule({
  declarations: [
    SellionsFormsRendererComponent,
    PhoneInputComponent,
    NipComponent,
    TextComponent,
    IntegerComponent,
    FloatingComponent,
    EmailComponent,
    LinkComponent,
    CurrencyComponent,
    TimepickerComponent,
    LocalizationComponent,
    MultipartFilesComponent,
    NumberComponent,
    TextAreaComponent,
    RadioComponent,
    CheckboxComponent,
    DropdownComponent,
    BoolComponent,
    ReferenceComponent,
    DictionaryComponent,
    DatepickerComponent,
    ControlsFactoryComponent,
    SmartFilterComponent,
    DataSourceFieldOptionComponent,
    GeolocalizationDialogComponent,
  ],
  entryComponents: [SmartFilterComponent, GeolocalizationDialogComponent],
  imports: [
    CommonModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    MatMomentDateModule,
    MatCardModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    MatDatepickerModule,
    MatButtonModule,
    MatRadioModule,
    MatCheckboxModule,
    MatAutocompleteModule,
    MatIconModule,
    MatListModule,
    MatDialogModule,
  ],
  exports: [
    SellionsFormsRendererComponent,
    PhoneInputComponent,
    NipComponent,
    TextComponent,
    IntegerComponent,
    FloatingComponent,
    EmailComponent,
    LinkComponent,
    CurrencyComponent,
    TimepickerComponent,
    LocalizationComponent,
    MultipartFilesComponent,
    NumberComponent,
    TextAreaComponent,
    RadioComponent,
    CheckboxComponent,
    DropdownComponent,
    BoolComponent,
    ReferenceComponent,
    DictionaryComponent,
    DatepickerComponent,
    ControlsFactoryComponent,
    SmartFilterComponent,
  ],
  providers: [
    ConfigServiceProvider,
    { provide: MAT_DATE_LOCALE, useValue: 'pl-PL' },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: null },
    { provide: MAT_DATE_FORMATS, useValue: DATE_FORMATS },
    ReferencesService,
    DataSourceOptionsService,
  ],
})
export class SellionsFormsModule {}
