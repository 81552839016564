import Form from './form';
import { AuthServerProvider } from '../auth/auth-oauth2.service';
import { CdkDrag, CdkDragMove, CdkDragRelease, CdkDropList } from '@angular/cdk/drag-drop';
import { ChangeDetectorRef, Component, HostListener, Input, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { CopyService } from '../shared/copy.service';
import { DragAndDropService } from './services/drag-and-drop.service';
import { environment } from '../../environments/environment';
import { FormControl, Validators } from '@angular/forms';
import { FormField } from '../../../sellions_forms/projects/forms/src/lib/interfaces/form-field.interface';
import { FormFieldTypes } from '../../../sellions_forms/projects/forms/src/lib/constants/form-field-types.constant';
import { ModalController } from '@ionic/angular';
import { predefinedFieldTypes } from './fieldQuestionTypes';
import { QuestionComponent } from './question/question.component';
import { Subscription } from 'rxjs';
import { ToastService } from './services/toast.service';
import { typeLabels } from './question/type';

@Component({
  templateUrl: './form.page.html',
  styleUrls: ['./form.page.scss'],
})
export class FormPage implements OnInit, OnDestroy {
  @Input() private formId = 1;
  public form: Form;
  private saveDelegate;
  public dataViewBaseApi = environment.engineUrl;
  public formFieldTypes = FormFieldTypes;
  public predefinedTypes = predefinedFieldTypes;
  public typeLabels = typeLabels;
  public configuredFields: FormField[];
  public activeDropLists: CdkDropList[] = [];
  private dropListsSub: Subscription;
  public subFormsValidated = false;
  public formName: FormControl;

  @ViewChild('nameErrorContainer') set nameErrorContainer(element) {
    if (element) {
      this.adjustElementsHeight();
    }
  }

  @ViewChildren(QuestionComponent) questions: QueryList<QuestionComponent>;

  @ViewChildren(CdkDropList) dropLists?: QueryList<CdkDropList>;

  @HostListener('window:resize', ['$event']) onResize() {
    this.adjustElementsHeight();
  }

  constructor(
    private modalController: ModalController,
    public auth: AuthServerProvider,
    private toastService: ToastService,
    private dragAndDropService: DragAndDropService,
    private cdr: ChangeDetectorRef,
    private copyArray: CopyService,
  ) {}

  ngOnInit() {
    this.formName = new FormControl(this.form.name ? this.form.name : '', Validators.required);
  }

  ionViewDidEnter() {
    this.adjustElementsHeight();
    if (this.dropLists) {
      this.registerDropLists(this.dropLists);
      this.dropListsSub = this.dropLists.changes.subscribe(() => {
        this.registerDropLists(this.dropLists);
      });
    }
  }

  ngOnDestroy() {
    this.dropListsSub.unsubscribe();
  }

  registerDropLists(lists: QueryList<CdkDropList>) {
    this.dragAndDropService.register(lists);
    this.activeDropLists = this.dragAndDropService.getActiveDropLists();
    this.cdr.detectChanges();
  }

  cancel() {
    this.modalController.dismiss();
  }

  save() {
    if (this.isFormValid()) {
      if (!this.form.fields.length) {
        this.toastService.showNotification('Zdefiniuj pytania');
      } else if (!this.formName.value) {
        this.formName.markAsTouched();
        this.toastService.showNotification('Zdefiniuj nazwę formularza');
      } else {
        this.saveDelegate(this.form.fields, this.formName.value);
        this.modalController.dismiss();
      }
    } else {
      this.showErrorsMessages();
    }
  }

  adjustElementsHeight() {
    const contentHeight = this.getElement('modal-wrapper').offsetHeight;
    const buttonsHeight = this.getElement('form-footer').offsetHeight;
    const nameHeight = this.getElement('name-input-container').offsetHeight;
    const labelsHeight = this.getElement('mat-tab-labels').offsetHeight;
    const finalHeight = contentHeight - buttonsHeight - nameHeight - labelsHeight + 'px';
    const tableElement = this.getElement('table-rows');
    const typesElement = this.getElement('types');
    const previewElement = this.getElement('preview');
    if (tableElement && typesElement) {
      [tableElement, typesElement].forEach((element) => (element.style.height = finalHeight));
    }
    if (previewElement) {
      previewElement.style.height = finalHeight;
    }
  }

  getElement(className: string): HTMLElement {
    return document.getElementsByClassName(className)[0] as HTMLElement;
  }

  isPredefinedType(field) {
    return this.predefinedTypes.includes(field.type);
  }

  getPossibleDependencies(field) {
    const fields = [];
    this.form.fields.forEach((f) => {
      if (f.type !== 'list') {
        this.addPossibleDependency(f, field, fields);
      }
      if (f.type === 'list' && f.subForm.definition.length) {
        f.subForm.definition.forEach((definition) => {
          this.addPossibleDependency(definition, field, fields);
        });
      }
    });
    return fields;
  }

  addPossibleDependency(dependency, field, array) {
    if (dependency !== field && dependency.id) {
      if (this.isPredefinedType(dependency)) {
        if (this.isFieldDataValid(dependency)) {
          array.push(dependency);
        }
      } else {
        array.push(dependency);
      }
    }
  }

  isFieldDataValid(field): boolean {
    return !!((field.dataView && field.dataViewColumn) || field.dictionaryId || (field.values && field.values.length && this.areValuesValid(field)));
  }

  filterOutUnconfiguredQuestions(questions) {
    return questions.filter((question) => {
      const field = question.field;
      if (field.id && field.label) {
        const conditions = [];
        if (this.isPredefinedType(field)) {
          const isDataValid = this.isFieldDataValid(field);
          conditions.push(isDataValid);
        }

        if (field.type === 'list') {
          const isSubFormNotEmpty = !!(field.subForm.definition.length && this.getConfiguredSubformQuestions(field).length > 0);
          conditions.push(isSubFormNotEmpty);
        }

        const dependencies = field.conditions;
        if (dependencies && dependencies.length) {
          const areDependenciesValid = !(
            (dependencies.length > 1 && !field.conditionsConjuction) ||
            dependencies.some((dependency) => !dependency.questionId || !dependency.value || !dependency.operator)
          );
          conditions.push(areDependenciesValid);
        }

        const fieldsToCheck =
          question.subFormFieldIndex === undefined ? this.form.fields : this.form.fields[question.subFormFieldIndex].subForm.definition;
        const isIdUnique = fieldsToCheck.filter((fieldInstance) => fieldInstance.id === field.id).length === 1;
        conditions.push(isIdUnique);
        return conditions.length ? !conditions.includes(false) : true;
      } else {
        return false;
      }
    });
  }

  getNumberOfFullyConfiguredFields(): number {
    const configuredFields = this.getConfiguredMainFields();
    const fullyConfiguredFields = configuredFields.filter((field) => {
      if (field.type === 'list') {
        return field.subForm.definition.length === this.getConfiguredSubformQuestions(field).length;
      } else {
        return true;
      }
    });
    return fullyConfiguredFields.length;
  }

  getConfiguredFields(): FormField[] {
    const configuredQuestions = this.copyArray.copy(this.getConfiguredMainFields());
    configuredQuestions.forEach((field) => {
      if (field.type === 'list') {
        field.subForm.definition = this.getConfiguredSubformQuestions(field).map((subformQuestion) => subformQuestion.field);
      }
    });
    return configuredQuestions;
  }

  getConfiguredMainFields() {
    return this.filterOutUnconfiguredQuestions(this.getMainQuestions()).map((question) => question.field);
  }

  getConfiguredSubformQuestions(field) {
    return this.filterOutUnconfiguredQuestions(this.getSubformQuestions(this.getFieldIndex(field)));
  }

  areValuesValid(field): boolean {
    let result = true;
    field.values.forEach((value) => {
      if (!value.id || !value.value) {
        result = false;
      }
    });
    return result;
  }

  isFormValid(): boolean {
    this.subFormsValidated = true;
    return this.getNumberOfFullyConfiguredFields() === this.form.fields.length;
  }

  areMainQuestionsIdsUnique(): boolean {
    return this.areIdsUnique(this.getMainQuestions(), this.form.fields);
  }

  areSubFormQuestionsIdsUnique(): boolean {
    return this.areIdsUnique(this.getSubformQuestions());
  }

  getMainQuestions(): QuestionComponent[] {
    return this.questions.filter((question) => question.subFormFieldIndex === undefined);
  }

  getSubformQuestions(index?: number): QuestionComponent[] {
    return this.questions.filter((question) => (index ? question.subFormFieldIndex === index : question.subFormFieldIndex !== undefined));
  }

  getFieldIndex(field): number {
    return this.form.fields.findIndex((fieldInstance) => fieldInstance.id === field.id);
  }

  areIdsUnique(questions, fields?) {
    let result = true;
    questions.forEach((question) => {
      if (!fields) {
        fields = this.form.fields[question.subFormFieldIndex].subForm.definition;
      }
      if (fields.filter((field) => field.id === question.field.id).length > 1) {
        result = false;
        question.setIdError();
      } else {
        question.deleteIdDuplicatedError();
      }
    });
    return result;
  }

  showErrorsMessages() {
    this.questions.forEach((question) => question.showErrors());
    this.toastService.showNotification('Nie wszystkie pytania są poprawnie skonfigurowane');
  }

  onTabChange(tab) {
    this.adjustElementsHeight();
    if (tab.index === 1) {
      this.configuredFields = this.getConfiguredFields();
      if (!this.configuredFields.length) {
        this.toastService.showNotification('Zdefiniuj pytania');
      }
    }
    if (!this.isFormValid()) {
      this.showErrorsMessages();
    }
  }

  allowDropPredicate = (drag: CdkDrag, drop: CdkDropList) => {
    return this.dragAndDropService.isDropAllowed(drag, drop);
  };

  dragMoved(event: CdkDragMove<any>) {
    this.dragAndDropService.dragMoved(event);
  }

  dragReleased(event: CdkDragRelease) {
    this.dragAndDropService.dragReleased(event);
  }

  wasItemMoved(event): boolean {
    return event.item.dropContainer.id === event.container.id;
  }

  manageItemDrop(event, index?) {
    if (this.wasItemMoved(event)) {
      this.moveItem(event, index);
    } else {
      this.addField(event);
    }
  }

  getNewField(type): FormField {
    const field: FormField = {
      id: '',
      label: '',
      required: true,
      type: type,
      disabled: false,
    };
    if (field.type === 'list') {
      field.subForm = { definition: [] };
    }
    return field;
  }

  addField(event) {
    const field = this.getNewField(event.item.data);
    if (field.type === 'list') {
      this.subFormsValidated = false;
    }
    if (event.container) {
      const id = event.container.id;
      if (id === 'fields') {
        this.form.fields.splice(event.currentIndex, 0, field);
      } else if (id.includes('fieldsSubform')) {
        const fieldsIndex = +id.replace('fieldsSubform', '');
        this.form.fields[fieldsIndex].subForm.definition.splice(event.currentIndex, 0, field);
      }
    } else {
      this.form.fields.push(field);
    }
  }

  moveItem(event, index?) {
    if (index) {
      const subFormFields = this.form.fields[index].subForm.definition;
      const item = subFormFields[event.previousIndex];
      subFormFields.splice(event.previousIndex, 1);
      subFormFields.splice(event.currentIndex, 0, item);
    } else {
      const item = this.form.fields[event.previousIndex];
      this.form.fields.splice(event.previousIndex, 1);
      this.form.fields.splice(event.currentIndex, 0, item);
    }
  }

  delete(index) {
    this.form.fields.splice(index, 1);
    this.areMainQuestionsIdsUnique();
  }

  deleteSubformField(fieldIndex, subFormIndex) {
    this.form.fields[fieldIndex].subForm.definition.splice(subFormIndex, 1);
    this.areSubFormQuestionsIdsUnique();
  }
}
