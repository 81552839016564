import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {AnalyticalSpace} from "./analytical-space";
import {Observable} from "rxjs/internal/Observable";

@Injectable({
    providedIn: 'root',
})
export class AnalyticalSpaceService {

    constructor(private http: HttpClient) {}

    get(id: number): Observable<AnalyticalSpace> {
        return this.http.get<AnalyticalSpace>(`api/analytical-spaces/${id}`)
    }

    create(analyticalSpace: AnalyticalSpace): Observable<any> {
        return this.http.post(
            `api/analytical-spaces`,
            analyticalSpace,
            { headers: { 'Content-Type': 'application/json' } },
        );
    }

    update(id: number, analyticalSpace: AnalyticalSpace): Observable<any> {
        return this.http.put(
            `api/analytical-spaces/${id}`,
            analyticalSpace,
            {},
        );
    }
}
