import { CanDeactivateProcess } from './process/can-deactivate-process';
import { GenericDataViewRouteActivateGuard } from './generic-data-view/generic-data-view-route-activate.guard';
import { JsonConfigService } from './config/json-config.service';
import { MainComponent } from './menu/main.component';
import { NgModule } from '@angular/core';
import { pageRoutes } from './app-pages';
import { ProcessSelectorPage } from './process-selector/process-selector.page';
import { RouterModule, Routes } from '@angular/router';
import { UserRouteAccessService } from './auth/user-route-access-service';
import { ErrorTranslationService } from './config/error-translation.service';

const routes: Routes = [
  {
    path: '',
    component: MainComponent,
    children: pageRoutes,
    canActivate: [UserRouteAccessService],
    canActivateChild: [UserRouteAccessService],
  },
  {
    path: 'login',
    loadChildren: './auth/auth.module#AuthModule',
  },
  {
    path: 'process-selector',
    component: ProcessSelectorPage,
    data: {
      authorities: ['ROLE_ANALYST', 'ROLE_ADMIN'],
    },
    canActivate: [UserRouteAccessService],
  },
  {
    path: 'processes',
    loadChildren: './processes/processes.module#ProcessesPageModule',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [CanDeactivateProcess, GenericDataViewRouteActivateGuard, JsonConfigService, ErrorTranslationService],
})
export class AppRoutingModule {}
