import { BaseFieldComponent } from '../base-field/base-field.component';
import { Component, forwardRef } from '@angular/core';
import { DictionaryItem } from '../../../interfaces/dictionary-item.interface';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'forms-dictionary',
  templateUrl: './dictionary.component.html',
  styleUrls: ['./dictionary.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DictionaryComponent),
      multi: true,
    },
  ],
})
export class DictionaryComponent extends BaseFieldComponent {
  public compareDictionaryItems(o1: DictionaryItem, o2: DictionaryItem): boolean {
    return o1.name === o2.name;
  }

  public static mapDictionary(dictionaryItem: DictionaryItem) {
    return dictionaryItem.name;
  }
}
