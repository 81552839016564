import { Component } from '@angular/core';
import { ProcessService } from '../process/process.service';
import { AlertController, ToastController } from '@ionic/angular';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'process-selector',
  templateUrl: './process-selector.page.html',
  styleUrls: ['./process-selector.page.scss'],
})
export class ProcessSelectorPage {
  processes: Array<any>;
  private query: any;

  schedulerForm: FormGroup;
  private runOnce: FormControl;
  private runPeriodically: FormControl;
  private jobName: FormControl;
  private hours: FormControl;
  private dayOfMonth: FormControl;
  private months: FormControl;
  private year: FormControl;
  private process: FormControl;

  constructor(
    private processService: ProcessService,
    private toastController: ToastController,
    private alertController: AlertController,
    private formBuilder: FormBuilder,
  ) {
    processService
      .getProcesses()
      .subscribe(
        (processes) =>
          (this.processes = processes.content.filter((p) =>
            this.isProcessForData(p),
          )),
      );

    this.schedulerForm = formBuilder.group({
      runOnce: this.runOnce,
      runPeriodically: this.runPeriodically,
      jobName: this.jobName,
      hours: this.hours,
      dayOfMonth: this.dayOfMonth,
      months: this.months,
      year: this.year,
      process: this.process,
    });
  }

  private isProcessForData(process) {
    return process.params.dataRunnable;
  }

  send() {
    var scheduleData = {};
    scheduleData['runOnce'] = this.schedulerForm.get('runOnce').value;
    scheduleData['runPeriodically'] =
      this.schedulerForm.get('runPeriodically').value;
    scheduleData['jobName'] = this.schedulerForm.get('jobName').value;
    scheduleData['hours'] = this.schedulerForm.get('hours').value;
    scheduleData['dayOfMonth'] = this.schedulerForm.get('dayOfMonth').value;
    scheduleData['months'] = this.schedulerForm.get('months').value;
    scheduleData['year'] = this.schedulerForm.get('year').value;
    scheduleData['process'] = this.schedulerForm.get('process').value;
    scheduleData['query'] = (<any>window).pageParams.query;
    this.alertController
      .create({
        message: 'Are you sure you want to save schedule for this process?',
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
          },
          {
            text: 'Yes',
            handler: () => this.saveSchedule(scheduleData),
          },
        ],
      })
      .then((alert) => alert.present());
  }

  saveSchedule(scheduleData: any) {
    this.processService.handleProcessSchedule(scheduleData).subscribe((proc) =>
      this.toastController
        .create({
          message: 'The requested action has been completed',
          duration: 1000,
        })
        .then((toast) => {
          toast.present();
          window.close();
        }),
    );
  }

  closeWindow() {
    window.close();
  }
}
