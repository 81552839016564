import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { ApiRequest } from './api-request';

@Injectable({
  providedIn: 'root',
})
export class HttpService {
  constructor() {}

  getParams(request: ApiRequest) {
    let params = new HttpParams()
      .set('page', '' + request.page)
      .set('size', '' + request.pageSize);

    if (request.sort)
      params = params.set('sort', `${request.sort},${request.sortOrder}`);

    if (request.query)
      params = params.set('query', JSON.stringify(request.query));

    return params;
  }

  getJSONParams(request: ApiRequest) {
    if (request.sort) {
      let names = request.sort.split('.');
      let r = [names[0]];
      names
        .slice(1)
        .map((a) => "'" + a + "'")
        .forEach((b) => r.push(b));
      request.sort = r.join('->>');
    }
    return this.getParams(request);
  }
}
