import { Action } from './interfaces/action.interface';
import { CellDefinition } from './interfaces/cell-definition.interface';
import { Column } from './interfaces/column.interface';
import { DataChangeOutput } from './interfaces/data-change-output.interface';
import { DataService } from '../../services/data/data.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { InputDataSource } from './data-sources/input-data-source.class';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MixedDataSource } from './data-sources/mixed-data-source.class';
import { OfflineDataSource } from './data-sources/offline-data-source.class';
import { OnlineDataSource } from './data-sources/online-data-source.class';
import { RemovalConfirmationDialog } from './removal-confirmation-dialog/removal-confirmation-dialog.component';
import { Subject } from 'rxjs';
import { TableColumn } from './table-column.model';
import { TableConfig } from './interfaces/table-config.interface';
import { TableDataSource } from './data-sources/table-data-source.class';
import { take, takeUntil, tap } from 'rxjs/operators';
import { Utils } from '../../utils/utils.class';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Type,
  ViewChild,
} from '@angular/core';
import { Filter } from './interfaces/filter.interface';
import { OfflineTableFilterService } from '../../services/filters/offline-table-filter.service';
import { ReportService } from '../../services/report/report.service';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'sellions-layout-renderer-table',
  templateUrl: './table.component.html',
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0', overflow: 'hidden' })),
      state('expanded', style({ height: '*', minHeight: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class TableRendererComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() set config(config: TableConfig) {
    if (config && this.isTableConfigNew(config)) {
      if (this.configInput) {
        this.configInput = JSON.parse(JSON.stringify(config));
        this.initializeTable();
      } else {
        this.configInput = JSON.parse(JSON.stringify(config));
      }
    }
  }
  @Input() database: any;
  @Input() disabled: boolean = true;
  @Input() editable: boolean = false;
  @Input() noAddRowButton: boolean = false;
  @Input() dataSource: TableDataSource;

  @Output() loading = new EventEmitter<boolean>();
  @Output() onDataChange = new EventEmitter<DataChangeOutput>();
  @Output() onAction = new EventEmitter<Action>();

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  public readonly defaultPageSize = 5;
  public readonly pageSizeOptions = [5, 10, 15];

  // todo: establish types/interfaces
  private unsubscribe$ = new Subject<void>();
  public initialData: any[] = []; // stores initial data provided with config, when operationType = INPUT
  public columns: TableColumn[] = [];
  public displayedColumns: string[] = [];
  public editing = {};
  public columnsWithFilter: Column[] = [];
  public filters: Filter[] = [];
  public reports = [];
  public totalNumberOfElements: number = 0;
  private viewChecked = false;
  public expandedRow: any;
  public configInput: TableConfig;
  private indexesOfNestedTablesWithExpandableDetails: number[] = [];

  constructor(
    private cdr: ChangeDetectorRef,
    private componentFactoryResolver: ComponentFactoryResolver,
    private dataService: DataService,
    private dialog: MatDialog,
    private http: HttpClient,
    private offlineTableFilterService: OfflineTableFilterService,
    private reportService: ReportService,
  ) {}

  ngOnInit() {
    this.initializeDataSource();

    if (this.configInput.reports) this.reports = this.configInput.reports;

    if (this.configInput.displayConfig.pagination === undefined) this.configInput.displayConfig.pagination = true;

    this.dataSource.loading$.pipe(takeUntil(this.unsubscribe$)).subscribe((loading) => this.loading.emit(loading));

    this.dataSource.totalNumberOfElements$.pipe(takeUntil(this.unsubscribe$)).subscribe((number) => (this.totalNumberOfElements = number));

    this.dataSource.dataInitialized$.pipe(takeUntil(this.unsubscribe$)).subscribe((status) => {
      if (status) {
        this.emitChanges();
      }
    });

    this.initializeTable();

    this.sort.sortChange
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((sort: Sort) => this.loadData(this.paginator.pageIndex, this.paginator.pageSize, sort));
  }

  ngAfterViewInit() {
    this.reloadData();
    if (this.paginator)
      this.paginator.page
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe((page) => this.loadData(page.pageIndex, page.pageSize, this.sort, this.filters));
  }

  public reloadData() {
    if (!this.paginator) {
      this.loadData(0, this.defaultPageSize, this.sort, this.filters);
      return;
    }

    this.loadData(this.paginator.pageIndex, this.paginator.pageSize, this.sort, this.filters);
  }

  ngAfterViewChecked() {
    if (!this.viewChecked) {
      this.cdr.detectChanges();
      this.viewChecked = true;
    }
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public loadData(pageIndex?: number, pageSize?: number, sort?: Sort, filters?: Filter[]) {
    const config = this.configInput.dataSourceConfig;
    this.dataSource.loadData({
      page: pageIndex,
      size: pageSize,
      url: config.onlineDataSourceUrl,
      token: config.token,
      offlineStorageKey: config.offlineStorageKey,
      data: config.data,
      sortProperty: sort ? sort.active : config.sortProperty,
      sortDirection: sort ? (sort.direction.toUpperCase() as 'ASC' | 'DESC') : config.sortDirection,
      filters: filters ? filters : [],
    });
  }

  private initializeDataSource() {
    if (!!this.dataSource) return;

    switch (this.configInput.dataSourceConfig.operationType) {
      case 'INPUT':
        this.initialData = this.configInput.dataSourceConfig.data;
        this.dataSource = new InputDataSource(this.http, this.dataService, this.offlineTableFilterService);
        this.loadInitialValues();
        break;
      case 'MIXED':
        this.dataSource = new MixedDataSource(this.http, this.dataService, this.offlineTableFilterService);
        break;
      case 'ONLINE':
        this.dataSource = new OnlineDataSource(this.http, this.dataService);
        break;
      case 'OFFLINE':
        this.dataSource = new OfflineDataSource(this.http, this.dataService, this.offlineTableFilterService);
        break;
      default:
        console.error('Unsuported data source type in table!');
    }
  }

  public emitChanges(changedField?: string, changedRowIndex?: number, nestedRowIndexes?: number[]) {
    const dataOutput: DataChangeOutput = { fields: this.dataSource.getData() };
    if (changedField && (changedRowIndex || changedRowIndex === 0)) {
      dataOutput.changedPlace = { changedField, changedRowIndex };
    }
    if (nestedRowIndexes) {
      dataOutput.nestedRowIndexes = nestedRowIndexes;
    }
    this.onDataChange.emit(dataOutput);
  }

  public addEmptyRow() {
    this.dataSource.addEmptyRow();
    this.emitChanges();
  }

  public removeWithPopup(index: number) {
    let dialogRef = this.dialog.open(RemovalConfirmationDialog);

    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe((result) => {
        if (result) this.remove(index);
      });
  }

  public remove(index: number) {
    const url = this.configInput.dataSourceConfig.onlineDataSourceUrl;
    const deleteKey = this.configInput.dataSourceConfig.onlineDataSourceDeleteKey;
    const token = this.configInput.dataSourceConfig.token;

    if (deleteKey) {
      let id = Utils.evaluateString(deleteKey, this.dataSource.getDataElement(index));
      this.http
        .delete(`${url}/${id}`, {
          headers: token ? { Authorization: token } : {},
        })
        .pipe(take(1))
        .toPromise();
    }

    this.dataSource.remove(index);
    this.emitChanges();
  }

  openModal(cellDefinition: CellDefinition, row: any) {
    if (cellDefinition.component) {
      const factories = Array.from(this.componentFactoryResolver['_factories'].keys());
      const factoryClass = <Type<any>>factories.find((x: any) => x.name === cellDefinition.component);
      const data = {};
      data[cellDefinition.input] = { ...row };
      const dialogRef = this.dialog.open(factoryClass, {
        data: data,
      });
      dialogRef.afterClosed().subscribe((data) => {
        if (data) eval(cellDefinition.process)(data, row);
      });
    }
  }

  private loadInitialValues() {
    const uneditableFieldNames = this.configInput.displayConfig.columns
      .filter((column) => column.uneditableIfInitialValue)
      .map((column) => column.field);

    this.dataSource.initializeUneditableFields(uneditableFieldNames);
  }

  public initializeTable() {
    this.columns = this.getColumns();
    this.displayedColumns = this.columns.map((column) => column.header);
    this.columnsWithFilter = this.configInput.displayConfig.columns.filter((column: Column) => column.filter);
  }

  private getColumns(): TableColumn[] {
    return this.configInput.displayConfig.columns.map((column: Column) => {
      const cell: Function | any[] | { value: any } = this.getCell(column);

      var color: Function = (element: any) => {
        if (column.color) return Utils.evaluateString(column.color, element);
        return null;
      };

      const tableColumn: TableColumn = {
        header: column.label,
        type: column.type,
        field: column.field,
        processKey: column.processKey,
        cell,
        color,
        block: column.block,
        uneditableIfInitialValue: !!column.uneditableIfInitialValue,
        placeholder: column.placeholder || '',
        sortId: column.sortId,
        id: column.id,
        filterable: column.filterable,
      };

      if (column.type === 'expand_details' && this.configInput.displayConfig.nested) {
        tableColumn.nestedButtonCondition = this.configInput.displayConfig.nestedButtonCondition;
      }

      return tableColumn;
    });
  }

  private getCell(column: Column): Function | any[] | { value: any } {
    switch (column.type) {
      case 'action':
        return Object.keys(column.value).map((name) => [name, column.value[name]]);
      case 'checkbox':
        return (element: any) => Utils.evaluateString(column.value, element) === 'true';
      case 'dropdown':
        return column.values;
      case 'single_dropdown':
        return column.values;
      default:
        return (element: any) => (element ? Utils.evaluateString(column.value, element) : '');
    }
  }

  public resetPaginator() {
    this.paginator.pageIndex = 0;
    this.paginator.pageSize = this.defaultPageSize;
  }

  public emitAction(action: Action) {
    if (!action.processKey) action.processKey = this.configInput.screenActions.processList[0].processKey;
    this.onAction.emit(action);
  }

  public download(report: { url: string; name: string }) {
    this.reportService.download(report, { Accept: 'text/csv' }, { errorHeader: 'Błąd', errorMessage: 'Nie można pobrać raportu.' });
  }

  public onExpand(row) {
    this.expandedRow = this.expandedRow === row ? null : row;
  }

  public getAnimationState(row): string {
    return row === this.expandedRow ? 'expanded' : 'collapsed';
  }

  public shouldDisplayDetails(row: any) {
    return eval(this.configInput.displayConfig.nestedButtonCondition)(row);
  }

  public getNestedTableConfig(nestedRowIndex: number): TableConfig {
    const data = this.dataSource.getDataElement(nestedRowIndex);
    if (!data.fields) {
      data.fields = [];
    }
    const config: TableConfig = JSON.parse(JSON.stringify(this.configInput));
    config.dataSourceConfig.data = data.fields;
    config.dataSourceConfig.operationType = 'INPUT';
    this.manageConfigExpandableColumn(config, nestedRowIndex);
    return config;
  }

  private manageConfigExpandableColumn(config: TableConfig, nestedRowIndex: number): void {
    const shouldHaveExpandableDetailsColumn = this.indexesOfNestedTablesWithExpandableDetails.indexOf(nestedRowIndex) > -1;
    const expandableDetailsColumnIndex = config.displayConfig.columns.findIndex((column) => column.type === 'expand_details');
    const hasExpandableDetailsColumn = expandableDetailsColumnIndex !== -1;
    if (shouldHaveExpandableDetailsColumn && !hasExpandableDetailsColumn) {
      config.displayConfig.nested = true;
      const expandDetailsColumn = this.configInput.displayConfig.columns.find((column) => column.type === 'expand_details');
      if (this.disabled) {
        config.displayConfig.columns.push(expandDetailsColumn);
      } else {
        config.displayConfig.columns.splice(-1, 0, expandDetailsColumn);
      }
    } else if (!shouldHaveExpandableDetailsColumn && hasExpandableDetailsColumn) {
      config.displayConfig.nested = false;
      config.displayConfig.columns.splice(expandableDetailsColumnIndex, 1);
    }
  }

  public onNestedDataChange(data: DataChangeOutput, nestedRowIndex: number): void {
    this.dataSource.updateNestedData(data.fields, nestedRowIndex);
    this.manageExpandableDetails(data.fields, nestedRowIndex);
    const changedRowsIndexes = data.nestedRowIndexes && data.nestedRowIndexes.length ? [nestedRowIndex, ...data.nestedRowIndexes] : [nestedRowIndex];
    const changes = data.changedPlace;
    this.emitChanges(changes ? changes.changedField : '', changes ? changes.changedRowIndex : null, changedRowsIndexes);
  }

  private manageExpandableDetails(data: any[], nestedRowIndex: number): void {
    const index = this.indexesOfNestedTablesWithExpandableDetails.findIndex((nestedIndex) => nestedIndex === nestedRowIndex);
    if (data.some((element) => eval(this.configInput.displayConfig.nestedButtonCondition)(element))) {
      if (index === -1) {
        this.indexesOfNestedTablesWithExpandableDetails.push(nestedRowIndex);
      }
    } else {
      if (index !== -1) {
        this.indexesOfNestedTablesWithExpandableDetails.splice(index, 1);
      }
    }
  }

  private isTableConfigNew(config: TableConfig): boolean {
    return JSON.stringify(config) !== JSON.stringify(this.configInput);
  }
}
