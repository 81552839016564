import { AuthServerProvider } from '../../auth/auth-oauth2.service';
import { Component, Input, ViewChild, ViewEncapsulation } from '@angular/core';
import { SellionsFormsRendererComponent } from '../../../../sellions_forms/projects/forms/src/lib/components/renderer/renderer.component';
import { FormGroup } from '@angular/forms';
import { AlertController, ModalController } from '@ionic/angular';
import { MultipartFile } from '../../../../sellions_forms/projects/forms/src/lib/components/controls/multipart-files/multipart-file';
import { ProcessVariables } from '../../process/process-variables';
import {FormField} from "../../../../sellions_forms/projects/forms/src/lib/interfaces/form-field.interface";

@Component({
  selector: 'form-viewer',
  templateUrl: './form-viewer.component.html',
  styleUrls: ['./form-viewer.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FormViewerComponent {
  @Input() form: FormField[];
  @Input() processName: string;
  @Input() processError: any;
  @Input() sendDelegate: (values: ProcessVariables, callback: () => void) => void;
  @ViewChild(SellionsFormsRendererComponent) sellionsFormsRendererComponent: SellionsFormsRendererComponent;
  public dataViewBaseAPI = location.origin;
  private formData: { [key: string]: any } = {};
  public isSendButtonDisabled = false;

  constructor(public authService: AuthServerProvider, private alertController: AlertController, private modalController: ModalController) {}

  public send() {
    if (!this.isFormValid() || this.isSendButtonDisabled) {
      return;
    }

    this.isSendButtonDisabled = true;

    const formDataWithReferences = this.formData;

    const result: ProcessVariables = { variables: [], multipartFiles: [] };
    for (const key in formDataWithReferences) {
      const value = formDataWithReferences[key];
      if (Array.isArray(value) && value.length > 0 && value[0] instanceof MultipartFile) {
        this.handleMultipartFiles(result, key, value);
      } else {
        result.variables.push({ name: key, value });
      }
    }
    this.sendDelegate(result, () => {
      this.isSendButtonDisabled = false;
    });
  }

  private handleMultipartFiles(result: ProcessVariables, variableName: string, multipartFiles: Array<MultipartFile>): void {
    const fileNameArray: Array<string> = [];
    multipartFiles.forEach((part) => {
      if (part instanceof MultipartFile) {
        result.multipartFiles.push(part);
        fileNameArray.push(part.fileNameWithPath);
      } else {
        throw new Error('The array contains a type other than File');
      }
    });
    result.variables.push({ name: variableName, value: fileNameArray });
  }

  public formChangeUpdate(data: FormGroup) {
    this.formData = data.getRawValue();
  }

  public isFormValid(): boolean {
    return this.sellionsFormsRendererComponent.isFormValid();
  }

  public confirmClosing() {
    this.alertController
      .create({
        header: 'Anulowanie',
        message: 'Czy jesteś pewien, że chcesz anulować? Wprowadzone zmiany zostaną utracone.',
        buttons: [
          {
            text: 'Nie',
            role: 'cancel',
            cssClass: 'secondary',
          },
          {
            text: 'Tak',
            handler: () => this.close(),
          },
        ],
      })
      .then((alert) => alert.present());
  }

  private close() {
    this.modalController.dismiss();
  }
}
