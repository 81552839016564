import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MailTemplate } from './mail-template';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MailTemplateService {
  constructor(private http: HttpClient) {}

  getMailTemplates(): Observable<MailTemplate[]> {
    return this.http.get<MailTemplate[]>('/api/mailtemplate');
  }

  getMailTemplate(id: number): Observable<MailTemplate> {
    return this.http.get<MailTemplate>(`/api/mailtemplate/${id}`);
  }

  createMailTemplate(name, mjml, html, text, id?): Observable<MailTemplate> {
    let formData = new FormData();

    formData.append('name', name);
    formData.append('mjml', mjml);
    formData.append('html', html);
    formData.append('text', text);

    return this.http.post<MailTemplate>(
      `/api/mailtemplate/${id || ''}`,
      formData,
    );
  }

  deleteMailTemplate(id: number) {
    return this.http.delete(`/api/mailtemplate/${id}`);
  }
}
