import { Directive } from '@angular/core';
import { DictionaryItem } from '../../../interfaces/dictionary-item.interface';
import { ReferenceField } from '../../../interfaces/reference-field.interface';
import { BaseFieldComponent } from '../base-field/base-field.component';
import { DataSourceFieldUtils } from './data-source-field-utils';

@Directive({})
export abstract class DataSourceValueField extends BaseFieldComponent {
  public compareFn: ((o1: any, o2: any) => boolean) | null = (
    o1: DictionaryItem | ReferenceField | string,
    o2: DictionaryItem | ReferenceField | string,
  ): boolean => DataSourceFieldUtils.compareValues(o1, o2);
}
