import { BehaviorSubject } from 'rxjs';
import { DataService } from '../data/data.service';
import { Injectable } from '@angular/core';
import { Utils } from '../../utils/utils.class';
import { FilterSet } from '../../interfaces/filter-set.interface';
import { FilterSetOption, FilterSetOptions } from '../../interfaces/filter-set-options.interface';
import { FilterOutput } from '../../interfaces/filter-outpt.interface';
import { FiltersConfig } from '../../interfaces/filters-config.interface';
import { FilterInput } from '../../interfaces/filter-input.interface';

@Injectable()
export class FiltersService {
  private readonly iterableTypes = ['autocomplete', 'select', 'multi-select'];
  private readonly baseStorageKey = 'filters';

  public readonly defaultIcon = '';
  public filterSetSubject$ = new BehaviorSubject<FilterSet>(null);

  constructor(private dataService: DataService) {}

  public getFilters(setName: string): Promise<FilterSet> {
    return this.dataService
      .getObject(this.baseStorageKey + '_' + setName)
      .catch((error) => {
        if (error.reason.reason === 'missing') {
          this.saveFilters(setName, {
            filterConfig: {
              label: this.defaultIcon,
              filters: [],
            },
            filters: [],
            name: setName,
            options: {},
          });
        }
      });
  }

  public applyFilters(setName: string, filters: FilterOutput[], filterFunction?: (o: object) => boolean): Promise<void> {
    return new Promise((resolve, reject) => {
      this.getFilters(setName)
        .then((filterSet: FilterSet) => {
          const newFilterSet: FilterSet = { ...filterSet, filters };
          if (filterFunction) {
            newFilterSet.filterFunction = filterFunction;
          }

          this.filterSetSubject$.next({ ...newFilterSet, name: setName });

          this.saveFilters(setName, newFilterSet).then(() => resolve());
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  }

  private saveFilters(setName: string, filters: FilterSet): Promise<FilterSet> {
    return this.dataService.saveObject<FilterSet>(
      this.baseStorageKey + '_' + setName,
      filters,
    );
  }

  public setConfig(setName: string, config: FiltersConfig): void {
    this.getFilters(setName)
      .then((filters: FilterSet) => {
        if (filters) {
          filters.filterConfig = config;
        } else {
          filters = {
            filterConfig: config,
            filters: [],
            name: setName,
            options: {},
          };
        }

        this.saveFilters(setName, filters);
      })
      .catch((error) => console.error(error));
  }

  public extractOptionsFromData(setName: string, data: Array<object>): void {
    this.getFilters(setName).then((filterSet: FilterSet) => {
      if (filterSet) {
        const filters: Array<FilterInput> = filterSet.filterConfig.filters;

        filters.forEach((filter: FilterInput) => {
          if (this.iterableTypes.indexOf(filter.inputType) > -1) {
            filterSet.options[filter.column] = [];
            data.forEach((elem) => {
              this.addOptions(filter.column, filter.valueName, filter.valueKey, elem, filterSet);
            });
          }
        });

        this.saveFilters(setName, filterSet);
      }
    });
  }

  private addOptions(paramName: string, paramValueName: string, paramValueKey: string, elem: object, filterSet: FilterSet): void {
    if (Array.isArray(elem[paramName])) {
      elem[paramName].forEach((option) => {
        this.addSingleOption(paramValueName, option[paramValueKey], option, filterSet.options[paramName]);
      });
    } else {
      this.addSingleOption(paramValueName, paramName, elem, filterSet.options[paramName]);
    }
  }

  private addSingleOption(name: string, value: string, option: object, optionsArray: Array<object>): void {
    const newOption: FilterSetOption = {
      label: Utils.evaluateString(name, option),
      value: this.getElementValue(option, value),
    };

    const regex = /^(null\s*)+$/gm;

    if (!regex.test(newOption.label) && !Utils.checkIfArrayContains(optionsArray, newOption)) {
      optionsArray.push(newOption);
    }
  }

  private getElementValue(element: object, parameterName: string): string | number | object | boolean {
    const params = parameterName.split('.');
    return this.getElementValueByParams(element, params);
  }

  private getElementValueByParams(element: object, params: Array<string>): string | number | object | boolean {
    if (params.length > 1) {
      return this.getElementValueByParams(element[params[0]], params.splice(1));
    }
    return element[params[0]];
  }

  public getOptions(setName: string, paramName: string): Promise<Array<FilterSetOption>> {
    return this.getFilters(setName).then(
      (filterSet: FilterSet) => filterSet.options[paramName],
    );
  }
}
