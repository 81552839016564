import { Injectable } from '@angular/core';
import {Filter} from '../../components/table/interfaces/filter.interface';

@Injectable()
export class OfflineTableFilterService {
  public filterElements(elements: any[], filters: Filter[]): any[] {
    let data = JSON.parse(JSON.stringify(elements));
    if (filters && filters.length) {
      filters.forEach(filter => {
        if (filter.value) {
          data = data.filter(element => {
            if (filter.type === 'string') {
              return element[filter.key].toLowerCase().includes(filter.value.toLowerCase());
            }
            if (filter.type === 'date from') {
              return new Date(element[filter.key]).getTime() >= new Date(filter.value).getTime();
            }
            if (filter.type === 'date to') {
              return new Date(element[filter.key]).getTime() <= new Date(filter.value).getTime();
            }
          });
        }
      });
    }
    return data;
  }
}
