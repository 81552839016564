import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'info-popup',
  templateUrl: 'info-popup.html',
})
export class InfoPopup {
  constructor(
    public dialogRef: MatDialogRef<InfoPopup>,
    @Inject(MAT_DIALOG_DATA)
    public data: { errorHeader: string; errorMessage: string }
  ) {}

  onOkClick(): void {
    this.dialogRef.close();
  }
}
