import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  private config: any;

  constructor(private http: HttpClient) {
    this.http.get('api/config').subscribe((config) => (this.config = config));
  }

  getConfig() {
    return new Promise((resolve, reject) => this.loadConfig(resolve));
  }

  private loadConfig(resolve) {
    if (this.config) resolve(this.config);
    else setTimeout(() => this.loadConfig(resolve), 500);
  }
}
