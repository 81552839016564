import { HttpClient } from '@angular/common/http';
import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { FileDisplayerConfig } from './file-displayer-config.interface';
import { FileReference } from './file-reference.interface';

@Component({
  selector: 'sellions-layout-renderer-file-displayer',
  templateUrl: './file-displayer.component.html',
})
export class FileDisplayerComponent {
  @Input() baseUrl: string;
  @Input() fileDisplayerConfig: FileDisplayerConfig;
  @Input() fileReference: FileReference;

  private isImageLoaded = false;

  constructor(private http: HttpClient) {}

  public isImageFile(): boolean {
    return (
      this.fileReference !== null ||
      this.fileReference.contentType === 'image/jpeg' ||
      this.fileReference.contentType === 'image/gif' ||
      this.fileReference.contentType === 'image/png' ||
      this.fileDisplayerConfig.forcedFileType === 'image'
    );
  }

  public downloadFile(): void {
    this.getFile()
      .pipe(take(1))
      .subscribe((url) => window.open(url));
  }

  private getFile(): Observable<string> {
    return this.http.get(this.getFileUrl(), { responseType: 'blob' }).pipe(map((val) => URL.createObjectURL(val)));
  }

  public getFileUrl(): string {
    // temporary solution - should be changed after adjustments in engine
    return `${this.baseUrl}${this.fileDisplayerConfig.apiPath}${this.fileReference.fileId}`;
  }

  public isSpinnerShowed(): boolean {
    return this.isImageFile() && !this.isImageLoaded;
  }

  public setImageLoaded(): void {
    this.isImageLoaded = true;
  }
}
