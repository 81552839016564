import { HttpClient } from '@angular/common/http';
import { InfoPopup } from '../../components/info-popup/info-popup';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { saveAs } from 'file-saver';
import { take } from 'rxjs/operators';
import { Utils } from '../../utils/utils.class';

@Injectable()
export class ReportService {
  constructor(private dialog: MatDialog, private http: HttpClient) {}

  public download(report: { url: string; name: string }, headers = { Accept: 'text/csv' }, popup: { errorHeader: string; errorMessage: string }) {
    this.http
      .get(report.url, { headers: headers, observe: 'response', responseType: 'blob' })
      .pipe(take(1))
      .subscribe(
        (response) => {
          var fileName: string = 'default';

          if (report.name) fileName = report.name;
          else if (response.headers.has('content-disposition')) fileName = response.headers.get('content-disposition').replace(/.*; filename=/, '');

          if (fileName) fileName = fileName.replace('${current_date}', Utils.currentDate());

          saveAs(response.body, fileName);
        },
        () => {
          const dialogRef = this.dialog.open(InfoPopup, {
            width: '250px',
            data: popup,
          });

          dialogRef
            .afterClosed()
            .pipe(take(1))
            .subscribe(() => {});
        },
      );
  }
}
