import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ContentPage } from '../../page.interface';
import { DataService } from '../../services/data/data.service';
import { finalize, take } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Utils } from '../../utils/utils.class';

@Component({
  selector: 'sellions-layout-renderer-details',
  templateUrl: './details.component.html',
})
export class DetailsComponent implements OnInit {
  @Input() detailsScreenConfig: any; // screensy, na podstawie których definiowane jest jakie zmienne z listy są do pobrania
  @Input() screenData: any; // lista na podstawie której pobierane są odpowiednie zmienne.
  @Input() database: any;
  @Input() baseUrl: string;
  @Output() elementClicked = new EventEmitter<{
    element: Object;
    target: EventTarget;
  }>();
  @Output() loadingEvent = new EventEmitter<boolean>();

  public loading: boolean;

  constructor(private http: HttpClient, private dataService: DataService) {}

  ngOnInit() {
    if (this.detailsScreenConfig && this.detailsScreenConfig.dataSourceConfig) {
      this.getData();
    }
  }

  evaluateValueByKeyName(variableNameToBind: string): string {
    return Utils.evaluateString(variableNameToBind, this.screenData);
  }

  onClick(element: any, $event: MouseEvent) {
    this.elementClicked.emit({ element, target: $event.target });
  }

  getData(): void {
    this.changeLoadingStatus(true);

    const config = this.detailsScreenConfig.dataSourceConfig;
    const url = config && this.baseUrl ? this.dataService.getUrl(this.baseUrl, config.onlineDataSourceUrl) : '';
    if (url) {
      this.http
        .get<ContentPage>(url)
        .pipe(take(1))
        .pipe(
          finalize(() => {
            this.changeLoadingStatus(false);
          }),
        )
        .subscribe(
          (data) => this.manageData(data.content),
          (error) => console.error(error),
        );
    }
  }

  manageData(data: any[]): void {
    if (data.length > 1) {
      this.screenData = data;
    }

    if (data.length === 1) {
      this.screenData = data[0];
    }
  }

  private changeLoadingStatus(status: boolean): void {
    this.loadingEvent.emit(status);
    this.loading = status;
  }

  getItemConditionsEvaluation(item) {
    if (item.displayConditions) {
      return this.screenData[item.displayConditions];
    } else {
      return true;
    }
  }
}
