import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import { TableConfig } from '../table/interfaces/table-config.interface';
import { floatingActionButtonAnimations } from './floating-action-button.animations';

@Component({
  selector: 'sellions-layout-renderer-floating-action-button',
  templateUrl: './floating-action-button.component.html',
  animations: floatingActionButtonAnimations,
})
export class FloatingActionButtonComponent implements OnChanges {
  @Input() config: TableConfig;
  @Output() clickedButton = new EventEmitter<any>();

  mainButtonIcon = 'add';
  fabButtons = [];
  buttons = [];
  fabTogglerState = 'inactive';

  constructor() {}

  ngOnChanges(): void {
    this.initializeButton();
  }

  showItems() {
    this.fabTogglerState = 'active';
    this.buttons = this.fabButtons;
  }

  hideItems() {
    this.fabTogglerState = 'inactive';
    this.buttons = [];
  }

  onToggleFab() {
    this.buttons.length ? this.hideItems() : this.showItems();
  }

  onClick(index: number) {
    this.clickedButton.emit(this.buttons[index]);
  }

  private initializeButton() {
    this.fabButtons = this.config.screenActions.processList;
    this.mainButtonIcon = this.config.screenActions.actionListIcon;
  }
}
