import { BaseFieldComponent } from '../base-field/base-field.component';
import { Component, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ReferenceField } from '../../../interfaces/reference-field.interface';

@Component({
  selector: 'forms-reference',
  templateUrl: './reference.component.html',
  styleUrls: ['./reference.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ReferenceComponent),
      multi: true,
    },
  ],
})
export class ReferenceComponent extends BaseFieldComponent {
  public mapReference(reference: ReferenceField) {
    if (!reference) {
      return null;
    }
    return reference.rowFormat ? reference.rowFormat.title : reference.dataViewColumn;
  }
}
