import { BaseFieldComponent } from '../base-field/base-field.component';
import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'forms-currency',
  templateUrl: './currency.component.html',
    providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CurrencyComponent),
      multi: true,
    },
  ],
})
export class CurrencyComponent extends BaseFieldComponent implements OnInit {
  @Input() displayCurrency: boolean = true;
  @Input() currency: string = 'zł';
  @Input() decimalSeparator: string = ',';
  public displayValue: string = '';

  ngOnInit() {
    this.value = this.formField && this.formField.predefinedValue;
    if (this.value) this.displayValue = this.value.toString();
  }

  public mask(input: HTMLInputElement) {
    this.displayValue = this.filterInput(input.value);
    input.value = this.displayValue;
    this.writeValue(parseFloat(input.value.replace(this.decimalSeparator, '.')));
  }

  private filterInput(value: string): string {
    const sign = value[0] === '-' ? '-' : '';
    value = value.replace(new RegExp(`[^\\d\\${this.decimalSeparator}]`, 'g'), '');

    if (value.indexOf(this.decimalSeparator) > -1) {
      let [integerPart, decimalPart] = value.split(this.decimalSeparator);
      if (decimalPart.length > 2) decimalPart = decimalPart.slice(0, 2);

      return sign + (integerPart || '') + this.decimalSeparator + (decimalPart || '');
    }

    return sign + value;
  }
}
