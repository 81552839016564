import { BaseFieldComponent } from '../base-field/base-field.component';
import { Component, forwardRef, OnInit } from '@angular/core';
import { GeolocalizationDialogComponent } from './no-geolocation-dialog/no-geolocation-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'forms-localization',
  templateUrl: './localization.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LocalizationComponent),
      multi: true,
    },
  ],
})
export class LocalizationComponent extends BaseFieldComponent implements OnInit {
  constructor(private dialog: MatDialog) {
    super();
  }

  ngOnInit() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => this.writeValue(position.coords),
        (error) => this.dialog.open(GeolocalizationDialogComponent),
      );
    } else {
      this.dialog.open(GeolocalizationDialogComponent);
    }
  }
}
