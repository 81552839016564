import {Injectable} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";

@Injectable()
export class ErrorTranslationService {

  public constructor(
      private translateService: TranslateService) {
  }

  public getErrorMessage(error): Promise<string> {
    const errorKey = this.getErrorKey(error)
    return this.translateService.get(errorKey).toPromise()
  }

  private getErrorKey(error): string {
    if (error && error.error && error.error.fieldErrors && error.error.fieldErrors[0].error) {
      return error.error.fieldErrors[0].error
    }

    if (error.error.message) {
      return error.error.message;
    }

    return null;
  }
}
