import { BaseFieldComponent } from '../base-field/base-field.component';
import { Component, forwardRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'forms-floating',
  templateUrl: './floating.component.html',
  styleUrls: ['./floating.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FloatingComponent),
      multi: true,
    },
  ],
})
export class FloatingComponent extends BaseFieldComponent {
  @Input() decimalSeparator: string = ',';
  public displayValue: string = '';

  public mask(input: HTMLInputElement) {
    this.displayValue = this.filterInput(input.value);
    input.value = this.displayValue;
    this.writeValue(parseFloat(input.value.replace(this.decimalSeparator, '.')));
  }

  private filterInput(value: string): string {
    const sign = value[0] === '-' ? '-' : '';
    value = value.replace(new RegExp(`[^\\d\\${this.decimalSeparator}]`, 'g'), '');

    if (value.indexOf(this.decimalSeparator) > -1) {
      let [integerPart, decimalPart] = value.split(this.decimalSeparator);
      return sign + (integerPart || '') + this.decimalSeparator + (decimalPart || '');
    }

    return sign + value;
  }
}
