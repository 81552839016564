import { DictionaryItem } from '../../../interfaces/dictionary-item.interface';
import { PredefinedValue } from '../../../interfaces/predefined-value.interface';
import { ReferenceField } from '../../../interfaces/reference-field.interface';

export class DataSourceFieldUtils {
  public static compareValues(
    dropdownValue: DictionaryItem | ReferenceField | PredefinedValue | string,
    providedValue: DictionaryItem | ReferenceField | PredefinedValue | string,
  ): boolean {
    if (!providedValue) {
      return false;
    }

    if (this.isObjectWithId(dropdownValue)) {
      return this.compareObjectValues(
        dropdownValue as DictionaryItem | ReferenceField | PredefinedValue,
        providedValue as DictionaryItem | ReferenceField | PredefinedValue | string,
      );
    }

    return dropdownValue === providedValue;
  }

  private static compareObjectValues(
    dropdownObject: DictionaryItem | ReferenceField | PredefinedValue,
    providedValue: DictionaryItem | ReferenceField | PredefinedValue | string,
  ): boolean {
    if (typeof providedValue === 'string') {
      let name: string;

      if (this.isDictionaryItem(dropdownObject)) name = (dropdownObject as DictionaryItem).name;
      if (this.isPredefinedValue(dropdownObject)) name = (dropdownObject as PredefinedValue).value;

      return dropdownObject.id.toString() === providedValue || name === providedValue;
    }

    if (this.isObjectWithId(providedValue)) {
      let result = dropdownObject.id === providedValue.id;

      if (result && this.isDictionaryItem(dropdownObject)) {
        result = this.compareDictionaryItems(dropdownObject as DictionaryItem, providedValue);
      }

      return result;
    }
  }

  private static isObjectWithId(value: DictionaryItem | ReferenceField | PredefinedValue | string): boolean {
    return value && typeof value === 'object' && 'id' in value;
  }

  private static compareDictionaryItems(
    dropdownDictionaryItem: DictionaryItem,
    providedObject: DictionaryItem | ReferenceField | PredefinedValue,
  ): boolean {
    if (!this.isDictionaryItem(providedObject)) {
      return false;
    }

    return dropdownDictionaryItem.dictionaryDefinitionId === (providedObject as DictionaryItem).dictionaryDefinitionId;
  }

  private static isDictionaryItem(object: DictionaryItem | ReferenceField | PredefinedValue): boolean {
    return 'dictionaryDefinitionId' in object;
  }

  private static isPredefinedValue(object: DictionaryItem | ReferenceField | PredefinedValue): boolean {
    return 'id' in object && 'value' in object;
  }
}
