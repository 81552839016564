import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import {IonicModule} from '@ionic/angular';

import {CardViewModule} from '../card-view/card-view.module';
import {AnalyticalSpacesPage} from "./analytical-spaces.page";
import {SharedModule} from "../shared/shared.module";
import {RouterModule} from "@angular/router";
import {analyticalSpacesViewRoute} from "./analytical-spaces-view.route";
import {SellionsLayoutRendererLibModule} from "../../../sellions_layout_renderer/projects/sellions-layout-renderer-lib/src/lib/sellions-layout-renderer-lib.module";
import {AnalyticalSpaceCreateAndEditComponent} from "./create/analytical-space-create-and-edit.component";
import {VisibilityComponent} from "../visibility/visibility.component";
import {VisibilityModule} from "../visibility/visibility.module";
import {AnalyticalSpaceService} from "./analytical-space.service";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        CardViewModule,
        SharedModule,
        RouterModule.forChild(analyticalSpacesViewRoute),
        SellionsLayoutRendererLibModule,
        ReactiveFormsModule,
        VisibilityModule,
        MatFormFieldModule,
        MatInputModule,
    ],
    declarations: [
        AnalyticalSpacesPage,
        AnalyticalSpaceCreateAndEditComponent
    ],
    entryComponents: [
        AnalyticalSpacesPage,
        AnalyticalSpaceCreateAndEditComponent,
        VisibilityComponent,
    ],
    providers: [AnalyticalSpaceService],
})
export class AnalyticalSpacesModule {
}
