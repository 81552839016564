import { Injectable } from '@angular/core';
import { SortOption } from '../../interfaces/sort-option.interface';

@Injectable()
export class ConfigServiceProvider {
  api = {
    definition: '/api/dictionary-items/definition',
    dataView: '/api/data-view',
  };

  getUrl(dataViewBaseAPI: string, identifier: string): string {
    if (dataViewBaseAPI && this.api[identifier]) {
      return dataViewBaseAPI + this.api[identifier];
    }
    return null;
  }

  getUrlWithParameter(dataViewBaseAPI: string, identifier: string, parameter: string, sort: SortOption[]): any {
    const url = this.getUrl(dataViewBaseAPI, identifier);
    const sortParameter = this.getSortParameter(sort);
    return url ? `${url}/${parameter}${sortParameter}` : null;
  }

  private getSortParameter(sort: SortOption[]): string {
    if (!sort || sort.length === 0) {
      return '';
    }

    let sortParameter = '';

    sort.forEach((sortOption: SortOption) => {
      sortParameter += sortParameter === '' ? '?' : '&';
      sortParameter += `sort=${sortOption.property},${sortOption.direction}`;
    });

    return sortParameter;
  }
}
