import { Inject, Injectable, QueryList } from '@angular/core';
import {
  CdkDrag,
  CdkDragMove,
  CdkDragRelease,
  CdkDropList,
} from '@angular/cdk/drag-drop';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class DragAndDropService {
  dropLists: CdkDropList[] = [];
  currentHoverDropListId?: string;

  constructor(@Inject(DOCUMENT) private document: Document) {}

  public register(dropLists: QueryList<CdkDropList>) {
    this.dropLists = [];
    dropLists.forEach((list) => this.dropLists.push(list));
  }

  public getActiveDropLists(): CdkDropList[] {
    return this.dropLists;
  }

  dragMoved(event: CdkDragMove<any>) {
    const elementFromPoint = this.document.elementFromPoint(
      event.pointerPosition.x,
      event.pointerPosition.y,
    );

    if (!elementFromPoint) {
      this.currentHoverDropListId = undefined;
      return;
    }

    const dropList = elementFromPoint.classList.contains('cdk-drop-list')
      ? elementFromPoint
      : elementFromPoint.closest('.cdk-drop-list');

    if (!dropList) {
      this.currentHoverDropListId = undefined;
      return;
    }

    this.currentHoverDropListId = dropList.id;
  }

  dragReleased(event: CdkDragRelease) {
    this.currentHoverDropListId = undefined;
  }

  isDropAllowed(drag: CdkDrag, drop: CdkDropList) {
    if (drag.data === 'list' && drop.id.includes('fieldsSubform')) {
      return false;
    } else {
      return this.currentHoverDropListId == null
        ? true
        : drop.id === this.currentHoverDropListId;
    }
  }
}
