import { ActiveFilterListComponent } from './components/filters/active-filter-list/active-filter-list';
import { CdkTableModule } from '@angular/cdk/table';
import { CommonModule } from '@angular/common';
import { DasherDatePipe } from './pipes/secure/dasher-date.pipe';
import { DasherPipe } from './pipes/secure/dasher.pipe';
import { DataService } from './services/data/data.service';
import { DetailsComponent } from './components/details/details.component';
import { EllipsisPipe } from './pipes/secure/ellipsis.pipe';
import { FileDisplayerComponent } from './components/file-displayer/file-displayer.component';
import { FilterComponent } from './components/filters/filter/filter.component';
import { FiltersComponent } from './components/filters/filters.component';
import { FilterSelectionComponent } from './components/filters/filter-selection/filter-selection.component';
import { FiltersService } from './services/filters/filters.service';
import { FloatingActionButtonComponent } from './components/floating-action-button/floating-action-button.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InfoPopup } from './components/info-popup/info-popup';
import { ListDisplayerComponent } from './components/list-displayer/list-displayer.component';
import { ListRendererComponent } from './components/list/list.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatNativeDateModule } from '@angular/material/core';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { NgModule } from '@angular/core';
import { OfflineTableFilterService } from './services/filters/offline-table-filter.service';
import { RemovalConfirmationDialog } from './components/table/removal-confirmation-dialog/removal-confirmation-dialog.component';
import { ReportService } from './services/report/report.service';
import { SecurePipe } from './pipes/secure/secure.pipe';
import { SimpleFiltersComponent } from './components/simple-filters/simple-filters.component';
import { SimpleFiltersDialogComponent } from './components/simple-filters/simple-filters-dialog/simple-filters-dialog.component';
import { SimpleFiltersService } from './services/simple-filters/simple-filters.service';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { TableCellComponent } from './components/table/cell/cell.component';
import { TableFilterComponent } from './components/table/filter/table-filter.component';
import { TableRendererComponent } from './components/table/table.component';
import { TableWithFloatingActionButtonComponent } from './components/table-with-floating-action-button/table-with-floating-action-button.component';

@NgModule({
  declarations: [
    ListRendererComponent,
    TableRendererComponent,
    FloatingActionButtonComponent,
    TableWithFloatingActionButtonComponent,
    DetailsComponent,
    FileDisplayerComponent,
    ListDisplayerComponent,
    FiltersComponent,
    FilterSelectionComponent,
    FilterComponent,
    ActiveFilterListComponent,
    RemovalConfirmationDialog,
    SecurePipe,
    EllipsisPipe,
    DasherPipe,
    DasherDatePipe,
    SpinnerComponent,
    TableFilterComponent,
    TableCellComponent,
    InfoPopup,
    SimpleFiltersComponent,
    SimpleFiltersDialogComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatTableModule,
    MatPaginatorModule,
    CdkTableModule,
    MatSortModule,
    MatButtonModule,
    MatDialogModule,
    MatSelectModule,
    MatInputModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatAutocompleteModule,
    MatCheckboxModule,
    MatIconModule,
  ],
  exports: [
    ListRendererComponent,
    TableRendererComponent,
    FloatingActionButtonComponent,
    TableWithFloatingActionButtonComponent,
    DetailsComponent,
    FileDisplayerComponent,
    ListDisplayerComponent,
    FiltersComponent,
    FilterSelectionComponent,
    ActiveFilterListComponent,
    SpinnerComponent,
    TableFilterComponent,
    TableCellComponent,
    InfoPopup,
    SimpleFiltersComponent,
    SimpleFiltersDialogComponent,
  ],
  providers: [
    DataService,
    FiltersService,
    OfflineTableFilterService,
    ReportService,
    SimpleFiltersService,
  ],
  entryComponents: [
    FilterSelectionComponent,
    RemovalConfirmationDialog,
    InfoPopup,
    SimpleFiltersDialogComponent,
  ],
})
export class SellionsLayoutRendererLibModule {}
