import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { DataLayout, Field } from './data-layout';
import { Action } from './action';
import { ApiRequest } from '../shared/api-request';
import { IonInfiniteScroll } from '@ionic/angular';

class Filter {
  constructor(public key: string, public value: any) {}
}

@Component({
  selector: 'card-view',
  templateUrl: './card-view.component.html',
  styleUrls: ['./card-view.component.scss'],
})
export class CardViewComponent implements OnChanges, OnInit {
  @Input() data;
  @Input() count;
  @Input() details;
  @Input() dataLayout: DataLayout;
  @Input() actions: Array<Action>;
  @Input() sortAndFilter = true;
  @Input() isDataLoading = false;

  @Output() onClick = new EventEmitter<any>();
  @Output() detailsLoader = new EventEmitter<any>();
  @Output() dataLoader = new EventEmitter<ApiRequest>();

  @ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;

  @Input() selectedMode;
  mode = 'list';

  filters: Array<Filter> = [];

  constructor() {}

  ngOnChanges() {
    if (this.details) this.detailsCard = this.details[0];
  }

  ngOnInit() {
    if (this.details)
      this.actions.unshift(
        new Action('open', (data) => this.openDetails(data)),
      );
    if (this.selectedMode) this.mode = this.selectedMode;
    else if (this.dataLayout && this.dataLayout.displayedFields.length <= 4)
      this.mode = 'row-4';
  }

  sortField;
  sortOrder = 'asc';
  sort() {
    if (this.data && this.data.length > 0) this.loadRows(0);
  }

  filter() {
    this.loadRows(0);
  }

  callEvent(callback) {
    callback;
  }

  callClick(data) {
    this.onClick.emit(data);
  }

  doInfinite() {
    if (this.count == 0 || this.data.length < this.count) this.loadRows();
    this.infiniteScroll.complete();
  }

  private i = 1;

  loadRows(startingPage?: number) {
    if (startingPage != undefined) this.i = startingPage;

    let query = {};
    this.filters.forEach((f) => (query[f.key] = [f.value]));

    this.dataLoader.emit(
      new ApiRequest(
        this.i++,
        undefined,
        this.sortField,
        this.sortOrder,
        this.filters.length > 0 ? query : undefined,
      ),
    );
  }

  opened = false;
  detailsCard;
  detailsRow;

  openDetails(data: any) {
    this.detailsRow = data;
    this.detailsLoader.emit(data);
    this.opened = true;
  }

  getField(data: any, field: Field) {
    return CardViewComponent.getField(data, field);
  }

  static getField(data: any, field: Field) {
    if (field.func) return field.func(data);

    let result = data;
    field.field
      .split('.')
      .forEach((s) => (result = result ? result[s] : undefined));
    return result;
  }

  doRefresh(event) {
    this.loadRows(0);

    setTimeout(() => {
      event.target.complete();
    }, 500);
  }

  getFilterAndSortFields() {
    return this.dataLayout.displayedFields.filter((a) => a.field);
  }

  addFilter() {
    this.filters.push(new Filter('', ''));
  }

  removeFilter(index) {
    this.filters.splice(index, 1);
  }
}
