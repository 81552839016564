export const FormFieldTypes = [
  'textarea',
  'radio',
  'checkbox',
  'dropdown',
  'number',
  'list',
  'bool',
  'datepicker',
  'phoneNumber',
  'nip',
  'text',
  'integer',
  'floating',
  'email',
  'link',
  'currency',
  'timepicker',
  'localization',
  'multipartFiles',
];
