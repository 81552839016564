import { Component, OnDestroy, OnInit } from '@angular/core';
import { DataLayout, Field } from '../card-view/data-layout';
import { Action } from '../card-view/action';
import { ProcessService } from '../process/process.service';
import { ActivatedRoute, Router } from '@angular/router';
import {
  AlertController,
  ModalController,
  ToastController,
} from '@ionic/angular';
import { Subscription } from 'rxjs';
import { DataPage } from '../shared/data-page';
import { FormService } from '../forms/form.service';
import { ProcessGroup } from '../process/groups/process-group';
import { ProcessGroupDetails } from '../process/groups/process-group-details';
import { Visibility } from '../visibility/visibility';
import { ProcessGroupVariable } from '../process/groups/process-group-variable';

@Component({
  selector: 'process-groups',
  templateUrl: './process-groups-list-page.component.html',
  styleUrls: ['./process-groups-list-page.component.scss'],
})
export class ProcessGroupsListPage
  extends DataPage
  implements OnInit, OnDestroy
{
  buttonList = [{ object: this, name: 'Create', callback: this.create }];

  data: Array<ProcessGroup>;
  count = 0;

  dataLayout = new DataLayout(
    [new Field('ID', 'id'), new Field('Nazwa', 'name')],
    undefined,
  );
  actions = [new Action('close', (data) => this.delete(data))];

  open = (data) => this.router.navigate(['process-groups', data.id, 'edit']);

  private subs = new Subscription();

  constructor(
    private processService: ProcessService,
    private formService: FormService,
    private router: Router,
    private route: ActivatedRoute,
    private modalController: ModalController,
    private toastController: ToastController,
    private alertController: AlertController,
  ) {
    super();
  }

  dataLoader() {
    this.subs.add(
      this.processService.getGroups().subscribe((resp) => {
        this.data = resp;
        this.count = resp.length;
      }),
    );
  }

  details = [];

  loadDetails(data: any) {}

  create() {
    this.alertController
      .create({
        header: 'Creating',
        inputs: [
          {
            name: 'name',
            type: 'text',
            placeholder: 'Nazwa',
          },
        ],
        buttons: [
          {
            text: 'Ok',
            handler: (values) => {
              this.processService
                .saveGroupDetails(
                  new ProcessGroupDetails(
                    null,
                    values.name,
                    new Map<string, Visibility>(),
                    [],
                  ),
                )
                .subscribe((group) =>
                  this.router.navigate(['process-groups', group.id, 'edit']),
                );
            },
          },
        ],
      })
      .then((alert) => alert.present());
  }

  delete(group) {
    this.alertController
      .create({
        header: 'Delete',
        message: `Are you sure you want to delete process group ${group.name}? Member processes will not be removed, but they will lose group visibilities and variables`,
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
            cssClass: 'secondary',
          },
          {
            text: 'Yes',
            handler: () =>
              this.processService
                .deleteGroup(group.id)
                .subscribe(() => this.data.splice(this.data.indexOf(group), 1)),
          },
        ],
      })
      .then((alert) => alert.present());
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
