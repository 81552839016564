import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { DictionaryEntry } from '../../../dictionary.interface';
import { FilterInput } from '../../../interfaces/filter-input.interface';
import { FiltersService } from '../../../services/filters/filters.service';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Utils } from '../../../utils/utils.class';
import { FilterSetOption, FilterSetOptions } from '../../../interfaces/filter-set-options.interface';

@Component({
  selector: 'sellions-layout-renderer-filter',
  templateUrl: './filter.component.html',
})
export class FilterComponent implements OnChanges {
  @Input() filter: FilterInput;
  @Input() selectedValue;
  @Input() dataSetName: string;

  data: Array<FilterSetOption> = [];
  displayed = '';
  selected: string | Array<string | DictionaryEntry> = '';
  filteredOptions: Array<FilterSetOption>;
  selectAllEnabled = true;
  fieldId: string;

  constructor(private filtersService: FiltersService) {}

  ngOnChanges(changes: SimpleChanges) {
    this.fieldId = this.filter.label.replace(/\W/g, '_');
    this.filtersService.getOptions(this.dataSetName, this.filter.column).then((options) => {
      this.data = options;
      this.filteredOptions = this.data;
      this.selected = changes.selectedValue && changes.selectedValue.currentValue && changes.selectedValue.currentValue.value;
      this.displayed = this.findLabelByValue(this.selected);
    });
  }

  public compareObject(obj1: Object, obj2: Object): boolean {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
  }

  private findLabelByValue(value: any): string {
    const obj = this.data && this.data.find((elem) => this.compareObject(elem.value, value));
    return obj && obj.label;
  }

  filterOptions(value: Event) {
    const input = (value.target as HTMLInputElement).value;
    this.filteredOptions = this.data.filter((element) => Utils.checkForStringMatch(element.label, input, true));
  }

  autocompleterSelect(optionSelected: MatAutocompleteSelectedEvent) {
    this.displayed = optionSelected.option.value.label;
    this.selected = optionSelected.option.value.value || optionSelected.option.value.label;
    console.log(this.displayed);
    console.log(this.selected);
  }

  toggleSelectAll() {
    if (this.selected.length === 2 && (this.selected as Array<string>).indexOf('') > -1) {
      this.selected = (this.selected as Array<string>).filter((option) => option !== '');
    } else if (this.selected.length === this.data.length) {
      if ((this.selected as Array<string>).indexOf('') === -1) {
        this.selected = '';
      }
    }
  }

  selectAll() {
    this.selected = [''];
  }
}
