import { DataSourceParams } from '../interfaces/data-source-params.interface';
import { TableDataSource } from './table-data-source.class';
import {OfflineTableFilterService} from '../../../services/filters/offline-table-filter.service';
import {DataService} from '../../../services/data/data.service';
import {HttpClient} from '@angular/common/http';


export class InputDataSource extends TableDataSource {
  constructor(protected http: HttpClient, protected dataService: DataService, protected offlineTableFilterService: OfflineTableFilterService) {
    super(http, dataService);
  }

  loadData(params: DataSourceParams) {
    this.checkParams(params);
    const data = this.offlineTableFilterService.filterElements(params.data, params.filters);
    this.dataSubject.next(data);
    this.dataInitializedSubject.next(true);
  }

  private checkParams(params: DataSourceParams) {
    if (params['data'] == undefined) throw `No data provided in params!`;
  }
}
