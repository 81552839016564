import { Routes } from '@angular/router';

export const pageRoutes: Routes = [
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full',
  },
  {
    path: 'accessdenied',
    redirectTo: '/home',
    pathMatch: 'full',
  },
  {
    path: 'home',
    loadChildren: './home/home.module#HomePageModule',
    data: {
      authorities: ['ROLE_ANALYST', 'ROLE_PROCESS_EDITOR', 'ROLE_USER_DASHBOARD', 'ROLE_ADMIN'], //add a more general engine role
      icon: 'home',
      title: 'Home',
    },
  },
  {
    path: 'report-definitions',
    loadChildren: './report-definitions/report-definitions.module#ReportDefinitionsModule',
    data: {
      authorities: ['ROLE_ANALYST', 'ROLE_ADMIN'],
      icon: 'podium',
      title: 'Kibana',
    },
  },
    { path: 'user-dashboard',
        loadChildren: './user-dashboard/user-dashboard.module#UserDashboardModule',
        data: {
            authorities: ['ROLE_USER_DASHBOARD', 'ROLE_ADMIN'],
            icon: 'square-outline',
            title: 'Kibana',
        },
    },
  {
    path: 'analytical-spaces',
    loadChildren: './analytical-spaces/analytical-spaces.module#AnalyticalSpacesModule',
    data: {
      authorities: ['ROLE_ADMIN'],
      icon: 'easel',
      title: 'Analytical spaces',
    },
  },
  {
    path: 'flex-definitions',
    loadChildren: './flex-definitions/flex-definitions.module#FlexDefinitionsModule',
    data: {
      authorities: ['ROLE_FLEX_DEFINITION_VIEWER', 'ROLE_FLEX_DEFINITION_EDITOR'],
      icon: 'analytics',
      title: 'Struktury danych',
    },
  },
  {
    path: 'data-views',
    loadChildren: './data-views/data-views.module#DataViewModule',
    data: {
      authorities: ['ROLE_DATA_VIEW_VIEWER', 'ROLE_DATA_VIEW_EDITOR', 'ROLE_ADMIN'],
      icon: 'add',
      title: 'Widok danych',
    },
  },
  {
    path: 'dictionary-definitions',
    loadChildren: './dictionary-definition/dictionary-definition.module#DictionaryDefinitionPageModule',
    data: {
      icon: 'bookmarks',
      authorities: ['ROLE_ADMIN'],
      title: 'Definicje słowników',
    },
  },
  {
    path: 'kpi-definitions',
    loadChildren: './kpi-definitions/kpi-definition.module#KpiDefinitionPageModule',
    data: {
      authorities: ['ROLE_KPI_DEFINITION_VIEWER', 'ROLE_KPI_DEFINITION_EDITOR', 'ROLE_ADMIN'],
      icon: 'trending-up',
      title: 'KPI',
    },
  },
  {
    path: 'ranking-definitions',
    loadChildren: './ranking-definitions/ranking-definition.module#RankingDefinitionPageModule',
    data: {
      authorities: ['ROLE_RANKING_DEFINITION_VIEWER', 'ROLE_RANKING_DEFINITION_EDITOR', 'ROLE_ADMIN'],
      icon: 'trophy',
      title: 'Rankingi',
    },
  },
  {
    path: 'data-import',
    loadChildren: './data-import/data-import.module#DataImportModule',
    data: {
      authorities: ['ROLE_DATA_IMPORT_MAPPING_READER', 'ROLE_DATA_IMPORT_MAPPING_EDITOR', 'ROLE_DATA_IMPORT_UPLOADER', 'ROLE_ADMIN'],
      icon: 'document',
      title: 'Data import',
    },
  },
    {
        path: 'user-tasks',
        loadChildren: './user-tasks/user-task.module#UserTaskPageModule',
        data: {
            authorities: ['ROLE_USER_TASK_VIEWER', 'ROLE_USER_TASK_EDITOR', 'ROLE_ADMIN'],
            icon: 'done-all',
            title: 'Zadania',
        },
    },
  {
    path: 'process',
    loadChildren: './process/process.module#ProcessPageModule',
    data: {
      authorities: ['ROLE_PROCESS_EDITOR', 'ROLE_ADMIN'],
      icon: 'cog',
      title: 'Processes',
    },
  },
  {
    path: 'process-groups',
    loadChildren: './process-groups/process-groups.module#ProcessGroupsPageModule',
    data: {
      authorities: ['ROLE_PROCESS_GROUP_EDITOR', 'ROLE_ADMIN'],
      icon: 'folder',
      title: 'Process groups',
    },
  },
  {
    path: 'business-rules',
    loadChildren: './business-rules/business-rules.module#BusinessRulesModule',
    data: {
      authorities: ['ROLE_PROCESS_EDITOR', 'ROLE_ADMIN'],
      icon: 'business',
      title: 'Business rules',
    },
  },
  {
    path: 'process-instances',
    loadChildren: './process-instances/process-instances.module#ProcessInstancesModule',
    data: {
      authorities: ['ROLE_PROCESS_EDITOR', 'ROLE_ADMIN'],
      icon: 'rocket',
      title: 'Processes Instances',
    },
  },
  {
    path: 'tasks',
    loadChildren: './tasks/tasks.module#TasksPageModule',
    data: {
      authorities: ['ROLE_USER', 'ROLE_ADMIN'],
      icon: 'checkbox-outline',
      title: 'Tasks',
    },
  },
  {
    path: 'mail',
    loadChildren: './mail/mail.module#MailPageModule',
    data: {
      authorities: ['ROLE_PROCESS_EDITOR', 'ROLE_ADMIN'],
      icon: 'mail',
      title: 'Mail templates',
    },
  },
  {
    path: 'site-templates',
    loadChildren: './site-templates/site-templates.module#SiteTemplatesModule',
    data: {
      authorities: ['ROLE_PROCESS_EDITOR', 'ROLE_ADMIN'],
      icon: 'desktop',
      title: 'Site templates',
    },
  },
  {
    path: 'generic-data-view',
    loadChildren: './generic-data-view/generic-data-view.module#GenericDataViewModule',
  },
];

// extract routes used in main menu
// the routes must be kept in the original array to be able to compile loadChildren properly
export const mainMenuRoutes = pageRoutes.filter((route) => route.data && route.data.authorities);

export const routesRequiringBackend = ['kpi-definitions', 'ranking-definitions', 'data-import'];
