import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { TaskService } from './task-service';
import { Observable } from 'rxjs';
import { HttpService } from '../shared/http.service';
import { Visibility } from '../visibility/visibility';
import { map, mapTo } from 'rxjs/operators';
import * as FileSaver from 'file-saver';
import * as XmlJs from 'xml-js';
import * as X2Js from 'x2js';
import { ProcessGroup } from './groups/process-group';
import { ProcessGroupMembership } from './groups/process-group-membership';
import { ProcessGroupDetails } from './groups/process-group-details';
import { ProcessVariable, ProcessVariables } from './process-variables';

@Injectable({
  providedIn: 'root',
})
export class ProcessService {
  private formatters = {
    xml: this.processToStringXml,
    json: this.processToStringJson,
  };

  constructor(private http: HttpClient, private httpService: HttpService) {}

  getProcesses(): Observable<any> {
    return this.http.get<any>('api/process');
  }

  getProcessesPage(request) {
    return this.http.get<Array<any>>('api/process', {
      params: this.httpService.getParams(request),
      observe: 'response',
    });
  }

  getProcess(name: string) {
    return this.http
      .get(`api/process/${name}`, {
        headers: { observe: 'response' },
      })
      .pipe(
        map((p) => {
          p['visibility'] = this.getVisibilities(p['visibility']);
          return p;
        }),
      );
  }

  getProcessImages(ids: Array<string>) {
    return this.http.get('api/process/image', {
      params: new HttpParams().set('ids', ids.join(',')),
    });
  }

  getVersion(name: string, deploymentId: string) {
    return this.http
      .get(`api/process/${name}/versions/${deploymentId}`, {
        headers: { observe: 'response' },
      })
      .pipe(
        map((p) => {
          p['visibility'] = this.getVisibilities(p['visibility']);
          return p;
        }),
      );
  }

  getProcessWithForm(name: string) {
    return this.http
      .get(`api/process/${name}/formDefinition`, {
        headers: { observe: 'response' },
      });
  }

  getVisibilities(visibilities: any) {
    let res = {};
    Object.keys(visibilities).forEach((v) => {
      if (!visibilities[v]) res[v] = new Visibility([], [], true);
      else res[v] = visibilities[v];
    });
    return res;
  }

  saveProcess(name: string, xml: any, visibility: any, dataRunnable: boolean, keepOldVisibility: boolean): Observable<any> {
    return this.http.post(`api/process/${name}`, {
      bpmnXml: xml,
      visibility: visibility,
      dataRunnable: dataRunnable,
      keepOldVisibility: keepOldVisibility,
    });
  }

  saveProcessOnDisc(format: string, name: string, xml: any, visibility: any, dataRunnable: boolean) {
    if (!this.formatters.hasOwnProperty(format)) {
      alert('Invalid export format ' + format);
      return;
    }
    const processString = this.formatters[format](xml, visibility, dataRunnable);
    const blob = new Blob([processString]);

    FileSaver.saveAs(blob, name + '.' + format);
  }

  private processToStringXml(xml: any, visibility: any, dataRunnable: boolean) {
    const options = { compact: true, ignoreComment: true, spaces: 4 };
    const processDtoJSON = XmlJs.xml2js(xml, options);
    processDtoJSON['definitions']['metadata'] = {
      visibility: JSON.stringify(visibility),
      dataRunnable: dataRunnable,
    };
    return XmlJs.js2xml(processDtoJSON, options).replace('&&', '&amp;&amp;');
  }

  private processToStringJson(xml: any, visibility: any, dataRunnable: boolean) {
    let processDto = {
      xml: xml,
      visibility: visibility,
      dataRunnable: dataRunnable,
    };
    return JSON.stringify(processDto);
  }

  getExtensions() {
    return this.http.get<Array<TaskService>>('api/process/extensions');
  }

  deleteProcess(name: string) {
    return this.http.delete(`api/process/${name}`, {
      headers: { observe: 'response' },
    });
  }

  handleProcessSchedule(data) {
    return this.http.post(`api/process/handleProcessSchedule`, data);
  }

  getProcessVersions(key, request) {
    return this.http.get<Array<any>>(`api/process/${key}/versions`, {
      params: this.httpService.getParams(request),
      observe: 'response',
    });
  }

  public startProcess(process, processVariables?: ProcessVariables): Observable<any> {
    if (processVariables.multipartFiles && Array.isArray(processVariables.multipartFiles) && processVariables.multipartFiles.length > 0) {
      return this.startProcessWithMultipartFiles(process, processVariables);
    }
    return this.startProcessWithoutMultipartFiles(process, processVariables.variables);
  }

  private startProcessWithoutMultipartFiles(process, variables?: Array<ProcessVariable>): Observable<any> {
    const variablesObject = this.mapVariablesToVariablesObject(variables);
    return this.http.post<any>(`api/process/${process.key}/start`, variablesObject);
  }

  private startProcessWithMultipartFiles(process, processVariables?: ProcessVariables): Observable<any> {
    const formData = new FormData();
    processVariables.multipartFiles.forEach((multipartFile) => formData.append('multipartFiles', multipartFile.file, multipartFile.fileNameWithPath));
    formData.append('variables', this.getVariablesBlob(processVariables.variables));
    return this.http.post<any>(`api/process/v2/${process.key}/start`, formData);
  }

  private getVariablesBlob(variables: Array<ProcessVariable>): Blob {
    const variablesObject = this.mapVariablesToVariablesObject(variables);
    return new Blob([JSON.stringify(variablesObject, null, 2)], { type: 'application/json' });
  }

  private mapVariablesToVariablesObject(variables: Array<ProcessVariable>): object {
    const variablesObject = {};
    if (variables && Array.isArray(variables)) {
      variables.forEach((variable) => {
        variablesObject[variable.name] = variable.value;
      });
    }
    return variablesObject;
  }

  restore(processKey: any, id: any) {
    return this.http.post(`api/process/${processKey}/restore`, {
      processId: id,
    });
  }

  importProcess(processString, keepOldVisibility): Observable<string> {
    const importProcessDTO = this.parseXmlOrJson(processString);

    let processId = importProcessDTO['processId'];
    let xml = importProcessDTO['xml'];
    let visibility = importProcessDTO['visibility'];
    let dataRunnable = importProcessDTO['dataRunnable'];

    return this.saveProcess(processId, xml, visibility, dataRunnable, keepOldVisibility).pipe(mapTo(processId));
  }

  private parseXmlOrJson(processString: string) {
    //dwie libki do parsowania xmli w jednej klasie to troche overkill,
    //ale niestety x2Js nie umie formatowac przy eksporcie, z kolei xmlJs robi jakąś dziwną magię przy imporcie
    const x2Js = new X2Js();
    if (processString.startsWith('<?xml')) {
      let processXmlAndMetadataAsJson = x2Js.xml2js(processString);
      let obj = processXmlAndMetadataAsJson['definitions']['metadata'];
      //metadane musza byc wewnatrz definitions albo pluje sie ze jest wiele root node'ow
      //z kolei jak dam jeszcze parenta dla calosci to pluje sie ze schema nie jest w roocie
      delete processXmlAndMetadataAsJson['definitions']['metadata'];
      let processBackToXml = x2Js.js2xml(processXmlAndMetadataAsJson);
      //visibility bylo splaszczone jako string w xmlu, przywracamy strukture
      obj.visibility = JSON.parse(obj.visibility);
      obj.xml = processBackToXml;
      obj.processId = this.getProcessId(processXmlAndMetadataAsJson);
      return obj;
    } else {
      let obj = JSON.parse(processString);
      let xml = obj['xml'];
      let processXmlAsJson = x2Js.xml2js(xml);
      obj.processId = this.getProcessId(processXmlAsJson);
      return obj;
    }
  }

  private getProcessId(process: any): string {
    return process['definitions']['process']['_id'];
  }

  getGroupMembership(key: String): Observable<ProcessGroupMembership> {
    return this.http.get<ProcessGroupMembership>(`api/process-group-membership/${key}`);
  }

  assignProcessDefinitionToGroup(key: String, groupMembership: number[]): Observable<any> {
    return this.http.post(`api/process-group-membership/${key}`, {
      groupIds: groupMembership,
    });
  }

  getGroups(): Observable<ProcessGroup[]> {
    return this.http.get<ProcessGroup[]>(`api/process-groups`);
  }

  getGroupDetails(id: number): Observable<ProcessGroupDetails> {
    return this.http.get<ProcessGroupDetails>(`api/process-groups/${id}`);
  }

  saveGroupDetails(processGroup: ProcessGroupDetails): Observable<ProcessGroupDetails> {
    return this.http.post<ProcessGroupDetails>(`api/process-groups`, processGroup);
  }

  deleteGroup(groupId: number): Observable<any> {
    return this.http.delete(`api/process-groups/${groupId}`);
  }
}
