import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { JsonConfigService } from '../config/json-config.service';
import { Observable } from 'rxjs';

@Injectable()
export class GenericDataViewRouteActivateGuard implements CanActivate {
  constructor(private jsonConfigService: JsonConfigService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return new Observable<boolean>((observer) => {
      this.jsonConfigService.getCurrentUserLayout().subscribe((currentUserLayout) => {
        if (currentUserLayout && this.canUserLoadView(currentUserLayout, route)) {
          observer.next(true);
        } else {
          observer.next(false);
        }
        observer.complete();
      });
    });
  }

  canUserLoadView(currentUserLayout: any, route: ActivatedRouteSnapshot): boolean {
    const layoutComponentViewIdList = currentUserLayout.layout.map((layout) => layout.viewId);
    if (layoutComponentViewIdList.indexOf(route.params['viewId']) > -1) {
      return true;
    } else {
      this.router.navigate(['/home']);
      return false;
    }
  }
}
