import { Injectable } from '@angular/core';
import { Credentials } from '../credentials';
import { AuthServerProvider } from '../auth-oauth2.service';
import { Principal } from '../principal.service';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  constructor(
    private principal: Principal,
    private authServerProvider: AuthServerProvider,
  ) {}

  login(credentials: Credentials) {
    return new Promise((resolve, reject) => {
      this.authServerProvider.login(credentials).subscribe(
        (data) => {
          this.principal.identity(true).then((account) => {
            resolve(data);
          });
        },
        (err) => {
          this.logout();
          reject(err);
        },
      );
    });
  }

  logout() {
    this.authServerProvider.logout().subscribe();
    this.principal.authenticate(null);
  }
}
