import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { VisibilitySelectComponent } from './visibility-select/visibility-select.component';
import { Visibility } from './visibility';
import { UsersService } from '../users/users/users.service';
import { GroupsService } from '../users/groups/groups.service';
import { take } from 'rxjs/operators';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-visibility',
  templateUrl: './visibility.component.html',
  styleUrls: ['./visibility.component.scss'],
})
export class VisibilityComponent implements OnInit {
  @Input() visibility: Visibility;
  @Input() disabled: boolean = false;
  @Input() shouldDisplayVisibilityOpenButton: boolean = true;

  private modal;
  private users = [];
  private groups = [];
  public selectedUsers = [];
  public selectedGroups = [];
  public isVisibilityOpen = false;

  constructor(
    private modalController: ModalController,
    private usersService: UsersService,
    private groupsService: GroupsService,
  ) {}

  ngOnInit() {
    this.setUpData();
  }

  private setUpData() {
    const users$ = this.usersService.getUsersNoParams().pipe(take(1));
    const groups$ = this.groupsService.getGroupsNoParams().pipe(take(1));
    forkJoin([users$, groups$]).subscribe((data) => {
      this.users = data[0];
      this.groups = data[1];
      this.setUpSelectedEntries();
    });
  }

  private setUpSelectedEntries() {
    if (this.visibility) {
      this.isVisibilityOpen = this.visibility.opened;
      const people = this.visibility.people;
      const groups = this.visibility.groups;
      if (people && people.length) {
        this.selectedUsers = people.map((element) =>
          this.users.find((user) => user.id === element),
        );
      }
      if (groups && groups.length) {
        this.selectedGroups = groups.map((group) =>
          group.map((element) =>
            this.groups.find((entry) => entry.id === element),
          ),
        );
      }
    }
  }

  public async openVisibilitySelect(type: string, group?) {
    this.modal = await this.modalController.create({
      component: VisibilitySelectComponent,
      componentProps: {
        type: type,
        entriesInput: this.getEntriesInput(type, group),
        entries: type === 'groups' ? this.groups : this.users,
      },
    });
    this.modal.onDidDismiss().then((data) => {
      if (data && data.data) {
        const selected = data.data.selected;
        if (type === 'users') {
          this.selectedUsers = selected;
        } else if (type === 'groups') {
          if (group) {
            const index = this.selectedGroups.indexOf(group);
            this.selectedGroups[index] = selected;
          } else if (
            this.selectedGroups.length === 1 &&
            !this.selectedGroups[0].length
          ) {
            this.selectedGroups[0] = selected;
          } else {
            this.selectedGroups.push(selected);
          }
        }
      }
    });
    return await this.modal.present();
  }

  getEntriesInput(type, group) {
    if (type === 'users') {
      return this.selectedUsers;
    } else if (type === 'groups' && group) {
      return group;
    } else {
      return null;
    }
  }

  public deleteUser(entry) {
    const i = this.selectedUsers.findIndex(
      (selectedEntry) => selectedEntry.id === entry.id,
    );
    this.selectedUsers.splice(i, 1);
  }

  public deleteGroupEntry(entry, group) {
    const entryIndex = group.findIndex(
      (selectedEntry) => selectedEntry.id === entry.id,
    );
    const groupIndex = this.selectedGroups.indexOf(group);
    this.selectedGroups[groupIndex].splice(entryIndex, 1);
    if (!this.selectedGroups[groupIndex].length) {
      this.selectedGroups.splice(groupIndex, 1);
    }
  }

  public updateVisibility(): void {
    this.visibility.opened = this.isVisibilityOpen;
    if (this.isVisibilityOpen) {
      this.visibility.people = [];
      this.visibility.groups = [];
    } else {
      this.visibility.people = this.selectedUsers.map((user) => user.id);
      this.visibility.groups = this.selectedGroups.map((group) =>
        group.map((entry) => entry.id),
      );
    }
  }

  public close() {
    this.modalController.dismiss();
  }

  public save() {
    this.updateVisibility();
    this.close();
  }
}
