import { BoolComponent } from '../controls/bool/bool.component';
import { CheckboxComponent } from '../controls/checkbox/checkbox.component';
import { CurrencyComponent } from '../controls/currency/currency.component';
import { DatepickerComponent } from '../controls/datepicker/datepicker.component';
import { DictionaryComponent } from '../controls/dictionary/dictionary.component';
import { DropdownComponent } from '../controls/dropdown/dropdown.component';
import { EmailComponent } from '../controls/email/email.component';
import { FloatingComponent } from '../controls/floating/floating.component';
import { IntegerComponent } from '../controls/integer/integer.component';
import { LinkComponent } from '../controls/link/link.component';
import { LocalizationComponent } from '../controls/localization/localization.component';
import { MultipartFilesComponent } from '../controls/multipart-files/multipart-files.component';
import { NipComponent } from '../controls/nip/nip.component';
import { NumberComponent } from '../controls/number/number.component';
import { PhoneInputComponent } from '../controls/phone-input/phone-input.component';
import { RadioComponent } from '../controls/radio/radio.component';
import { ReferenceComponent } from '../controls/reference/reference.component';
import { TextComponent } from '../controls/text/text.component';
import { TextAreaComponent } from '../controls/textarea/textarea.component';
import { TimepickerComponent } from '../controls/timepicker/timepicker.component';

// maps types to components
export const ComponentTypesMap = {
  phoneNumber: PhoneInputComponent,
  nip: NipComponent,
  text: TextComponent,
  integer: IntegerComponent,
  floating: FloatingComponent,
  email: EmailComponent,
  link: LinkComponent,
  currency: CurrencyComponent,
  timepicker: TimepickerComponent,
  localization: LocalizationComponent,
  multipartFiles: MultipartFilesComponent,
  number: NumberComponent,
  textarea: TextAreaComponent,
  radio: RadioComponent,
  checkbox: CheckboxComponent,
  dropdown: DropdownComponent,
  bool: BoolComponent,
  reference: ReferenceComponent,
  dictionary: DictionaryComponent,
  datepicker: DatepickerComponent,
};
