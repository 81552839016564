import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoginService } from './login.service';
import { Credentials } from '../credentials';
import { Router } from '@angular/router';
import { StateStorageService } from '../state-storage.service';
import { AuthService } from '../auth.service';
import { AlertController, LoadingController } from '@ionic/angular';
import { JsonConfigService } from '../../config/json-config.service';

@Component({
  selector: 'login-form',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  authenticationError: boolean;

  constructor(
    private formBuilder: FormBuilder,
    private loginService: LoginService,
    private authService: AuthService,
    private router: Router,
    private stateStorageService: StateStorageService,
    private alertController: AlertController,
    private loadingController: LoadingController,
    private jsonConfigService: JsonConfigService,
  ) {}

  user;

  ngOnInit(): void {
    this.stateStorageService.getPreviousLoggedUser().then((user) => {
      this.user = user;
      this.buildForm();
    });
  }

  private buildForm() {
    this.loginForm = this.formBuilder.group({
      email: [
        this.user ? this.user.login : '',
        Validators.compose([Validators.required]),
      ],
      password: ['', Validators.required],
    });
    if (this.user) this.loginForm.controls['email'].disable();
  }

  close() {
    this.user = null;
    this.loginForm.controls['email'].reset();
    this.loginForm.controls['email'].enable();
  }

  onSignIn() {
    this.loadingController
      .create({
        message: 'Logowanie...',
        duration: 5000,
      })
      .then((l) => {
        l.present();
        this.login();
      });
  }

  private login() {
    this.loginService
      .login(
        new Credentials(
          this.loginForm.controls['email'].value,
          this.loginForm.controls['password'].value,
        ),
      )
      .then(() => {
        this.authenticationError = false;
        if (
          this.router.url === '/register' ||
          this.router.url === '/activate' ||
          this.router.url === '/finishReset' ||
          this.router.url === '/requestReset'
        ) {
          this.router.navigate(['']);
        }

        this.stateStorageService.getPreviousState().then((previousState) => {
          if (previousState)
            this.stateStorageService
              .resetPreviousState()
              .then(() =>
                this.router.navigate([previousState.name], {
                  queryParams: previousState.params,
                }),
              );
          else this.router.navigate(['/']);
        });
        this.closeLoading();
      })
      .catch(() => {
        this.showLoginError();
        this.closeLoading();
      });
  }

  closeLoading() {
    this.loadingController.getTop().then((top) => {
      if (top) this.loadingController.dismiss();
    });
  }

  showLoginError() {
    this.alertController
      .create({
        header: 'Błędne hasło',
        message: 'Spróbuj ponownie',
        buttons: ['OK'],
      })
      .then((a) => {
        this.loginForm.controls['password'].reset();
        a.present();
      });
  }

  keyDownFunction(event) {
    if (event.keyCode == 13) this.onSignIn();
  }
}
