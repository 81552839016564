import {Routes} from '@angular/router';
import {AnalyticalSpacesPage} from "./analytical-spaces.page";
import {AnalyticalSpaceCreateAndEditComponent} from "./create/analytical-space-create-and-edit.component";

export const analyticalSpacesViewRoute: Routes = [
    {
        path: '',
        component: AnalyticalSpacesPage,
        data: {
            authorities: [
                'ROLE_ADMIN'
            ],
        },
    },
    {
        path: ':id/edit',
        component: AnalyticalSpaceCreateAndEditComponent,
        data: {
            authorities: [
                'ROLE_ADMIN'
            ],
        },
    },
];
