import {
  Component,
  ElementRef,
  HostListener,
  Input,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import Modeler from 'dmn-js/lib/Modeler';
import { BusinessRuleService } from './business-rule.service';
import { AlertController, LoadingController } from '@ionic/angular';
import * as FileSaver from 'file-saver';

@Component({
  template: `
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="primary">
          <ion-button (click)="save()">Zapisz</ion-button>
        </ion-buttons>
        <ion-buttons slot="primary">
          <ion-button (click)="export()">Export</ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <div #canvas style="width: 100%; height: 100%; padding: 10px"></div>
    </ion-content>
  `,
})
export class BusinessRuleEditPage {
  constructor(
    private route: ActivatedRoute,
    private businessRuleService: BusinessRuleService,
    private router: Router,
    private loading: LoadingController,
    private alertController: AlertController,
  ) {}

  @ViewChild('canvas')
  canvas: ElementRef;

  key: string;

  modeler: Modeler;

  dmn: string;

  @Input()
  forwardedDmnId: string;

  // noinspection JSUnusedGlobalSymbols
  async ionViewDidEnter() {
    let loader = await this.loading.create({
      showBackdrop: false,
      spinner: 'crescent',
      translucent: true,
    });
    loader.present();
    try {
      // generate random id; otherwise second and next opened editor doesn't appear
      this.canvas.nativeElement.id =
        'canvas' + Math.random().toString(36).substring(2);

      this.key = await new Promise<string>((accept) => {
        // await ... toPromise() doesn't work here
        this.route.params.subscribe((it) => {
          accept(it.id);
        });
      });
      if (this.key) {
        // edit
        this.dmn = await this.businessRuleService
          .getBusinessRuleDmn(this.key)
          .toPromise();
      } else if (this.forwardedDmnId) {
        // preview
        this.dmn = await this.businessRuleService
          .getBusinessRuleDmn(this.forwardedDmnId)
          .toPromise();
      } else {
        // new
        this.dmn = await this.businessRuleService
          .getNewBusinessRuleDmn()
          .toPromise();
      }
      this.modeler = new Modeler({
        container: '#' + this.canvas.nativeElement.id,
      });
      await new Promise((accept, reject) => {
        this.modeler.importXML(this.dmn, (error) => {
          if (error) reject(error);
          else accept(null);
        });
      });
    } finally {
      await loader.dismiss();
    }
  }

  async save() {
    let dmn = await this.getDmnFromModeler();
    if (this.dmnNeedsSave(dmn)) {
      let key = this.modeler.getActiveView().element.id;
      if (
        key != this.key &&
        (await this.businessRuleService.isBusinessRuleExists(key).toPromise())
      ) {
        await new Promise((accept) => {
          this.alertController
            .create({
              message: `Reguła biznesowa ${key} już istnieje. Czy chcesz ją nadpisać?`,
              buttons: [
                {
                  text: 'Nie',
                  role: 'cancel',
                  cssClass: 'secondary',
                },
                {
                  text: 'Tak',
                  handler: () => accept(null),
                },
              ],
            })
            .then((it) => it.present());
        });
      }
      await this.businessRuleService.saveBusinessRuleDmn(key, dmn).toPromise();
      if (key != this.key) {
        this.router.navigate(['business-rules', key, 'edit']);
      }
      this.dmn = dmn;
      this.key = key;
    }
  }

  async export() {
    let dmn = await this.getDmnFromModeler();
    let key = this.modeler.getActiveView().element.id;
    const blob = new Blob([dmn]);

    FileSaver.saveAs(blob, key + '.xml');
  }

  private async getDmnFromModeler(): Promise<string> {
    return await new Promise<string>((accept, reject) => {
      this.modeler.saveXML({ format: true }, function (err, dmn) {
        if (err) reject(err);
        else {
          accept(dmn);
        }
      });
    });
  }

  private dmnNeedsSave(dmn: string): boolean {
    return dmn != this.dmn;
  }

  async needsSave(): Promise<boolean> {
    return this.dmnNeedsSave(await this.getDmnFromModeler());
  }

  @HostListener('window:beforeunload', ['$event'])
  async preventWindowClosingIfNeedsSave($event: any) {
    if (await this.needsSave()) {
      $event.returnValue = true;
    }
  }
}
