import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { PredefinedValue } from '../../../../../interfaces/predefined-value.interface';
import { ReferenceField } from '../../../../../interfaces/reference-field.interface';
import { DictionaryItem } from '../../../../../interfaces/dictionary-item.interface';
import { DataSourceOption } from '../../data-source-options';

@Component({
  selector: 'forms-data-source-field-option',
  templateUrl: './data-source-field-option.component.html',
  styleUrls: ['./data-source-field-option.component.scss'],
})
export class DataSourceFieldOptionComponent implements OnChanges {
  @Input() option!: DataSourceOption;
  @Input() dataSource!: 'dictionary' | 'dataView' | 'predefined';

  protected displayOption: string;

  constructor() {}

  ngOnChanges(): void {
    this.prepareDisplayValue();
  }

  private prepareDisplayValue(): void {
    if (typeof this.option === 'string') {
      this.displayOption = this.option;
      return;
    }

    switch (this.dataSource) {
      case 'dictionary':
        this.displayOption = (this.option as DictionaryItem).name;
        break;
      case 'dataView':
        this.displayOption = (this.option as ReferenceField).dataViewColumn;
        break;
      case 'predefined':
        this.displayOption = (this.option as PredefinedValue).value;
        break;
    }
  }
}
