import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FilterOutput } from '../../interfaces/filter-outpt.interface';
import { FiltersConfig } from '../../interfaces/filters-config.interface';
import { FilterSelectionComponent } from './filter-selection/filter-selection.component';
import { FiltersService } from '../../services/filters/filters.service';
import { MatDialog } from '@angular/material/dialog';
import { take } from 'rxjs/operators';

@Component({
  selector: 'sellions-layout-renderer-filters',
  templateUrl: './filters.component.html',
})
export class FiltersComponent implements OnChanges {
  @Input() dataSetName: string;
  @Input() filtersConfig: FiltersConfig;
  @Input() buttonImgPath: string;
  @Input() displayCancelButton = false;
  @Input() set outsideFilters(value: FilterOutput[]) {
    this.updateAppliedFilters(value);
  }
  public appliedFilters: FilterOutput[] = [];

  constructor(private filtersService: FiltersService, public dialog: MatDialog) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.filtersConfig) {
      this.filtersService.setConfig(this.dataSetName, this.filtersConfig);
    }
  }

  openFilters(): void {
    const dialogRef = this.dialog.open(FilterSelectionComponent, {
      data: {
        dataSetName: this.dataSetName,
        ...this.filtersConfig,
        displayCancelButton: this.displayCancelButton,
      },
    });

    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe(
        (filters: FilterOutput[]) => {
          if (filters) {
            this.appliedFilters = filters;

            this.filtersService.applyFilters(this.dataSetName, this.appliedFilters);
          }
        },
        (error) => console.error(error),
      );
  }

  updateAppliedFilters(filters: FilterOutput[]): void {
    if (filters && filters.length > 0) {
      filters.forEach((filter) => {
        const index = this.appliedFilters.findIndex((appliedFilter) => appliedFilter.column === filter.column);
        if (index > -1) {
          this.appliedFilters.splice(index, 1);
        }
        this.appliedFilters.push(filter);
      });
      this.filtersService.applyFilters(this.dataSetName, this.appliedFilters);
    }
  }
}
