import { BaseFieldComponent } from '../base-field/base-field.component';
import { Component, forwardRef, Inject } from '@angular/core';
import { DataSourceFieldUtils } from '../data-source/data-source-field-utils';
import { DataSourceOption, DataSourceOptions } from '../data-source/data-source-options';
import { DictionaryItem } from '../../../interfaces/dictionary-item.interface';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSelectionListChange } from '@angular/material/list';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { PredefinedValue } from '../../../interfaces/predefined-value.interface';
import { ReferenceField } from '../../../interfaces/reference-field.interface';
import { SmartFilterComponent } from '../../smart-component/smart-filter.component';
import { SmartFilterData } from '../../../interfaces/smart-filter-data.interface';
import { take } from 'rxjs/operators';

@Component({
  selector: 'forms-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxComponent),
      multi: true,
    },
  ],
})
export class CheckboxComponent extends BaseFieldComponent {
  constructor(@Inject(MatDialog) private matDialog: MatDialog) {
    super();
  }

  public selectionChange(event: MatSelectionListChange): void {
    this.writeValue(
      event.source.selectedOptions.selected.map((option) => (this.isPredefinedDataSource() ? (option.value as PredefinedValue).value : option.value)),
    );
  }

  public checkIfSelected(value: ReferenceField | DictionaryItem | string): boolean {
    if (!Array.isArray(this.value)) {
      return false;
    }
    const result = this.value.some((selectedValue) => DataSourceFieldUtils.compareValues(value, selectedValue));
    return result;
  }

  public findValue(providedValue: DataSourceOption | string): DataSourceOption | string {
    if (this.formField.dataSource !== 'dataView') {
      return providedValue;
    }
    return (
      (this.formField.values as ReferenceField[]).find((value: ReferenceField) => DataSourceFieldUtils.compareValues(value, providedValue)) ||
      providedValue
    );
  }

  onReferencesPicked() {
    const matDialogRef = this.openCheckboxModalDialog();

    matDialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe((result) => {
        if (result) {
          this.writeValue(this.isPredefinedDataSource() ? (result as PredefinedValue[]).map((option) => option.value) : result);
        }
      });
  }

  private isPredefinedDataSource(): boolean {
    return this.formField.dataSource === 'predefined';
  }

  private openCheckboxModalDialog(): MatDialogRef<SmartFilterComponent, DataSourceOptions> {
    const modalData: SmartFilterData = {
      values: this.formField.values,
      selectedValues: this.value,
      dataSource: this.formField.dataSource,
    };

    return this.matDialog.open<SmartFilterComponent, SmartFilterData>(SmartFilterComponent, {
      disableClose: true,
      height: '400px',
      width: '600px',
      data: modalData,
    });
  }
}
