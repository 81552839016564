import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
@Component({
  selector: 'forms-no-geolocation-dialog',
  templateUrl: './no-geolocation-dialog.component.html',
})
export class GeolocalizationDialogComponent {
  constructor(private dialogRef: MatDialogRef<GeolocalizationDialogComponent>) {}

  close() {
    this.dialogRef.close(false);
  }
}
