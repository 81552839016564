import { Component, Input } from '@angular/core';
import { FilterInput } from '../../../interfaces/filter-input.interface';
import { FilterOutput } from '../../../interfaces/filter-outpt.interface';
import { FilterSet } from '../../../interfaces/filter-set.interface';
import { FiltersService } from '../../../services/filters/filters.service';

@Component({
  selector: 'sellions-layout-renderer-active-filter-list',
  templateUrl: 'active-filter-list.html',
})
export class ActiveFilterListComponent {
  @Input() filterSetName: string;
  public filters: string[] = [];

  constructor(private filtersService: FiltersService) {}

  ngOnInit() {
    this.filtersService.filterSetSubject$.subscribe(
      (filterSet: FilterSet) => {
        if (filterSet && filterSet.name === this.filterSetName) {
          this.filters = filterSet.filters.map((filter: FilterOutput) => {
            const f = filterSet.filterConfig.filters.find(
              (element: FilterInput) => element.column === filter.column,
            );

            return f.label || '';
          });
        }
      },
      (error) => console.error(error),
    );
  }

  clear() {
    this.filtersService.applyFilters(this.filterSetName, []);
  }
}
