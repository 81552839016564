import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpService } from './http.service';
import { IonicModule } from '@ionic/angular';
import { ToolbarComponent } from './header/toolbar.component';
import { RouterModule } from '@angular/router';
import { IconCachedComponent } from '../icon-cached/icon-cached.component';
import { IdGeneratorService } from './id-generator.service';
import { EngineSpinnerComponent } from './spinner/spinner.component';

@NgModule({
  imports: [CommonModule, IonicModule, RouterModule],
  declarations: [ToolbarComponent, IconCachedComponent, EngineSpinnerComponent],
  exports: [ToolbarComponent, IconCachedComponent, EngineSpinnerComponent],
  providers: [HttpService, IdGeneratorService],
})
export class SharedModule {}

declare global {
  interface Array<T> {
    flatMap(func: (T) => T): Array<T>;
  }
}

if (!Array.prototype.flatMap) {
  Array.prototype.flatMap = function <T>(this: T[], func: (T) => T): T[] {
    return this.reduce((acc, x) => acc.concat(func(x)), []);
  };
}
