import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { flatMap } from 'rxjs/operators';
import { ProcessService } from '../../process/process.service';
import { ProcessGroupDetails } from '../../process/groups/process-group-details';
import {
  ProcessGroupVariable,
  VariableType,
} from '../../process/groups/process-group-variable';
import { MultipleVisibilityComponent } from '../../visibility/multiple/multiple-visibility.component';
import { ModalController } from '@ionic/angular';

@Component({
  templateUrl: './process-groups-edit.page.html',
  styleUrls: ['./process-groups-edit.page.scss'],
})
export class ProcessGroupsEditPage implements OnInit {
  group: ProcessGroupDetails;

  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private processService: ProcessService,
    private modalController: ModalController,
  ) {}

  ngOnInit(): void {
    this.route.params
      .pipe(flatMap((params) => this.processService.getGroupDetails(params.id)))
      .subscribe((group) => {
        this.group = group;
      });
  }

  ngAfterViewInit(): void {}

  save() {
    this.processService
      .saveGroupDetails(this.group)
      .subscribe((group) => (this.group = group));
  }

  getVariableTypes(): String[] {
    return Object.keys(VariableType).map((key) => key.toString());
  }

  addVariable() {
    this.group.variables.push(
      new ProcessGroupVariable(0, null, VariableType.STRING, '', 0, 0, false),
    );
  }

  removeVariable(index: number) {
    this.group.variables.splice(index, 1);
  }

  compareFn(e1: VariableType, e2: VariableType): boolean {
    return e1 == e2;
  }

  openVisibilitySettings() {
    this.modalController
      .create({
        component: MultipleVisibilityComponent,
        componentProps: {
          visibilities: this.group.visibilities,
        },
      })
      .then((alert) => alert.present());
  }
}
