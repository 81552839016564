import { Component, Input, OnInit } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import { Visibility } from '../visibility';
import { VisibilityComponent } from '../visibility.component';

@Component({
  selector: 'multiple-visibility',
  templateUrl: './multiple-visibility.component.html',
  styleUrls: ['./multiple-visibility.component.scss'],
})
export class MultipleVisibilityComponent implements OnInit {
  permissionTypes: any = ['VIEW', 'EDIT', 'START', 'SUBPROCESS_START', 'OWNER'];

  _visibilities: any;
  visibilityKeys: string[];
  modal;

  constructor(
    private alertController: AlertController,
    private modalCtrl: ModalController,
  ) {}

  ngOnInit() {}

  @Input()
  set visibilities(visibilities: any) {
    this.visibilityKeys = Object.keys(visibilities);
    this._visibilities = visibilities;
  }

  get visibilities() {
    return this._visibilities;
  }

  getAvailablePermissionTypes() {
    return this.permissionTypes.filter(
      (type) => this.visibilityKeys.indexOf(type) === -1,
    );
  }

  addVisibility() {
    this.alertController
      .create({
        header: 'Dodaj widoczność',
        inputs: this.getAvailablePermissionTypes().map((v) => ({
          name: v,
          type: 'radio',
          label: v,
          value: v,
        })),
        buttons: [
          {
            text: 'Anuluj',
            role: 'cancel',
          },
          {
            text: 'Dodaj',
            handler: (value) => this.confirmNewVisibility(value),
          },
        ],
      })
      .then((a) => a.present());
  }

  private confirmNewVisibility(value: string) {
    if (this.visibilityKeys.indexOf(value) === -1) {
      this.visibilityKeys.push(value);
      this.visibilities[value] = new Visibility([], []);
    }
  }

  displayRemoveDialog(key: string) {
    this.alertController
      .create({
        header: 'Czy chcesz usunąć widoczność?',
        buttons: [
          {
            text: 'Anuluj',
            role: 'cancel',
          },
          {
            text: 'Usuń',
            handler: () => this.remove(key),
          },
        ],
      })
      .then((a) => a.present());
  }

  remove(key: string) {
    const index = this.visibilityKeys.indexOf(key);
    if (index !== -1) {
      this.visibilityKeys.splice(index, 1);
      delete this.visibilities[key];
    }
  }

  async openVisibilitySettings(visibility) {
    this.modal = await this.modalCtrl.create({
      component: VisibilityComponent,
      componentProps: {
        visibility: visibility,
      },
    });
    return await this.modal.present();
  }

  close() {
    this.modalCtrl.dismiss();
  }
}
