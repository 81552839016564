import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FilterSubjectDictionary } from '../../interfaces/filter-subject-dictionary.type';
import { SimpleFilterSet } from '../../interfaces/simple-filter-set.interface';

@Injectable()
export class SimpleFiltersService implements OnDestroy {
  private filterData$: FilterSubjectDictionary = {};

  ngOnDestroy(): void {
    for (const filterKey in this.filterData$) {
      this.filterData$[filterKey].complete();
      this.filterData$[filterKey].unsubscribe();
    }
  }

  public initSet(setId: string) {
    this.filterData$[setId] = new BehaviorSubject<SimpleFilterSet>({});
  }

  public setFilters(setId: string, filters: SimpleFilterSet) {
    this.filterData$[setId].next(filters);
  }

  public getFilter(setId: string): Observable<{ [key: string]: string }> {
    if (!this.filterData$[setId]) this.initSet(setId);
    return this.filterData$[setId].pipe(map((set: SimpleFilterSet) => this.mapSetToDictionary(set)));
  }

  private mapSetToDictionary(set: SimpleFilterSet): { [key: string]: string } {
    let dictionary = {};
    Object.keys(set).forEach((key: string) => {
      if (set[key].value !== '') dictionary[key] = set[key].value;
    });
    return dictionary;
  }
}
