import { Component, ElementRef, NgZone, ViewChild } from '@angular/core';
import { DataPage } from '../shared/data-page';
import { ApiRequest } from '../shared/api-request';
import { DataLayout, Field } from '../card-view/data-layout';
import { Router } from '@angular/router';
import { BusinessRuleService } from './business-rule.service';
import { BusinessRuleDTO } from './business-rule.dto';
import { Action } from '../card-view/action';
import { AlertController } from '@ionic/angular';

@Component({
  template: `
    <ion-header>
      <engine-toolbar [title]="'Reguły biznesowe'" [buttons]="buttonList">
        <input
          #fileInput
          type="file"
          (change)="import($event)"
          style="display:none;"
        />
      </engine-toolbar>
    </ion-header>
    <ion-content>
      <card-view
        [data]="data"
        [dataLayout]="dataLayout"
        [actions]="actions"
        (onClick)="edit($event)"
        [selectedMode]="'row-4'"
        (dataLoader)="dataLoader($event)"
        [sortAndFilter]="false"
      ></card-view>
    </ion-content>
  `,
})
export class BusinessRuleListPage extends DataPage {
  constructor(
    private router: Router,
    private businessRuleService: BusinessRuleService,
    private alertController: AlertController,
    private ngZone: NgZone,
  ) {
    super();
  }

  buttonList = [
    { object: this, name: 'Create', callback: this.create },
    { object: this, name: 'Import', callback: this.callInputFileTag },
  ];

  @ViewChild('fileInput')
  fileInput: ElementRef;

  data: Array<BusinessRuleDTO>;
  dataLayout = new DataLayout(
    [
      new Field('Nazwa', 'name'),
      new Field('Klucz', 'key'),
      new Field('Wersja', 'version'),
    ],
    undefined,
  );
  actions = [new Action('close', (data) => this.delete(data))];

  async dataLoader(apiRequest: ApiRequest) {
    this.data = await this.businessRuleService.getBusinessRules().toPromise();
  }

  create() {
    this.router.navigate(['business-rules', 'create']);
  }

  edit(data: any) {
    this.router.navigate(['business-rules', data.key, 'edit']);
  }

  callInputFileTag() {
    this.fileInput.nativeElement.click();
  }

  import(event) {
    const selectedFiles = event.target.files[0];

    let fileReader = new FileReader();
    fileReader.onload = (e) => {
      let key = selectedFiles.name.replace('.xml', '');
      this.businessRuleService
        .saveBusinessRuleDmn(key, fileReader.result)
        .subscribe((dmn) =>
          this.router.navigate(['business-rules', key, 'edit']),
        );
    };
    fileReader.readAsText(selectedFiles);
  }

  async delete(data) {
    (
      await this.alertController.create({
        header: 'Delete',
        message: `Are you sure you want to delete ${data.key} business rule?`,
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
            cssClass: 'secondary',
          },
          {
            text: 'Yes',
            handler: async () => {
              await this.businessRuleService
                .deleteBusinessRule(data.key)
                .toPromise();
              this.data.splice(this.data.indexOf(data), 1);
              this.ngZone.run(() => {}); // redisplay changed data
            },
          },
        ],
      })
    ).present();
  }
}
