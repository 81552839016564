import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'icon-cached',
  templateUrl: './icon-cached.component.html',
  styleUrls: ['./icon-cached.component.scss'],
})
export class IconCachedComponent implements OnInit {
  @Input() name: String;

  constructor() {}

  ngOnInit() {}
}
