import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SimpleFiltersDialogComponent } from './simple-filters-dialog/simple-filters-dialog.component';
import { SimpleFilterSet } from '../../interfaces/simple-filter-set.interface';
import { SimpleFiltersService } from '../../services/simple-filters/simple-filters.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'sellions-layout-renderer-simple-filters',
  templateUrl: './simple-filters.component.html',
})
export class SimpleFiltersComponent {
  @Input() setId: string;
  @Input() label: string;
  @Input() filters: SimpleFilterSet;
  @Input() buttonImgPath: string;

  constructor(public dialog: MatDialog, private filterService: SimpleFiltersService) {}

  public openFilters() {
    const dialogRef = this.dialog.open(SimpleFiltersDialogComponent, { data: this.filters });

    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe(
        (filters: SimpleFilterSet) => {
          if (filters) this.filterService.setFilters(this.setId, filters);
        },
        (error) => console.error(error),
      );
  }
}
