import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dasher',
})
export class DasherPipe implements PipeTransform {
  transform(value: any) {
    if (value === 0 || value === false || (value && value.toString)) {
      return value.toString();
    }
    return '--';
  }
}
