import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DisplayListRow } from '../../interfaces/display-list-row.interface';
import { RowListInterface } from '../list/row-list.interface';
import { Utils } from '../../utils/utils.class';

@Component({
  selector: 'sellions-layout-renderer-list-displayer',
  templateUrl: './list-displayer.component.html',
})
export class ListDisplayerComponent implements OnInit {
  @Input() baseUrl: string;
  @Input() data: any[];
  @Input() listRow: RowListInterface;
  @Output() elementClicked = new EventEmitter<{
    element: Object;
    target: EventTarget;
  }>();

  ngOnInit() {
    if (this.listRow.rowLayoutType !== 'SimpleTextRow' && !this.listRow.rowLayoutMappings.img && !this.listRow.rowLayoutMappings.file) {
      throw new Error('A rowLayoutMappings does not contain img nor file');
    }

    this.data.map((element) => this.evaluateDisplayStrings(element));
  }

  // FixMe: currently the method adds or overrides properties of data objects
  private evaluateDisplayStrings(object: DisplayListRow): void {
    object.title = Utils.evaluateString(this.listRow.rowLayoutMappings.title, object);
    object.subtitle = Utils.evaluateString(this.listRow.rowLayoutMappings.subtitle, object);
    object.content = Utils.evaluateString(this.listRow.rowLayoutMappings.content, object);
    if (this.listRow.rowLayoutMappings.img) {
      object.img = Utils.evaluateString(this.listRow.rowLayoutMappings.img, object);
    }
  }

  public onClick(element: any, $event: MouseEvent) {
    this.elementClicked.emit({ element, target: $event.target });
    $event.stopPropagation();
  }
}
