import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { RouterModule } from '@angular/router';
import { VisibilityComponent } from './visibility.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { VisibilitySelectComponent } from './visibility-select/visibility-select.component';
import {
  MatAutocompleteModule,
  MatCheckboxModule,
  MatFormFieldModule,
  MatInputModule,
} from '@angular/material';
import { MultipleVisibilityComponent } from './multiple/multiple-visibility.component';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    RouterModule,
    FormsModule,
    SharedModule,
    MatFormFieldModule,
    MatAutocompleteModule,
    MatCheckboxModule,
    MatInputModule,
    ReactiveFormsModule,
  ],
  declarations: [
    VisibilityComponent,
    VisibilitySelectComponent,
    MultipleVisibilityComponent,
  ],
  entryComponents: [
    VisibilitySelectComponent,
    MultipleVisibilityComponent,
    VisibilityComponent,
  ],
  exports: [VisibilityComponent, MultipleVisibilityComponent, VisibilitySelectComponent],
})
export class VisibilityModule {}
