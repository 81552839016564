import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class IdGeneratorService {
  constructor() {}

  public getIdValue(string: string): string {
    const noPolishLettersString = string
      .toLowerCase()
      .replace(/ę/g, 'e')
      .replace(/ó/g, 'o')
      .replace(/ą/g, 'a')
      .replace(/ś/g, 's')
      .replace(/ł/g, 'l')
      .replace(/ż/g, 'z')
      .replace(/ź/g, 'z')
      .replace(/ć/g, 'c')
      .replace(/ń/g, 'n')
      .replace(/[^a-z0-9_-]/g, '');

    if (noPolishLettersString.includes(' ')) {
      const words = noPolishLettersString.split(' ');
      let id = '';
      words.forEach((word, index) => {
        if (index === 0) {
          id += word;
        } else if (index !== 0 && word[0]) {
          id += word[0].toUpperCase() + word.substring(1);
        }
      });
      return id;
    } else {
      return noPolishLettersString;
    }
  }
}
