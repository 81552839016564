import {FormControl, FormGroupDirective, NgForm} from "@angular/forms";
import {ErrorStateMatcher} from "@angular/material/core";

export class CustomErrorStateMatcher implements ErrorStateMatcher {
  formValid: boolean;

  constructor(formValid: boolean) {
    this.formValid = formValid;
  }

  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return (control && control.touched && control.invalid) || (control && control.invalid && !this.formValid);
  }

  setFormValid(formValid) {
    this.formValid = formValid;
  }
}
