import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';

@Injectable()
export class StateStorageService {
  constructor(private storage: Storage) {}

  getPreviousState() {
    return this.storage.get('previousState');
  }

  resetPreviousState() {
    return this.storage.remove('previousState');
  }

  storePreviousState(previousStateName, previousStateParams) {
    return this.storage.set('previousState', {
      name: previousStateName,
      params: previousStateParams,
    });
  }

  getDestinationState() {
    return this.storage.get('destinationState');
  }

  storeDestinationState(destinationState, destinationStateParams, fromState) {
    return this.storage.set('destinationState', {
      destination: {
        name: destinationState.name,
        data: destinationState.data,
      },
      params: destinationStateParams,
      from: {
        name: fromState.name,
      },
    });
  }

  getPreviousLoggedUser() {
    return this.storage.get('loggedUser');
  }

  storePreviousLoggedUser(user: any) {
    return this.storage.set('loggedUser', user);
  }
}
