import { Component, Inject, OnInit } from '@angular/core';
import { DataSourceFieldUtils } from '../controls/data-source/data-source-field-utils';
import { DataSourceOptions } from '../controls/data-source/data-source-options';
import { DictionaryItem } from '../../interfaces/dictionary-item.interface';
import { FormControl } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { Observable } from 'rxjs';
import { PredefinedValue } from '../../interfaces/predefined-value.interface';
import { ReferenceField } from '../../interfaces/reference-field.interface';
import { SmartFilterData } from '../../interfaces/smart-filter-data.interface';

@Component({
  selector: 'forms-smart-component',
  templateUrl: './smart-filter.component.html',
  styleUrls: ['./smart-filter.component.scss'],
})
export class SmartFilterComponent implements OnInit {
  constructor(@Inject(MatDialogRef) private dialogRef: MatDialogRef<SmartFilterComponent>, @Inject(MAT_DIALOG_DATA) public data: SmartFilterData) {}

  public compareFn: ((o1: any, o2: any) => boolean) | null = (
    o1: DictionaryItem | ReferenceField | string,
    o2: DictionaryItem | ReferenceField | string,
  ): boolean => DataSourceFieldUtils.compareValues(o1, o2);

  allValueSelected: boolean = false;

  searchInputControl = new FormControl();
  filteredValues: Observable<any[]>;

  selectedValues: DataSourceOptions = [];

  ngOnInit() {
    this.selectedValues = [...this.data.selectedValues] as DataSourceOptions;
    this.allValueSelected = this.selectedValues.length === this.data.values.length;
    this.initSearchInput();
  }

  private initSearchInput(): void {
    this.filteredValues = this.searchInputControl.valueChanges.pipe(
      startWith<string>(''),
      map((value) => this.filterValues(value)),
    );
  }

  private filterValues(value: string): DataSourceOptions {
    const filterValue = value.toLowerCase();

    switch (this.data.dataSource) {
      case 'dataView':
        return (this.data.values as ReferenceField[]).filter((option) => option.dataViewColumn.toLocaleLowerCase().includes(filterValue));
      case 'dictionary':
        return (this.data.values as DictionaryItem[]).filter((option) => option.name.toLocaleLowerCase().includes(filterValue));
      case 'predefined':
        return (this.data.values as PredefinedValue[]).filter((option) => option.value.toLocaleLowerCase().includes(filterValue));
    }
  }

  onSearchInputClear(): void {
    this.searchInputControl.patchValue('');
  }

  onPickAllButtonChanged(event: MatCheckboxChange): void {
    if (event.checked && !this.areAllValuesSelected(this.selectedValues.length)) {
      this.selectedValues = [...this.data.values] as DataSourceOptions;
    } else {
      this.selectedValues = [];
    }
  }

  onSelectionChanged(event: MatSelectChange): void {
    if (this.areAllValuesSelected(event.value.length)) {
      this.allValueSelected = true;
    } else {
      this.allValueSelected = false;
    }
  }

  private areAllValuesSelected(selectedValuesLength: number): boolean {
    return selectedValuesLength === this.data.values.length;
  }
}
