import { AbstractControl, FormControl, FormGroup, NgForm, NgModel } from '@angular/forms';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { CopyService } from '../../shared/copy.service';
import { DataChangeOutput } from '../../../../sellions_layout_renderer/projects/sellions-layout-renderer-lib/src/lib/components/table/interfaces/data-change-output.interface';
import { DataView } from '../../data-views/data-view';
import { DataViewService } from '../../data-views/data-view.service';
import { DictionaryDefinitionService } from '../../dictionary-definition/dictionary-definition.service';
import { forkJoin } from 'rxjs';
import { FormField } from '../../../../sellions_forms/projects/forms/src/lib/interfaces/form-field.interface';
import { Group } from '../../users/interfaces/group.interface';
import { GroupsService } from '../../users/groups/groups.service';
import { IdGeneratorService } from '../../shared/id-generator.service';
import { mimeTypes } from '../../../../sellions_forms/projects/forms/src/lib/components/controls/multipart-files/mime-types.const';
import { ModalController } from '@ionic/angular';
import { SortService } from '../../shared/sort.service';
import { take } from 'rxjs/operators';
import { ToastService } from '../services/toast.service';
import { typeLabels } from './type';
import { User } from '../../users/interfaces/user.interface';
import { UsersService } from '../../users/users/users.service';
import { Visibility } from '../../visibility/visibility';
import { VisibilityComponent } from '../../visibility/visibility.component';
import {
  emailConditionTypes,
  fileConditionTypes,
  linkConditionTypes,
  numberConditionTypes,
  phoneConditionTypes,
  predefinedFieldTypes,
  textConditionTypes,
  textTypes,
} from '../fieldQuestionTypes';
import { PredefinedValue } from '../../../../sellions_forms/projects/forms/src/lib/interfaces/predefined-value.interface';

@Component({
  selector: 'app-question-component',
  templateUrl: './question.component.html',
  styleUrls: ['./question.component.scss'],
})
export class QuestionComponent implements OnInit {
  @Input() field: FormField;
  public _allowedDependencies: FormField[] = [];
  @Input() set allowedDependencies(value: FormField[]) {
    if (JSON.stringify(value) !== JSON.stringify(this._allowedDependencies)) {
      this.checkConditionsValidity(value);
      this._allowedDependencies = this.copyArray.copy(value);
    }
  }
  @Input() subFormFieldIndex?: number;
  @ViewChild('form') form: NgForm;
  @Output() deleteClick = new EventEmitter<void>();
  @Output() idUpdated = new EventEmitter<void>();

  selectedTabIndex = 0;
  visibilityType: 'public' | 'logged-in' | 'restricted';
  multipartFilesLimitType: 'one' | 'any' | 'specific';
  multipartFilesLimitForm = new FormGroup({ limit: new FormControl() });

  detailsEdited = false;
  typeLabels = typeLabels;

  // General
  textTypes: string[] = textTypes;
  mimeTypes: string[] = Object.keys(mimeTypes);

  // Data
  predefinedTypes = predefinedFieldTypes;
  dataField;
  dictionaryEntries = [];
  dataViewEntries = [];
  chosenDataView: DataView;
  private users: User[] = [];
  private groups: Group[] = [];

  // Validations
  numberConditionTypes = numberConditionTypes;
  textConditionTypes = textConditionTypes;
  fileConditionTypes = fileConditionTypes;
  phoneConditionTypes = phoneConditionTypes;
  emailConditionTypes = emailConditionTypes;
  linkConditionTypes = linkConditionTypes;
  conditionTypesMappings = {
    textarea: this.textConditionTypes,
    number: this.numberConditionTypes,
    multipartFiles: this.fileConditionTypes,
    list: this.numberConditionTypes,
    text: this.textConditionTypes,
    integer: this.numberConditionTypes,
    floating: this.numberConditionTypes,
    currency: this.numberConditionTypes,
    phoneNumber: this.phoneConditionTypes,
    email: this.emailConditionTypes,
    link: this.linkConditionTypes,
  };
  validationsConfig = null;

  // Dependencies
  predefinedDependencyValues = {};

  constructor(
    private copyArray: CopyService,
    private dictionaryService: DictionaryDefinitionService,
    private dataViewService: DataViewService,
    private groupsService: GroupsService,
    private idGenerator: IdGeneratorService,
    private modalController: ModalController,
    private usersService: UsersService,
    private toastService: ToastService,
    private sortServ: SortService,
  ) {}

  ngOnInit() {
    this.getFormValueData();
    this.initField();
  }

  private getFormValueData(): void {
    this.dictionaryService
      .getAll()
      .pipe(take(1))
      .subscribe((entries) => (this.dictionaryEntries = this.sortServ.sortArrayOfObjectsAlphabetically(entries, 'name')));
    this.dataViewService
      .getAllValid()
      .pipe(take(1))
      .subscribe((entries) => {
        const newDataViewEntries = entries.filter((entry) => entry.selected && entry.selected.length);
        this.dataViewEntries = this.sortServ.sortArrayOfObjectsAlphabetically(newDataViewEntries, 'label');
      });
    this.setUpVisibilityData();
  }

  private setUpVisibilityData(): void {
    const users$ = this.usersService.getUsersNoParams().pipe(take(1));
    const groups$ = this.groupsService.getGroupsNoParams().pipe(take(1));
    forkJoin([users$, groups$]).subscribe((data) => {
      this.users = data[0];
      this.groups = data[1];
    });
  }

  private initField(): void {
    this.dataField = this.field.dictionaryId ? 'dictionary' : this.field.dataView ? 'dataView' : null;

    this.initVisibilityIfNotExists();

    this.initValidationIfNotExists();

    this.addDefaultValidations();

    if (!this.field.conditions) {
      this.field.conditions = [];
    }

    if (this.field.validation) {
      this.initConfig();
    }

    if (this.field.type === 'multipartFiles') {
      this.manageMultipartFilesValidation();
      this.manageMultipartFilesVisibility();
    }

    this.multipartFilesLimitForm.controls['limit'].valueChanges.subscribe((value) => this.setMultipartFilesValidation('specific', parseInt(value)));
  }

  manageMultipartFilesValidation() {
    const validation = this.field.validation;
    if (!validation.minLength && !validation.maxLength) {
      this.multipartFilesLimitType = 'one';
      this.setMultipartFilesValidation('one');
    }
    if (validation.minLength === 1 && validation.maxLength === 1) {
      this.multipartFilesLimitType = 'one';
    } else if (validation.minLength === 1 && validation.maxLength === 10) {
      this.multipartFilesLimitType = 'any';
    } else if (validation.minLength === validation.maxLength && validation.maxLength !== 1) {
      this.multipartFilesLimitType = 'specific';
      this.multipartFilesLimitForm.controls['limit'].setValue(validation.maxLength);
      this.multipartFilesLimitForm.updateValueAndValidity();
    }
  }

  manageMultipartFilesVisibility() {
    const visibility = this.field.visibility;
    if (visibility.opened) {
      this.visibilityType = 'logged-in';
    } else {
      this.visibilityType = 'restricted';
    }
  }

  private initVisibilityIfNotExists(): void {
    if (!this.field.visibility) {
      const visibility = this.visibilityType ? this.visibilityType : 'logged-in';
      this.changeVisibilityType(visibility);
    } else {
      this.visibilityType;
    }
  }

  private initValidationIfNotExists(): void {
    if (!this.field.validation && this.conditionsExist()) {
      this.field.validation = { enabled: true, regExp: '' };
    }
  }

  private addDefaultValidations(): void {
    if (!this.field.validation || this.field.validation['regExp']) {
      return;
    }

    const type = this.field.type;

    switch (type) {
      case 'phoneNumber':
        this.field.validation['regExp'] = '^\\d{9}$';
        break;
      case 'email':
        this.field.validation['regExp'] = '^[^@\\s]+@[^@\\s]+\\.[^@\\s]+$';
        break;
      case 'nip':
        this.field.validation['regExp'] = '^\\d{10}$';
        break;
      case 'link':
        this.field.validation['regExp'] = '^(ftp|http|https)://[a-zA-Z0-9_-]+(\\.[a-zA-Z0-9_-]+)+$';
        break;
    }
  }

  private initConfig(): void {
    this.validationsConfig = {
      dataSourceConfig: {
        operationType: 'INPUT',
        data: Object.keys(this.field.validation)
          .filter((k) => k !== 'enabled')
          .map((k) => {
            return { type: k, value: this.field.validation[k], message: '' };
          }),
      },
      displayConfig: {
        rowLayoutType: 'SimpleTextRowSmallImg',
        pagination: false,
        columns: [
          {
            label: 'Typ',
            field: 'type',
            values: this.getConditionsTypes().map((ct) => {
              return { label: ct, value: ct };
            }),
            type: 'dropdown',
          },
          {
            label: 'Wartość',
            field: 'value',
            value: '${element.value}',
            type: 'string',
          },
          {
            label: 'Komunikat',
            field: 'message',
            value: '${element.message}',
            type: 'string',
          },
          {
            label: 'Akcje',
            value: {
              Usuń: '(row, rowIndex) => this.removeWithPopup(rowIndex)',
            },
            type: 'action',
          },
        ],
      },
      screenActions: {
        processList: [],
      },
      buttonLabel: '+ Dodaj wiersz',
    };
  }

  public conditionsExist(): boolean {
    return Object.keys(this.conditionTypesMappings).includes(this.field.type);
  }

  public isPredefinedType(): boolean {
    return this.predefinedTypes.includes(this.field.type);
  }

  public isTextType(): boolean {
    return this.textTypes.includes(this.field.type);
  }

  public getConditionsTypes(): string[] {
    const conditionTypesMapping = this.conditionTypesMappings[this.field.type];

    if (!conditionTypesMapping) {
      return [];
    }

    return conditionTypesMapping;
  }

  public setValidation(validations: DataChangeOutput): void {
    const validationResult = { enabled: true, regExp: '' };

    validations.fields.forEach((v) => {
      if (v['type'] && v['value']) {
        validationResult[v['type']] = v['value'];
      }
    });

    this.field.validation = validationResult;
  }

  public addDependency(): void {
    if (!this._allowedDependencies.length) {
      this.showEmptyDependenciesNotification();
    } else {
      this.field.conditions.push({ questionId: '', operator: '', value: '' });
    }
  }

  public addValue(): void {
    const value: PredefinedValue = {
        id: this.getUniqueValueId(this.field.values.length + 1),
        value: '',
      };
    (this.field.values as PredefinedValue[]).push(value);
  }

  private getUniqueValueId(id: number): number {
    if ((this.field.values as PredefinedValue[]).some((element): boolean => element.id === id)) {
      return this.getUniqueValueId(id + 1);
    } else {
      return id;
    }
  }

  public deleteDependency(index: number, dependencyId: string): void {
    this.field.conditions.splice(index, 1);
    delete this.predefinedDependencyValues[dependencyId];
  }

  public deleteValue(index: number): void {
    this.field.values.splice(index, 1);
  }

  public delete(): void {
    this.deleteClick.emit();
  }

  public isDependencyPredefined(questionId, dependencies): boolean {
    if (!questionId) {
      return false;
    }

    const dependency = dependencies.find((ad) => ad.id === questionId);
    return dependency && this.predefinedTypes.includes(dependency.type);
  }

  public prepareDataViewData(): void {
    this.chosenDataView = this.dataViewEntries.find((entry) => entry.name === this.field.dataView);
  }

  public resetDataTab(dataSource?: string): void {
    this.field.dataView = null;
    this.field.dataViewColumn = null;
    this.field.dictionaryId = null;
    this.chosenDataView = null;
    this.field.values = [];
    if (dataSource === 'predefined') {
      this.addValue();
    }
  }

  public shouldDisplayError(model: NgModel): boolean {
    return model ? model.invalid && (model.touched || model.dirty) : false;
  }

  private isFieldDataValid(): boolean {
    return !!(this.field.dictionaryId || (this.field.dataView && this.field.dataViewColumn) || this.areManuallyAddedValuesValid());
  }

  private areManuallyAddedValuesValid(): boolean {
    return this.field.values && this.field.values.length && this.areValuesValid();
  }

  private areValuesValid(): boolean {
    let result = true;
    (this.field.values as PredefinedValue[]).forEach((value) => {
      if (!value.id || !value.value) {
        result = false;
      }
    });
    return result;
  }

  public showErrors(): void {
    if (this.isPredefinedType() && !this.isFieldDataValid()) {
      this.selectedTabIndex = 1;
      this.detailsEdited = true;
    }
    setTimeout(() => this.markAllFieldsAsTouched(), 500);
  }

  private markAllFieldsAsTouched(): void {
    Object.keys(this.form.control.controls).forEach((key) => {
      this.form.control.get(key).markAsTouched();
    });
  }

  public onLabelChange($event): void {
    this.field.id = this.idGenerator.getIdValue($event.detail.value);
    this.idUpdated.emit();
  }

  public setIdError(): void {
    if (!this.hasIdDuplicatedError()) {
      this.form.control.get('id').setErrors({ duplicated: true });
    }
  }

  public deleteIdDuplicatedError(): void {
    if (this.hasIdDuplicatedError()) {
      delete this.form.control.get('id').errors['duplicated'];
      this.form.control.get('id').updateValueAndValidity();
    }
  }

  private hasIdDuplicatedError(): boolean {
    return this.form.control.get('id').hasError('duplicated');
  }

  public onIdChange(): void {
    this.idUpdated.emit();
  }

  private checkConditionsValidity(newAllowedDependenciesValue: FormField[]): void {
    if (this.field.conditions && this.field.conditions.length) {
      this.field.conditions.forEach((condition, index) => {
        if (condition.questionId) {
          const newDependency = newAllowedDependenciesValue.find((dep) => dep.id === condition.questionId);
          const oldDependency = this._allowedDependencies.find((dep) => dep.id === condition.questionId);
          if (!newDependency) {
            this.resetConditionsProperty(index, 'questionId');
            if (this.predefinedTypes.includes(oldDependency.type)) {
              this.resetConditionsProperty(index, 'value');
              delete this.predefinedDependencyValues[condition.questionId];
            }
            setTimeout(() => {
              this.getFormControl('stringValue' + index).markAsTouched();
              this.getFormControl('operator' + index).markAsTouched();
            }, 500);
          } else if (
            oldDependency &&
            ((oldDependency.dataSource === 'dataView' && oldDependency.dataView !== newDependency.dataView) ||
              (oldDependency.dataSource === 'dataView' && oldDependency.dataViewColumn !== newDependency.dataViewColumn) ||
              (oldDependency.dataSource === 'dictionary' && oldDependency.dictionaryId !== newDependency.dictionaryId) ||
              (oldDependency.dataSource === 'predefined' && !(newDependency.values as PredefinedValue[]).find((val) => val.value === condition.value)))
          ) {
            this.setPredefinedDependencyValues(condition.questionId, newAllowedDependenciesValue);
            this.resetConditionsProperty(index, 'value');
            this.getFormControl('value' + index).markAsTouched();
            this.getFormControl('operator' + index).markAsTouched();
          }
        }
      });
    }
  }

  private getFormControl(name: string): AbstractControl | null {
    return this.form.control.get(name);
  }

  private showEmptyDependenciesNotification(): void {
    this.toastService.showNotification('Brak pytań do których można się odwołać: uzupełnij id oraz dane');
  }

  public onDependencyIdClick(): void {
    if (!this._allowedDependencies.length) {
      this.showEmptyDependenciesNotification();
    }
  }

  public onDependencyIdChange(index: number, dependencyId: string, dependencies: FormField[]): void {
    if (this.isDependencyPredefined(dependencyId, dependencies)) {
      this.resetConditionsProperty(index, 'value');
    }
    this.setPredefinedDependencyValues(dependencyId, dependencies);
  }

  private resetConditionsProperty(index, name: string): void {
    this.field.conditions[index][name] = '';
  }

  private setPredefinedDependencyValues(dependencyId: string, dependencies: FormField[]): void {
    if (dependencyId && this.isDependencyPredefined(dependencyId, dependencies)) {
      const dependency = dependencies.find((ad) => ad.id === dependencyId);
      if (dependency.dataSource === 'dictionary') {
        this.dictionaryService
          .getDictionaryEntries(dependency.dictionaryId)
          .pipe(take(1))
          .subscribe((dependencyEntry) => (this.predefinedDependencyValues[dependencyId] = dependencyEntry['items'].map((i) => i.name)));
      } else if (dependency.dataSource === 'predefined') {
        this.predefinedDependencyValues[dependencyId] = (dependency.values as PredefinedValue[]).map((value) => value.value);
      } else if (dependency.dataSource === 'dataView') {
        this.dataViewService
          .getData(dependency.dataView)
          .pipe(take(1))
          .subscribe((result) => (this.predefinedDependencyValues[dependencyId] = result.map((i) => i[dependency.dataViewColumn])));
      }
    }
  }

  public changeVisibilityType(type: 'public' | 'logged-in' | 'restricted'): void {
    switch (type) {
      //FIXME public functionality to be implemented in the future
      case 'public':
        this.field.visibility = { groups: [], people: [], opened: true };
        break;
      case 'logged-in':
        this.field.visibility = { groups: [], people: [], opened: true };
        break;
      case 'restricted':
        this.field.visibility = { groups: [], people: [], opened: false };
        break;
    }
  }

  public setVisibility(visibilityRef: Visibility): void {
    this.modalController
      .create({
        component: VisibilityComponent,
        componentProps: {
          visibility: visibilityRef,
          shouldDisplayVisibilityOpenButton: false,
        },
      })
      .then((alert) => alert.present());
  }

  public setMultipartFilesValidation(validationType: 'one' | 'any' | 'specific', limit?: number): void {
    switch (validationType) {
      case 'one':
        this.field.validation['maxLength'] = 1;
        this.field.validation['minLength'] = 1;
        break;
      case 'any':
        this.field.validation['maxLength'] = 10;
        this.field.validation['minLength'] = 1;
        break;
      case 'specific':
        this.field.validation['maxLength'] = limit || 1;
        this.field.validation['minLength'] = limit || 1;
        break;
    }
  }

  public getGroupById(id: number): Group {
    return this.groups.find((group: Group) => group.id === id);
  }

  public getPersonById(id: number): User {
    return this.users.find((user: User) => user.id === id);
  }

  public setFieldDisabled(disabled: boolean): void {
    this.field.disabled = disabled;
  }

  public getDependencyValuePlacholder(questionId: string): string {
    if (questionId) {
      const dependency = this._allowedDependencies.find((dependencyInstace) => dependencyInstace.id === questionId);
      return dependency.type === 'datepicker' ? 'yyyy-mm-dd' : '';
    } else {
      return '';
    }
  }
}
