import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { AuthenticationBaseService } from '../../authentication-base.service';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { Condition } from '../../interfaces/condition.interface';
import { ConditionsConjuntion } from '../../enums/conditions-conjuntion.enum';
import { CustomErrorStateMatcher } from '../../interfaces/custom-error-state-matcher';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import { DataSourceOptionsService } from '../../services/data-source-options/data-source-options.service';
import { FormField } from '../../interfaces/form-field.interface';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { Operator } from '../../enums/operator.enum';
import { ReferenceField } from '../../interfaces/reference-field.interface';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Utils } from '../../utils/utils.class';

@Component({
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS] },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
  ],
  selector: 'sellions-forms-renderer',
  templateUrl: './renderer.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class SellionsFormsRendererComponent implements OnInit, OnDestroy {
  @Input() display: 'block' | 'inline' | 'inline-block' | 'flex' | 'none' = 'block';

  @Input() set dataViewBaseAPI(value: string) {
    if (value[value.length - 1] === '/') {
      this._dataViewBaseAPI = value.slice(0, -1);
    } else {
      this._dataViewBaseAPI = value;
    }
  }

  @Input() set source(value: FormField[]) {
    if (value && (!this.valueInput || JSON.stringify(value) !== JSON.stringify(this.valueInput))) {
      this.form = this.formBuilder.group({});
      this.valueInput = Utils.copyArray(value);
      this.fields = Utils.copyArray(value);
      this.populateForm(this.fields);

      this.manageFields();
    }
  }

  @Input() formData: { [key: string]: any };
  @Input() set authService(value: AuthenticationBaseService) {
    this._authService = value;
    this.dictionaryService.downloadFieldsOptions(this.fields, this._authService, this._dataViewBaseAPI);
    this.manageFields();
  }

  @Input() answers: any;
  @Input() disabled: boolean;
  @Input() floatLabel: 'auto' | 'always' = 'auto';
  @Input() displayListButtonIcons = false;
  @Output() formChange: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();
  @Output() fieldsManagementFinished: EventEmitter<true> = new EventEmitter<true>();
  @Output() referencesChange: EventEmitter<{
    [controlId: string]: ReferenceField[];
  }> = new EventEmitter<{ [controlId: string]: ReferenceField[] }>();

  public form: FormGroup;
  public fields: FormField[];
  public formValid = true;
  public matcher = new CustomErrorStateMatcher(true);
  public references: { [controlId: string]: ReferenceField[] } = {};
  private unsubscribe$ = new Subject<void>();
  private _dataViewBaseAPI = '';
  private _authService: AuthenticationBaseService;
  private valueInput: FormField[];
  private _fieldsManagementFinished: boolean = true;

  constructor(private dictionaryService: DataSourceOptionsService, private formBuilder: FormBuilder) {
    this.createForm();
  }

  ngOnInit() {
    if (this.formData) this.patchForm(this.formData);
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private patchForm(data: { [key: string]: any }) {
    this.fields.forEach((field) => {
      if (field.type === 'list' && !field.multiForm) {
        (this.form.get(field.id) as FormArray).at(0).patchValue(data[field.id]);
      } else {
        this.form.get(field.id).patchValue(data[field.id]);
      }
    });
  }

  private createForm() {
    this.form = this.formBuilder.group({});
  }

  private populateForm(fields: FormField[]) {
    if (this._authService && this._dataViewBaseAPI) {
      this.dictionaryService.downloadFieldsOptions(fields, this._authService, this._dataViewBaseAPI);
    }
    fields.forEach((control) => {
      if (this.areConditionsMet(control.conditions, control.conditionsConjuction)) {
        this.addControl(control);
      }
    });
    if (this.answers) {
      this.fillFormAnswers();
    }
    this.formChange.emit(this.form);

    this.form.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      if (this._fieldsManagementFinished) {
        this.manageFields();
        this.formChange.emit(this.form);
      }
    });
  }

  addNewList(name: string) {
    const list = this.fields.find((control) => control.id === name);

    let formGroup = this.formBuilder.group([]);
    list.subForm.definition.forEach((subForm) => {
      formGroup.addControl(
        subForm.id,
        this.formBuilder.control(
          {
            value: null,
            disabled: this.disabled,
          },
          this.generateValidators(subForm),
        ),
      );
      this.references[subForm.id + ((this.form.controls[name] as FormArray).length - 1)] = this.references[subForm.id];
    });
    (this.form.controls[name] as FormArray).push(formGroup);
  }

  removeList(control, index) {
    (this.form.controls[control.id] as FormArray).removeAt(index);
  }

  private addControl(control): void {
    const formControl = this.createFormControl(control);
    if (control.type === 'list') {
      const formArray = this.formBuilder.array([]);

      if (Array.isArray(control.predefinedValue)) {
        for (let i = 0; i < control.predefinedValue.length; ++i) {
          const subForm = this.createSubForm(control, i);
          formArray.push(subForm);
        }
      } else {
        const subForm = this.createSubForm(control);
        formArray.push(subForm);
      }

      this.form.addControl(control.id, formArray);
    } else if (control.type === 'bool') {
      formControl.setValue(false);
      this.form.addControl(control.id, formControl);
    } else if (control.type === 'checkbox') {
      if (!control.predefinedValue) {
        formControl.setValue([]);
      }
      this.form.addControl(control.id, formControl);
    } else {
      this.form.addControl(control.id, formControl);
    }
  }

  private createSubForm(control: FormField, index: number = 0): FormGroup {
    const subForm = this.formBuilder.group([]);
    for (const def of control.subForm.definition) {
      this.addFormControlToSubForm(def, control, subForm);
    }

    return subForm;
  }

  private addFormControlToSubForm(def: FormField, control: FormField, formGroup: FormGroup): void {
    const defControl = this.createFormControl(def);
    formGroup.addControl(def.id, defControl);
  }

  private createFormControl(control: FormField): FormControl {
    return this.formBuilder.control(
      {
        value: null,
        disabled: control.disabled,
      },
      this.generateValidators(control),
    );
  }

  private generateValidators(control: FormField): ValidatorFn[] {
    const validators: ValidatorFn[] = [];

    if (!control) {
      console.warn('A falsy value of FormField has been passed');
      return validators;
    }

    if ((this.areConditionsMet(control.conditions, control.conditionsConjuction) && control.requiredIfShown) || control.required) {
      validators.push(Validators.required);
    }

    if (this.controlValidationEnabled(control)) {
      validators.push(Validators.pattern(control.validation.regExp));
    }

    if (this.controlValidationEnabled(control) && this.isNumberValueDefined(control.validation.minLength)) {
      validators.push(Validators.minLength(control.validation.minLength));
    }

    if (this.controlValidationEnabled(control) && this.isNumberValueDefined(control.validation.maxLength)) {
      validators.push(Validators.maxLength(control.validation.maxLength));
    }

    if (this.controlValidationEnabled(control) && this.isNumberValueDefined(control.validation.min)) {
      validators.push(Validators.min(control.validation.min));
    }

    if (control.validation && control.validation && this.isNumberValueDefined(control.validation.max)) {
      validators.push(Validators.max(control.validation.max));
    }
    return validators;
  }

  private controlValidationEnabled(formField: FormField): boolean {
    return formField.validation && formField.validation.enabled
  }

  private isNumberValueDefined(numValue: number): boolean {
    return numValue!= null && !isNaN(numValue)
  }

  public getFormControl(control, index, def) {
    return ((this.form.controls[control.id] as FormArray).at(index) as FormGroup).controls[def.id];
  }

  public getControlFormArray(control) {
    if ((this.form.controls[control] as FormArray) !== undefined) {
      return (this.form.controls[control] as FormArray).controls;
    }
    return new Array(1);
  }

  public areConditionsMet(conditions: Condition[], conjunction: string): boolean {
    if (conditions && conditions.length > 0) {
      let allConditions: boolean;
      conditions.forEach((condition, index) => {
        if (index === 0) {
          allConditions = this.generateCondition(condition);
        } else if (index > 0 && conjunction === ConditionsConjuntion.AND) {
          allConditions = allConditions && this.generateCondition(condition);
        } else if (index > 0 && conjunction === ConditionsConjuntion.OR) {
          allConditions = allConditions || this.generateCondition(condition);
        }
      });
      return allConditions;
    } else {
      return true;
    }
  }

  private generateCondition(condition: Condition): boolean {
    if (condition) {
      const ctrl = this.form.controls[condition.questionId];
      const nestedParam = condition.nestedParam;
      const ctrlValue = nestedParam ? ctrl && ctrl.value[nestedParam] : ctrl && ctrl.value;
      const ctrlType = this.fields.find((field) => field.id === condition.questionId).type;
      if (condition.operator === Operator.EQUALS) {
        if (ctrlType === 'checkbox' && ctrlValue) {
          return ctrlValue.includes(condition.value);
        }
        return ctrlValue === condition.value;
      } else if (condition.operator === Operator.UNEQUALS) {
        if (ctrlType === 'checkbox' && ctrlValue) {
          return !ctrlValue.includes(condition.value);
        }
        return ctrlValue !== condition.value;
      }
    } else {
      return true;
    }
  }

  public isFormValid(): boolean {
    this.markAsTouched(this.form);
    this.form.updateValueAndValidity();
    this.formValid = this.form.valid;
    this.matcher.setFormValid(this.formValid);
    return this.formValid;
  }

  // Workaround: somehow the markAsTouched method does not affect inner controls...
  private markAsTouched(providedFormCollection: FormGroup | FormArray): void {
    providedFormCollection.markAsTouched();
    for (const controlId in providedFormCollection.controls) {
      const control: AbstractControl = providedFormCollection.controls[controlId];
      if (control instanceof FormGroup || control instanceof FormArray) {
        this.markAsTouched(control);
      }
      if (control.enabled) {
        control.markAsTouched();
      }
    }
  }

  isFieldInvalid(id: string): boolean {
    return (
      (this.form.controls[id] && this.form.controls[id].touched && this.form.controls[id].invalid) ||
      (!this.formValid && this.form.controls[id] && this.form.controls[id].invalid)
    );
  }

  public isValidInnerField(controlId: string, innerControlId: string): boolean {
    if (!this.form.controls[controlId]) {
      return false;
    }
    const innerForm = this.form.controls[controlId] as FormGroup;
    return (
      (innerForm[innerControlId] && innerForm[innerControlId].touched && innerForm[innerControlId].invalid) ||
      (!this.formValid && innerForm[innerControlId].invalid)
    );
  }

  private manageFields(): void {
    if (!this.fields) {
      return;
    }

    this._fieldsManagementFinished = false;

    this.fields.forEach((control) => {
      if (control.type === 'list') {
        const listOfGroup = (this.form.controls[control.id] as FormArray).controls;
        const definitions = control.subForm.definition;
        for (let i = 0; i < listOfGroup.length; ++i) {
          const formGroup = (listOfGroup[i] as FormGroup).controls;
          definitions.forEach((def) => {
            this.manageSingleField(def, formGroup, i, control);
          });
        }
      } else {
        this.manageSingleField(control, this.form.controls);
      }
    });

    this._fieldsManagementFinished = true;
    this.fieldsManagementFinished.emit(true);
  }

  private manageSingleField(control: FormField, formControls: { [key: string]: AbstractControl }, index: number = 0, subForm: FormField = null) {
    if (this.areConditionsMet(control.conditions, control.conditionsConjuction) && !formControls[control.id]) {
      this.addControl(control);
    }
    if (!this.areConditionsMet(control.conditions, control.conditionsConjuction) && formControls[control.id]) {
      this.form.removeControl(control.id);
    }

    //TODO do zaorania po zakończeniu prac nad LO, mergu zmian z sellions/development i ew. migracji starych formsów
    if (formControls[control.id] && !formControls[control.id].value) {
      this.fillFormInputWithPredefinedValue(control, formControls, index, subForm);
    }
  }

  private fillFormInputWithPredefinedValue(
    control: FormField,
    formControls: { [key: string]: AbstractControl },
    index: number = 0,
    subForm: FormField = null,
  ): void {
    if (control.predefinedValue && !formControls[control.id].touched) {
      formControls[control.id].patchValue(this.getPredefinedValue(control));
    }
    if (subForm && Array.isArray(subForm.predefinedValue) && subForm.predefinedValue.length > index) {
      formControls[control.id].patchValue(this.getPredefinedListValue(control, subForm.predefinedValue[index][control.id]));
    }
  }

  private getPredefinedValue(control: FormField): any {
    if (control.type === 'reference') {
      return Utils.evaluateDisplayStrings(control.predefinedValue, control);
    }
    if (control.dataSource === 'dataView') {
      return this.getPredifinedDataViewValue(control.id, control.predefinedValue);
    }
    return control.predefinedValue;
  }

  private getPredefinedListValue(control: FormField, predefinedValue: any): any {
    if (control.type === 'reference') {
      return Utils.evaluateDisplayStrings(predefinedValue, control);
    }
    if (control.dataSource === 'dataView') {
      return this.getPredifinedDataViewValue(control.id, predefinedValue);
    }
    return predefinedValue;
  }

  private getPredifinedDataViewValue(controlId: string, predefinedValueId: string): ReferenceField | string {
    if (this.references[controlId] && this.references[controlId].length > 0) {
      return this.references[controlId].find((el) => el.id === predefinedValueId);
    }
    return predefinedValueId;
  }

  private fillFormAnswers(): void {
    Object.keys(this.answers).forEach((key) => {
      const control = this.form.controls[key];
      if (control) {
        const answer = this.answers[key];
        if (typeof answer === 'object' && answer.length > 1) {
          for (let i = 0; i < answer.length - 1; i++) {
            this.addNewList(key);
          }
        }
        control.patchValue(this.answers[key]);
      }
    });
  }
}
