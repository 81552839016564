import { ErrorHandler, Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { AlertController } from '@ionic/angular';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private alertController: AlertController) {}

  handleError(error) {
    let message = error.toString();
    if (error instanceof HttpErrorResponse) {
      message = error.message;
    }
    this.alertController
      .create({
        header: 'Error',
        message: message,
      })
      .then((alert) => alert.present());
    console.error(error);
  }
}
