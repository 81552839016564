import { ContentPage } from '../../../page.interface';
import { DataSourceParams } from '../interfaces/data-source-params.interface';
import { Filter } from '../interfaces/filter.interface';
import { finalize, take } from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';
import { TableDataSource } from './table-data-source.class';

export class OnlineDataSource extends TableDataSource {
  public loadData(params: DataSourceParams) {
    this.checkParams(params);

    this.loadingSubject.next(true);

    let httpParams = new HttpParams().append('page', params.page && params.page.toString()).append('size', params.size && params.size.toString());
    const filters: Filter[] = params.filters;
    if (filters && filters.length) {
      filters
        .filter((filter) => filter.value !== null && filter.value !== '')
        .forEach((filter) => {
          httpParams = httpParams.append(filter.key, filter.value);
        });
    }

    if (params.sortProperty) {
      const sortOrder = params.sortDirection || 'ASC';
      httpParams = httpParams.append('sort', `${params.sortProperty},${sortOrder}`);
    }

    this.http
      .get<ContentPage | any[]>(params.url, {
        headers: params.token ? { Authorization: 'Bearer ' + (params.token.access_token ? params.token.access_token: params.token) } : {},
        params: httpParams,
      })
      .pipe(
        take(1),
        finalize(() => {
          this.loadingSubject.next(false);
          this.dataInitializedSubject.next(true);
        }),
      )
      .subscribe(
        (elements: ContentPage | any[]) => {
          if (Array.isArray(elements)) this.dataSubject.next(elements);
          else {
            this.dataSubject.next(elements.content);
            this.totalNumberOfElementsSubject.next(elements.totalElements);
          }
        },
        (error) => {
          this.dataSubject.next([]);
          this.totalNumberOfElementsSubject.next(0);

          throw { message: 'Error fetching online data.', error };
        },
      );
  }

  private checkParams(params: DataSourceParams) {
    if (params.url == undefined) throw `No url provided in params!`;
  }
}
