import { AuthenticationBaseService } from '../../authentication-base.service';
import { BehaviorSubject } from 'rxjs';
import { ConfigServiceProvider } from '../config/config.service';
import { ContentPage } from '../../interfaces/content-page.interface';
import { FormField } from '../../interfaces/form-field.interface';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { References } from '../../interfaces/references.interface';
import { take } from 'rxjs/operators';
import { Utils } from '../../utils/utils.class';
import { ReferenceField } from '../../interfaces/reference-field.interface';

@Injectable()
export class ReferencesService {
  public references$ = new BehaviorSubject<References>({});

  constructor(private confService: ConfigServiceProvider, private http: HttpClient) {}

  public getReferenceValue(field: FormField, authService: AuthenticationBaseService, dataViewBaseAPI: string) {
    if (!authService || !dataViewBaseAPI) {
      return;
    }

    authService.getAccessToken().then((token) => {
      const accessToken = typeof token === 'object' ? token.access_token : token;
      this.http
        .get<ContentPage>(this.confService.getUrlWithParameter(dataViewBaseAPI, 'dataView', field.dataView, field.sort), {
          headers: { Authorization: `Bearer ${accessToken}` },
        })
        .pipe(take(1))
        .subscribe((data) => {
          this.emitReferences(
            field.id,
            data.content.map((elem) => Utils.evaluateReferenceDisplayStrings(elem, field)),
          );
        });
    });
  }

  private emitReferences(id: string, references: ReferenceField[]) {
    const referenceMap = this.references$.getValue();
    referenceMap[id] = references;
    this.references$.next(referenceMap);
  }
}
