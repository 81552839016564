import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthenticationBaseService } from '../projects/forms/src/public-api';

@Injectable()
export class AuthenticationService implements AuthenticationBaseService {
  constructor(private http: HttpClient) { }

  public login(user: { login: string; password: string; }) {
    const urlEncodedBody = new URLSearchParams();
    urlEncodedBody.append('client_secret', '4ee1dea5-1e8d-4270-922b-6fd4bae21064');
    urlEncodedBody.append('client_id', 'sellions-app');
    urlEncodedBody.append('grant_type', 'password');
    urlEncodedBody.append('username', user.login);
    urlEncodedBody.append('password', user.password);

    return this.http.post<any>(
      'https://kc.test.sellions.com/auth/realms/LO/protocol/openid-connect/token',
      urlEncodedBody.toString(), {
      headers: {
        'Content-type': 'application/x-www-form-urlencoded; charset=utf-8'
      }
    }).subscribe(
      token => {
        console.log(token);
        sessionStorage.setItem('token', token.access_token);
      },
      error => console.error(error)
    );
  };

  getAccessToken(): Promise<any> {
    return new Promise(resolve => {
      resolve(sessionStorage.getItem('token'));
    });
  }
}