import {Component} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {User} from "../../users/interfaces/user.interface";
import {Group} from "../../users/interfaces/group.interface";
import {VisibilitySelectComponent} from "../../visibility/visibility-select/visibility-select.component";
import {AlertController, ModalController} from "@ionic/angular";
import {take} from "rxjs/operators";
import {forkJoin, Observable} from "rxjs";
import {UsersService} from "../../users/users/users.service";
import {GroupsService} from "../../users/groups/groups.service";
import {SimpleVisibility, Visibility} from "../../visibility/visibility";
import {ActivatedRoute, Router} from "@angular/router";
import {ToastService} from "../../forms/services/toast.service";
import {AnalyticalSpace} from "../analytical-space";
import {AnalyticalSpaceService} from "../analytical-space.service";
import {ErrorTranslationService} from "../../config/error-translation.service";
import {TranslateService} from "@ngx-translate/core";

@Component({
    selector: 'analytical-space-create-and-edit',
    templateUrl: './analytical-space-create-and-edit.component.html',
    styleUrls: ['./analytical-space-create-and-edit.component.scss'],
})
export class AnalyticalSpaceCreateAndEditComponent {
    form: FormGroup;
    users: User[] = [];
    groups: Group[] = [];
    selectedUsers: User[] = [];
    selectedGroups: Group[] = [];
    visibility: SimpleVisibility;
    id: number;

    constructor(private modalController: ModalController,
                private usersService: UsersService,
                private groupsService: GroupsService,
                private alertController: AlertController,
                private router: Router,
                private route: ActivatedRoute,
                private toastService: ToastService,
                private analyticalSpaceService: AnalyticalSpaceService,
                private errorTranslationService: ErrorTranslationService) {
    }

    ngOnInit() {
        this.id = this.route.snapshot.paramMap['params'].id;

        this.form = new FormGroup({
            name: new FormControl({value: '', disabled: this.id}, Validators.required),
            description: new FormControl('', Validators.required),
        });

        if (this.id) {
            this.analyticalSpaceService.get(this.id)
                .pipe(take(1))
                .subscribe(res => {
                    this.form.get('name').setValue(res.name)
                    this.form.get('description').setValue(res.description)
                    this.visibility = res.visibility
                    this.setUpVisibilityData()
                })
        } else {
            this.setUpVisibilityData()
        }
    }

    public async openVisibilitySelect(type: string) {
        const modal = await this.modalController.create({
            component: VisibilitySelectComponent,
            componentProps: {
                type: type,
                entriesInput: type === 'groups' ? this.selectedGroups : this.selectedUsers,
                entries: type === 'groups' ? this.groups : this.users,
            },
        });
        modal.onDidDismiss().then((data) => {
            if (data && data.data) {
                const selected = data.data.selected;
                if (type === 'users') {
                    this.selectedUsers = selected;
                } else if (type === 'groups') {
                    this.selectedGroups = selected;
                }
            }
        });
        return await modal.present();
    }

    public deleteUser(entry) {
        const i = this.selectedUsers.findIndex(
            (selectedEntry) => selectedEntry.id === entry.id,
        );
        this.selectedUsers.splice(i, 1);
    }

    public deleteGroup(entry) {
        const i = this.selectedGroups.findIndex(
            (selectedEntry) => selectedEntry.id === entry.id,
        );
        this.selectedGroups.splice(i, 1);
    }

    private setUpVisibilityData() {
        const users$ = this.usersService.getUsersNoParams().pipe(take(1));
        const groups$ = this.groupsService.getGroupsNoParams().pipe(take(1));
        forkJoin([users$, groups$]).subscribe((data) => {
            this.users = data[0];
            this.groups = data[1];
            this.setUpSelectedEntries();
        });
    }

    private setUpSelectedEntries() {
        if (this.visibility) {
            const people = this.visibility.people;
            const groups = this.visibility.groups;
            if (people && people.length) {
                this.selectedUsers = people.map((element) =>
                    this.users.find((user) => user.id === element),
                );
            }
            if (groups && groups.length) {
                this.selectedGroups = groups.map((element) =>
                    this.groups.find((group) => group.id === element),
                );
            }
        }
    }

    shouldDisplayError(controlName): boolean {
        const control = this.form.get(controlName);
        return control.invalid && (control.touched || control.dirty);
    }

    save() {
        if (this.form.invalid) {
            Object.keys(this.form.controls).forEach((key) =>
                this.form.get(key).markAsTouched(),
            );
            this.toastService.showNotification('Uzupełnij wszystkie pola');
            return;
        }

        this.alertController
            .create({
                header: 'Zapisanie wprowadzonych zmian',
                message: `Czy chcesz zapisać wprowadzone zmiany?`,
                buttons: [
                    {
                        text: 'Anuluj',
                        role: 'cancel',
                        cssClass: 'secondary',
                    },
                    {
                        text: 'Tak',
                        handler: () => this.saveAnalyticalSpace(),
                    },
                ],
            })
            .then((alert) => alert.present());
    }

    saveAnalyticalSpace() {
        const visibility = {people: [], groups: []};
        visibility.people = this.selectedUsers.map((user) => user.id);
        visibility.groups = this.selectedGroups.map((group) => group.id);
        const analyticalSpace: AnalyticalSpace = {
            name: this.getValue('name'),
            description: this.getValue('description'),
            visibility: visibility,
        };

        if (this.id) {
            this.analyticalSpaceService
                .update(this.id, analyticalSpace)
                .pipe(take(1))
                .subscribe(
                    () => this.navigateBackToList(),
                    (error) => this.getAndShowErrorMessage(error),
                );
        } else {
            this.analyticalSpaceService
                .create(analyticalSpace)
                .pipe(take(1))
                .subscribe(
                    () => this.navigateBackToList(),
                    (error) => this.getAndShowErrorMessage(error),
                );
        }
    }

    private getAndShowErrorMessage(error): Promise<any> {
        return this.errorTranslationService.getErrorMessage(error)
            .then(e => {
                const message = e ? e : 'Wystąpił błąd wewnętrzny'; //TODO jakiś lepszy failover jak brak translacji
                return this.toastService.showNotification(message)
            });
    }

    getValue(controlName) {
        return this.form.get(controlName).value;
    }

    cancel() {
        const activityType = this.id ? 'edycji' : 'tworzenia';
        this.alertController
            .create({
                header: 'Anulowanie ' + activityType + ' przestrzeni analitycznej',
                message:
                    'Czy chcesz zrezygnować z ' +
                    activityType +
                    ' przestrzeni analitycznej? Wprowadzone zmiany zostaną utracone.',
                buttons: [
                    {
                        text: 'Anuluj',
                        role: 'cancel',
                        cssClass: 'secondary',
                    },
                    {
                        text: 'Tak',
                        handler: () => this.navigateBackToList(),
                    },
                ],
            })
            .then((alert) => alert.present());
    }

    private navigateBackToList() {
        if (this.id) {
            this.router.navigate(['analytical-spaces'], {queryParams: {refresh: true}});
        } else {
            this.modalController.dismiss();
        }
    }
}
