import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dasherDate',
})
export class DasherDatePipe extends DatePipe implements PipeTransform {
  transform(value: any, args: any): string {
    if (!value || value.toString() === '[object Object]') return '--';
    else return super.transform(value, args);
  }
}
