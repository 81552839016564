import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { fromPromise } from 'rxjs/internal-compatibility';
import { catchError, flatMap } from 'rxjs/operators';
import { AuthServerProvider } from './auth-oauth2.service';
import { Router } from '@angular/router';
import { Storage } from '@ionic/storage';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private auth: AuthServerProvider,
    private router: Router,
    private storage: Storage,
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    return fromPromise(this.auth.getAccessToken()).pipe(
      flatMap((token) =>
        next
          .handle(
            request.clone({
              setHeaders: {
                Authorization: 'Bearer ' + (token ? token.access_token : ''),
              },
            }),
          )
          .pipe(catchError((e) => this.handleHttpError(e))),
      ),
    );
  }

  private handleHttpError(err: HttpErrorResponse): Observable<any> {
    if (err.status === 401 && !err.url.endsWith('/account')) {
      // unauthorized
      this.storage.remove('authenticationToken');
      this.router.navigate(['login']);
    }
    return throwError(err);
  }
}
