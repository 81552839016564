import { AuthenticationService } from '../../../sellions_forms/src/authentication.service';
import { CommonModule } from '@angular/common';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FormPage } from './form.page';
import { FormService } from './form.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormViewerComponent } from './form-viewer/form-viewer.component';
import { IonicModule } from '@ionic/angular';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatRadioModule } from '@angular/material/radio';
import { MatTabsModule } from '@angular/material/tabs';
import { NgModule } from '@angular/core';
import { QuestionComponent } from './question/question.component';
import { SellionsFormsModule } from '../../../sellions_forms/projects/forms/src/lib/forms.module';
import { SellionsLayoutRendererLibModule } from '../../../sellions_layout_renderer/projects/sellions-layout-renderer-lib/src/lib/sellions-layout-renderer-lib.module';
import { SharedModule } from '../shared/shared.module';
import { VisibilityModule } from '../visibility/visibility.module';
import {
  MatCardModule,
  MatInputModule,
  MatSelectModule,
} from '@angular/material';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    DragDropModule,
    MatTabsModule,
    MatRadioModule,
    SellionsLayoutRendererLibModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatSelectModule,
    MatInputModule,
    SellionsFormsModule,
    MatCardModule,
    VisibilityModule,
  ],
  declarations: [FormPage, QuestionComponent, FormViewerComponent],
  entryComponents: [FormPage],
  schemas: [],
  exports: [FormViewerComponent],
  providers: [FormService, AuthenticationService],
})
export class FormModule {}
