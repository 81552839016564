import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AuthInterceptor } from './auth/auth.interceptor';
import { AuthModule } from './auth/auth.module';
import { AuthServerProvider } from './auth/auth-oauth2.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { BusinessRulesModule } from './business-rules/business-rules.module';
import { DragulaModule } from 'ng2-dragula';
import { ErrorHandler, NgModule } from '@angular/core';
import { GlobalErrorHandler } from './shared/global-error-handler';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { IonicModule } from '@ionic/angular';
import { IonicStorageModule, Storage } from '@ionic/storage';
import { MenuModule } from './menu/menu.module';
import { ProcessGroupsPageModule } from './process-groups/process-groups.module';
import { ProcessSelectorPageModule } from './process-selector/process-selector.module';
import { Router } from '@angular/router';
import { AnalyticalSpacesModule } from './analytical-spaces/analytical-spaces.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    IonicStorageModule.forRoot(),
    AppRoutingModule,
    DragulaModule.forRoot(),
    HttpClientModule,
    AuthModule,
    MenuModule,
    ProcessSelectorPageModule,
    BusinessRulesModule,
    ProcessGroupsPageModule,
    AnalyticalSpacesModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
      deps: [AuthServerProvider, Router, Storage],
    },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/translations/translations_', '.json');
}
