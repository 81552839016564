import { BaseFieldComponent } from '../base-field/base-field.component';
import { Component, forwardRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'forms-phone-input',
  templateUrl: './phone-input.component.html',
  styleUrls: ['./phone-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PhoneInputComponent),
      multi: true,
    },
  ],
})
export class PhoneInputComponent extends BaseFieldComponent {
  @Input() displayPrefix: boolean = true;
  @Input() prefix: string = '+48';

  public mask(input: HTMLInputElement) {
    this.writeValue(input.value.replace(/[^\d]/g, ''));
    input.value = this._value;
  }
}
