import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { IonicModule } from '@ionic/angular';

import { CardViewComponent } from './card-view.component';
import { CardComponent } from './card/card.component';
import { FormsModule } from '@angular/forms';
import { DetailsItemComponent } from './details-item/details-item.component';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  imports: [CommonModule, FormsModule, IonicModule, SharedModule],
  declarations: [CardViewComponent, CardComponent, DetailsItemComponent],
  exports: [CardViewComponent],
  providers: [],
})
export class CardViewModule {}
