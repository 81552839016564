import { BaseFieldComponent } from '../base-field/base-field.component';
import { Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { mimeTypes } from './mime-types.const';
import { MultipartFile } from './multipart-file';

@Component({
  selector: 'forms-multipart-files',
  templateUrl: './multipart-files.component.html',
  styleUrls: ['./multipart-files.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MultipartFilesComponent),
      multi: true,
    },
  ],
})
export class MultipartFilesComponent extends BaseFieldComponent implements OnInit, OnDestroy {
  @Input() mimeType: string = '';
  @Input() limit = 1;
  public files: Array<MultipartFile> = [];

  ngOnInit() {
    if (this.formField.predefinedValue) {
      this.validatePredefinedValues();
    }

    if (this.formField.validation.minLength && this.formField.validation.maxLength) {
      this.limit = this.formField.validation.maxLength;
    }

    if (this.formField.validation.fileType) this.mimeType = mimeTypes[this.formField.validation.fileType];
  }

  private validatePredefinedValues() {
    const anyPredefinedValueIsNotMultipart = this.checkIfPredefineValueIsMultipart();

    if (anyPredefinedValueIsNotMultipart) {
      throw new Error('All predefined values from field: ' + this.formField.name + 'have to be multipart');
    }

    this.files = this.formField.predefinedValue;
  }

  private checkIfPredefineValueIsMultipart(): boolean {
    return this.formField.predefinedValue.some((multipart) => !(multipart instanceof MultipartFile));
  }

  ngOnDestroy() {
    this.files = [];
  }

  isValidField(): boolean {
    if (!this.formField) return false;

    const id = this.formField.id;
    if (!this.form.controls[id].touched) return true;
    if (this.files.length > this.formField.validation.maxLength) return false;
    if (this.files.length < this.formField.validation.minLength) return false;
    return super.isValidField();
  }

  pushFiles(files: FileList) {
    const multipartFiles = Array.from(files).map((file) => new MultipartFile(file, this.formField.id));
    if (this.limit > 1) {
      this.files = [...this.files, ...multipartFiles];
    } else {
      this.files = multipartFiles;
    }
    this.writeValue(this.files);
  }

  deleteFile(index: number) {
    this.files.splice(index, 1);
    this.writeValue(this.files);
  }
}
