import { BaseFieldComponent } from '../base-field/base-field.component';
import { Component, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'forms-nip',
  templateUrl: './nip.component.html',
  styleUrls: ['./nip.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NipComponent),
      multi: true,
    },
  ],
})
export class NipComponent extends BaseFieldComponent {
  public mask(input: HTMLInputElement) {
    this.writeValue(input.value.replace(/[^\d]/g, ''));
    input.value = this._value;
  }
}
