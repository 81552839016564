export class Visibility {
    constructor(
        public groups: number[][],
        public people: number[],
        public opened = false,
    ) {
    }
}

export interface SimpleVisibility {
    groups: number[];
    people: number[];
}
