import { ContentPage } from '../../../page.interface';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TableDataSource } from './table-data-source.class';
import { take } from 'rxjs/operators';
import { DataSourceParams } from '../interfaces/data-source-params.interface';
import { Filter } from '../interfaces/filter.interface';
import { DataService } from '../../../services/data/data.service';
import { OfflineTableFilterService } from '../../../services/filters/offline-table-filter.service';

export class MixedDataSource extends TableDataSource {
  constructor(protected http: HttpClient, protected dataService: DataService, protected offlineTableFilterService: OfflineTableFilterService) {
    super(http, dataService);
  }
  public loadData(params: DataSourceParams) {
    this.checkParams(params);

    this.loadingSubject.next(true);

    this.loadOnline(params.page, params.size, params.url, params.token, params.sortProperty, params.sortDirection, params.filters).subscribe(
      (elements) => {
        if (Array.isArray(elements)) this.dataSubject.next(elements);
        else {
          this.dataSubject.next(elements.content);
          this.totalNumberOfElementsSubject.next(elements.totalElements);
        }
        this.loadingSubject.next(false);
        this.dataInitializedSubject.next(true);
      },
      (error) => {
        console.warn('Unable to load online data. Loading from local storage. ', error);
        this.loadOffline(params.page, params.size, params.offlineStorageKey, params.sortProperty, params.sortDirection, params.filters);
      },
    );
  }

  private loadOnline(
    page: number,
    size: number,
    url: string,
    token: string,
    sortProperty: string,
    sortDirection: 'ASC' | 'DESC',
    filters: Filter[],
  ): Observable<ContentPage | any[]> {
    this.loadingSubject.next(true);

    let params = new HttpParams().append('page', page.toString()).append('size', size.toString());
    if (filters && filters.length) {
      filters.forEach((filter) => {
        params = params.append(filter.key, filter.value);
      });
    }

    if (sortProperty) {
      sortDirection = sortDirection || 'ASC';
      params = params.append('sort', `${sortProperty},${sortDirection}`);
    }

    return this.http
      .get<ContentPage | any[]>(url, {
        headers: token ? { Authorization: token } : {},
        params,
      })
      .pipe(take(1));
  }

  private loadOffline(page: number, size: number, offlineStorageKey: string, sortProperty: string, sortDirection: 'ASC' | 'DESC', filters: Filter[]) {
    this.loadingSubject.next(true);

    if (sortProperty) {
      console.warn('A sort functionality is not implemented by MixedDataSource.loadOffline yet');
    }

    this.dataService
      .getPage(offlineStorageKey, page * size, size)
      .then((elements) => {
        const isArray = Array.isArray(elements);
        if (!isArray) {
          this.totalNumberOfElementsSubject.next(elements.totalElements);
        }
        let data = isArray ? elements : elements.content;

        if (filters && filters.length) {
          data = this.offlineTableFilterService.filterElements(data, filters);
        }
        this.dataSubject.next(data);
        this.loadingSubject.next(false);
        this.dataInitializedSubject.next(true);
      })
      .catch((error) => {
        this.dataSubject.next([]);
        this.totalNumberOfElementsSubject.next(0);
        this.loadingSubject.next(false);

        throw { message: 'Error fetching online data.', error };
      });
  }

  private checkParams(params: DataSourceParams) {
    if (params.page == undefined) throw 'No page provided in params!';
    if (params.size == undefined) throw 'No size provided in params!';
    if (params.url == undefined) throw 'No url provided in params!';
    if (params.offlineStorageKey == undefined) throw 'No offlineStorageKey provided in params!';
  }
}
