import { ProcessGroupVariable } from './process-group-variable';

export class ProcessGroupDetails {
  constructor(
    public id: number,
    public name: string,
    public visibilities: any,
    public variables: ProcessGroupVariable[],
  ) {}
}
