export enum VariableType {
  STRING = 'STRING',
  LONG = 'LONG',
  DOUBLE = 'DOUBLE',
  BOOLEAN = 'BOOLEAN',
}

export class ProcessGroupVariable {
  constructor(
    public id: number,
    public name: string,
    public type: string,
    public stringValue: String,
    public longValue: number,
    public doubleValue: number,
    public booleanValue: Boolean,
  ) {}

  getValue(): any {}
}
