import { Routes } from '@angular/router';
import { ProcessGroupsListPage } from './process-groups-list-page.component';
import { ProcessGroupsEditPage } from './edit/process-groups-edit.page';

export const processGroupsRoute: Routes = [
  {
    path: '',
    component: ProcessGroupsListPage,
  },
  {
    path: ':id/edit',
    component: ProcessGroupsEditPage,
  },
];
