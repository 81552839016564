import * as PouchDB from 'pouchdb/dist/pouchdb';
import * as upsert from 'pouchdb-upsert';
import { BulkGetOptions } from '../../bulk-get.interface';
import { Injectable } from '@angular/core';
import { Subscriber } from 'rxjs';
import { Utils } from '../../utils/utils.class';

@Injectable()
export class DataService {
  private indexLength = 6;
  private pageSize = 10;
  private database: any;

  constructor() {
    PouchDB.plugin(upsert);
    this.init();
  }

  private init() {
    this.database = new PouchDB('sellions_database', {
      adapter: 'idb',
      size: 20,
      androidDatabaseImplementation: 2,
      revs_limit: 1,
      auto_compaction: true,
    });
  }

  public emitData(
    observer: Subscriber<any>,
    storageKey: string,
    totalRows = 0,
    startKey = Utils.pad(0, this.indexLength)
  ) {
    if (!storageKey) {
      console.error('No storage key provided to emitData()');
      return;
    }

    const endKey = Utils.pad(
      Number(startKey) + this.pageSize - 1,
      this.indexLength
    );
    this.bulkGet({
      startkey: storageKey + startKey,
      endkey: storageKey + endKey,
    })
      .then((elements: any[]) => {
        observer.next(elements);

        if (elements.length < this.pageSize) {
          observer.complete();
        } else {
          const startKey = Utils.pad(Number(endKey) + 1, this.indexLength);
          this.emitData(observer, storageKey, totalRows, startKey);
        }
      })
      .catch((err) => console.error(err));
  }

  public getPage(
    storageKey: string,
    index: number,
    size: number
  ): Promise<any> {
    if (!storageKey) {
      console.error('No storage key provided to getPage()');
      return Promise.resolve();
    }

    const startkey = Utils.pad(index, this.indexLength);
    const endkey = Utils.pad(Number(startkey) + size - 1, this.indexLength);
    return this.bulkGet({ startkey, endkey });
  }

  private bulkGet(options: BulkGetOptions): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this.database.allDocs(
        { ...options, include_docs: true },
        (err: any, response: { rows: { doc: { value: any } }[] }) => {
          if (response) {
            resolve(
              response.rows.map((row: { doc: { value: any } }) => row.doc.value)
            );
          } else {
            reject(err);
          }
        }
      );
    });
  }

  public async saveData(
    values: any[],
    keyFormulationFunction?: (value: any, index?: number) => string
  ): Promise<void> {
    return this.database
      .bulkDocs(
        values.map((value, index) => {
          return { value, _id: keyFormulationFunction(value, index) };
        })
      )
      .catch((err: any) => console.error(err));
  }

  public async saveObject<T>(key: string, value: T): Promise<T> {
    return this.database
      .upsert(key, (document: { value: string }) => {
        document.value = JSON.stringify(value);
        return document;
      })
      .catch((error) => console.error(error));
  }

  public async getObject(key: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.database
        .get(key)
        .then((document: { value: string }) => {
          if (document.value) {
            if (typeof document.value === 'string') {
              resolve(JSON.parse(document.value));
            } else {
              resolve(document.value);
            }
          } else {
            resolve(null);
          }
        })
        .catch((err) => {
          console.error(err);
          reject(err);
        });
    });
  }

  public getUrl(baseUrl: string, api: string): string {
    return baseUrl + api;
  }

  public getIndexLength(): number {
    return this.indexLength;
  }
}
