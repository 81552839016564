import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DataLayout, Field } from '../data-layout';
import { Action } from '../action';
import { CardViewComponent } from '../card-view.component';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'card-view-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
})
export class CardComponent implements OnInit {
  @Input() data;
  @Input() dataLayout: DataLayout;
  @Input() actions: Array<Action>;
  @Input() mode: string;
  @Input() imageUrl: string;
  @Input() imageBytes: string;

  @Output() event = new EventEmitter<() => any>();

  hasImage = false;

  constructor(private http: HttpClient, public domSanitizer: DomSanitizer) {}

  getField(data: any, field: Field) {
    let result = CardViewComponent.getField(data, field);
    return result ? result : '-';
  }

  ngOnInit(): void {
    this.setHasImage();
    if (this.imageUrl) {
      this.downloadImageFromUrl();
    }
  }

  resolveActionIconName(action: Action) {
    if (typeof action.icon == 'function') return action.icon(this.data);
    else return action.icon;
  }

  private setHasImage() {
    if (this.dataLayout.imageUrl || this.dataLayout.imageBytes) {
      this.hasImage = true;
    }
  }

  private downloadImageFromUrl() {
    this.http.get(this.imageUrl, { responseType: 'blob' }).subscribe((i) => {
      this.imageBytes = URL.createObjectURL(i);
    });
  }
}
