import * as moment from 'moment';
import { DictionaryEntry } from '../../../dictionary.interface';
import { FilterComponent } from '../filter/filter.component';
import { FilterOutput } from '../../../interfaces/filter-outpt.interface';
import { FilterSet } from '../../../interfaces/filter-set.interface';
import { FiltersService } from '../../../services/filters/filters.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, Inject, OnInit, QueryList, ViewChildren } from '@angular/core';

@Component({
  selector: 'sellions-layout-renderer-filter-selection',
  templateUrl: './filter-selection.component.html',
})
export class FilterSelectionComponent implements OnInit {
  @ViewChildren(FilterComponent) filterFields: QueryList<FilterComponent>;
  public displayCancelButton = false;
  public appliedFiltersMap: {
    [key: string]: FilterOutput;
  } = {};

  constructor(
    private filtersService: FiltersService,
    public dialogRef: MatDialogRef<FilterSelectionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  ngOnInit() {
    this.filtersService
      .getFilters(this.data.dataSetName)
      .then((filterSet: FilterSet) => {
        this.data.filters.map((filter: FilterOutput) => (this.appliedFiltersMap[filter.column] = this.getValue(filter, filterSet.filters)));
        this.displayCancelButton = this.data.displayCancelButton;
      })
      .catch((error) => console.error(error));
  }

  private getValue(filter: FilterOutput, appliedFilters: FilterOutput[]): FilterOutput {
    return appliedFilters && appliedFilters.find((f: FilterOutput) => f.column === filter.column);
  }

  private getFilters(): FilterOutput[] {
    const filters: FilterOutput[] = [];
    this.filterFields.forEach((field) => {
      if (field.selected) {
        let filter: FilterOutput = {
          column: field.filter.column,
          value: field.selected,
          type: field.filter.type,
          queryValue: {
            column: field.filter.queryValue && field.filter.queryValue.column,
            type: field.filter.queryValue ? field.filter.queryValue.type : 'EQUALS',
            value: this.getFilterValue(field.selected),
          },
        };
        if (field.filter.queryValue && field.filter.queryValue.type === 'NESTED') {
          filter.queryValue.nestedColumn = field.filter.queryValue.nestedColumn;
          filter.queryValue.nestedType = field.filter.queryValue.nestedType;
          if (field.selected[field.filter.queryValue.nestedColumn]) {
            filter.queryValue.value = this.getFilterValue(field.selected[field.filter.queryValue.nestedColumn]);
          }
        }
        filters.push(filter);
      }
    });
    return filters;
  }

  private getFilterValue(value: any | Array<string | DictionaryEntry>): string {
    if (!Array.isArray(value)) {
      if (value instanceof Date)
        return (
          moment(value).startOf('day').format('YYYY-MM-DD[T]HH:mm:ss') +
          'Z' +
          ' AND ' +
          moment(value).endOf('day').format('YYYY-MM-DD[T]HH:mm:ss') +
          'Z'
        );
      else return typeof value === 'string' ? value : value.toString();
    }

    if (typeof value[0] === 'string') {
      return value.join(',');
    }

    // Multi-selection is not implemented in backend, but it is possible get same result using multiple EQUALS filters with option OR
    return value[0].id.toString();
  }

  public applyFilters() {
    this.dialogRef.close(this.getFilters());
  }

  public close() {
    this.dialogRef.close();
  }
}
