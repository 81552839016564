import { HttpClient, HttpParams } from '@angular/common/http';
import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { Observable, Subject, Subscriber } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { DisplayListRow } from '../../interfaces/display-list-row.interface';
import { FilterOutput } from '../../interfaces/filter-outpt.interface';
import { FilterQueryValue } from '../../interfaces/filter-query-value.inteface';
import { FilterSet } from '../../interfaces/filter-set.interface';
import { ListConfig } from '../../interfaces/list-config.interface';
import { ContentPage } from '../../page.interface';
import { DataService } from '../../services/data/data.service';
import { FiltersService } from '../../services/filters/filters.service';
import { SimpleFiltersService } from '../../services/simple-filters/simple-filters.service';
import { Utils } from '../../utils/utils.class';

@Component({
  selector: 'sellions-layout-renderer-list',
  templateUrl: './list.component.html',
})
export class ListRendererComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  @ViewChild('itemList') itemList: ElementRef;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  @Input() config: ListConfig;
  @Input() database: any;
  @Input() noDataText: string;
  @Input() searchQuery: string;
  @Input() sortByKey: string;
  @Input() sortByDirection: 'ASC' | 'DESC';
  @Input() baseUrl: string;
  @Input() emptyListMessage: string;
  @Input() dataProccessingFunction: (data: any) => any = (data: any) => data;
  @Input() urlParams: { [key: string]: string };
  @Input() paginationEnabled: boolean = false;
  @Input() page: number = 0;
  @Input() size: number = 5;
  @Input() sizes: number[] = [5, 10, 15];

  @Output() navigationEvent = new EventEmitter<{
    viewId: string;
    element?: Object;
    objectKey?: Object;
  }>();
  @Output() loadingEvent = new EventEmitter<boolean>();
  @Output() elementClicked = new EventEmitter<{
    element: Object;
    target: EventTarget;
  }>();
  @Output() receivedData = new EventEmitter<any[]>();

  public loading: boolean;
  private unsubscribe$ = new Subject<void>();
  public data: any[] = [];
  public totalElements: number = 0;

  constructor(
    private dataService: DataService,
    private filtersService: FiltersService,
    private simpleFiltersService: SimpleFiltersService,
    private http: HttpClient,
    public elementReference: ElementRef,
  ) {}

  ngOnInit() {
    this.filtersService.filterSetSubject$.pipe(takeUntil(this.unsubscribe$)).subscribe(
      (filterSet: FilterSet) => {
        if (filterSet && filterSet.name === this.config.name) {
          this.getData(this.searchQuery, filterSet && filterSet.filters, filterSet && filterSet.filterFunction);
        } else {
          this.getData(this.searchQuery);
        }
      },
      (error) => console.error(error),
    );

    this.simpleFiltersService
      .getFilter(this.config.name)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((filters: { [key: string]: string }) => this.getOnlineData(this.getDataUrl(), this.searchQuery, undefined, undefined, filters));
  }

  ngOnChanges() {
    this.reload();
  }

  public reload() {
    const filterSet = this.filtersService.filterSetSubject$.value;
    this.getData(this.searchQuery || '', filterSet && filterSet.filters, filterSet && filterSet.filterFunction);
  }

  ngAfterViewInit() {
    const filterSet = this.filtersService.filterSetSubject$.value;

    if (this.paginationEnabled) {
      this.paginator.page.pipe(takeUntil(this.unsubscribe$)).subscribe((page) => {
        this.page = page.pageIndex;
        this.size = page.pageSize;

        this.getData(this.searchQuery || '', filterSet && filterSet.filters, filterSet && filterSet.filterFunction);
      });
    }
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getData(searchQuery: string, filters?: FilterOutput[], filterFunction?: (o: object) => boolean) {
    this.data = [];

    if ((this.config && this.config.dataSourceConfig.operationType === 'OFFLINE') || !this.baseUrl) {
      this.getOfflineData(searchQuery, filters);
    } else {
      const url = this.getDataUrl(filters);
      this.getOnlineData(url, searchQuery, filters, filterFunction);
    }
  }

  private getDataUrl(filters?: FilterOutput[]): string {
    let url = this.dataService.getUrl(this.baseUrl, this.config.dataSourceConfig.onlineDataSourceUrl);
    url = this.addParamsToUrl(url, filters);

    return url;
  }

  private getParamsAsString(): string {
    let str = '';
    Object.keys(this.urlParams).forEach((param) => (str += param + '=' + this.urlParams[param] + '&'));
    return str.slice(0, -1);
  }

  private addParamsToUrl(url: string, filters?: FilterOutput[]): string {
    if (filters) {
      url += this.generateFilterUrl(filters);
    }

    if (this.config.dataSourceConfig.sortProperty && url.indexOf('?filters=') > -1) {
      const sortDirection = this.config.dataSourceConfig.sortDirection || 'ASC';
      url += '&' + this.config.dataSourceConfig.sortProperty + ',' + sortDirection;
    }

    if (this.config.dataSourceConfig.sortProperty && url.indexOf('?filters=') === -1) {
      const sortDirection = this.config.dataSourceConfig.sortDirection || 'ASC';
      url += '&' + this.config.dataSourceConfig.sortProperty + ',' + sortDirection;
    }

    if (this.urlParams) {
      if (url.indexOf('?') > -1) {
        url += '&' + this.getParamsAsString();
      } else {
        url += '?' + this.getParamsAsString();
      }
    }

    return url;
  }

  private generateFilterUrl(filters?: FilterOutput[]): string {
    const queryFilters: FilterQueryValue[] = [];
    filters.forEach((filter) => {
      if (filter.queryValue.value) {
        queryFilters.push(filter.queryValue);
      }
    });

    if (queryFilters.length < 1) {
      return '';
    }

    return '?filters=' + encodeURIComponent(JSON.stringify({ filters: queryFilters }));
  }

  private appendFilters(params: HttpParams, filters: { [key: string]: string }): HttpParams {
    Object.keys(filters).forEach((key) => (params = params.append(key, filters[key])));
    return params;
  }

  private getOnlineData(
    url: string,
    searchQuery: string,
    filters?: FilterOutput[],
    filterFunction?: (o: object) => boolean,
    simpleFilters?: { [key: string]: string },
  ) {
    this.changeLoadingStatus(true);

    let params: HttpParams;
    if (this.paginationEnabled) {
      params = new HttpParams().append('page', this.page.toString()).append('size', this.size.toString());
    }
    if (simpleFilters) {
      if (params) params = this.appendFilters(params, simpleFilters);
      else params = new HttpParams(simpleFilters);
    }

    this.http
      .get<ContentPage>(url, { params })
      .pipe(
        takeUntil(this.unsubscribe$),
        finalize(() => this.changeLoadingStatus(false)),
      )
      .subscribe(
        (data) => {
          this.data = this.processData(data.content, searchQuery, null, filterFunction);
          this.dataService
            .saveData(
              this.data,
              (_value, index) => this.config.dataSourceConfig.offlineStorageKey + Utils.pad(index, this.dataService.getIndexLength()),
            )
            .catch((error) => console.error(error));
          this.receivedData.emit(this.data);

          this.totalElements = data.totalElements;
        },
        (error) => {
          if (this.config.dataSourceConfig.operationType === 'MIXED' && error.status === 0) {
            this.getOfflineData(searchQuery, filters);
          }
          console.error(error);
        },
      );
  }

  private getOfflineData(searchQuery: string, filters?: FilterOutput[], filterFunction?: (o: object) => boolean) {
    this.changeLoadingStatus(true);

    new Observable((observer: Subscriber<any[]>) => this.dataService.emitData(observer, this.config.dataSourceConfig.offlineStorageKey))
      .pipe(
        takeUntil(this.unsubscribe$),
        finalize(() => this.changeLoadingStatus(false)),
      )
      .subscribe(
        (elements: Object[]) => {
          this.data = this.data.concat(this.processData(elements, searchQuery, filters, filterFunction));
          this.receivedData.emit(this.data);
        },
        (error) => console.error(error),
      );
  }

  private processData(elements: Object[], searchQuery?: string, filters?: FilterOutput[], filterFunction?: (o: object) => boolean): Object[] {
    const unsorted = elements
      .map((element) => this.evaluateDisplayStrings(this.dataProccessingFunction ? this.dataProccessingFunction(element) : element) as any)
      .filter(
        (element) =>
          this.checkAgainstQuery(element, searchQuery) &&
          this.checkAgainstFilters(element, filters) &&
          this.checkAgainstFilterFunction(element, filterFunction),
      );

    if (this.sortByKey && this.sortByDirection) {
      return unsorted.sort((a, b) => this.sort(a, b, this.sortByKey, this.sortByDirection));
    } else {
      return unsorted;
    }
  }

  private checkAgainstQuery(element: DisplayListRow, query: string): boolean {
    if (query) {
      const minimalElement = {
        title: element.title,
        subtitle: element.subtitle,
        content: element.content,
      };

      return Utils.checkAgainstQuery(minimalElement, query);
    } else {
      return true;
    }
  }

  private checkAgainstFilters(element: Object, filters: any[]): boolean {
    if (filters) {
      let result = true;
      filters.forEach((filter) => {
        switch (filter.type) {
          case 'DICTIONARY':
            if (!Utils.checkAgainstDictionaryEntry(filter.value, element[filter.name])) result = false;
            break;
          case 'DATE':
            if (!Utils.checkAgainstDate(element[filter.name], filter.value)) result = false;
            break;
          default:
            if (!Utils.checkForStringMatch(element[filter.name], filter.value)) result = false;
            break;
        }
      });

      return result;
    } else {
      return true;
    }
  }

  private sort(elem1: any, elem2: any, sortByKey: string, sortByDirection: 'ASC' | 'DESC'): number {
    if (!sortByKey || !sortByDirection) {
      return -1;
    }

    let a = elem1[sortByKey];
    let b = elem2[sortByKey];

    if (!a || !b) {
      return -1;
    }

    let result = -1;

    if (a.name) {
      a = a.name;
      b = b.name;
    }

    if (a > b) {
      result = -1;
    } else {
      result = 1;
    }

    if (sortByDirection === 'DESC') {
      return result;
    } else {
      return -result;
    }
  }

  private checkAgainstFilterFunction(element: object, filterFunction?: (o: object) => boolean) {
    return filterFunction ? filterFunction(element) : true;
  }

  private evaluateDisplayStrings(object: DisplayListRow): Object {
    object.title = Utils.evaluateString(this.config.displayConfig.rowLayoutMappings.title, object);
    object.subtitle = Utils.evaluateString(this.config.displayConfig.rowLayoutMappings.subtitle, object);
    object.content = Utils.evaluateString(this.config.displayConfig.rowLayoutMappings.content, object);
    if (this.config.displayConfig.rowLayoutMappings.img) {
      object.img = Utils.evaluateString(this.config.displayConfig.rowLayoutMappings.img, object);
    }

    return object;
  }

  public onClick(element: any, $event: MouseEvent) {
    if (!this.config.onClick) {
      return;
    }

    if (this.config.onClick.behaviour === 'CHANGE_VIEW') {
      this.navigationEvent.emit({
        viewId: this.config.onClick.viewId,
        element,
      });
      return;
    }

    if (this.config.onClick.behaviour === 'TRIGGER_ELEMENT_CLICK') {
      this.elementClicked.emit({ element, target: $event.target });
      return;
    }

    console.log('Not implemented yet');
  }

  private changeLoadingStatus(status: boolean): void {
    this.loadingEvent.emit(status);
    this.loading = status;
  }
}
