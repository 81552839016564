import { Column } from '../interfaces/column.interface';
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Filter} from '../interfaces/filter.interface';

@Component({
  selector: 'sellions-layout-renderer-table-filter',
  templateUrl: './table-filter.component.html',
})
export class TableFilterComponent implements OnInit {
  @Input() columns: Column[];
  @Input() filters: Filter[];
  @Output() onApply = new EventEmitter<Filter[]>();

  ngOnInit() {
    if (!this.filters.length) {
      this.columns.forEach(column => {
        if (column.type === 'date') {
          this.addNewFilter(column.filter + 'From', 'date from', this.getPlaceholder(column.filterPlaceholder, 'od'));
          this.addNewFilter(column.filter + 'To', 'date to', this.getPlaceholder(column.filterPlaceholder, 'do'));
        }
        if (column.type === 'string') {
          this.addNewFilter(column.filter, 'string', this.getPlaceholder(column.filterPlaceholder));
        }
      });
    }
  }

  private addNewFilter(key: string, type: string, placeholder: string): void {
    const newFilter: Filter = {key, type, value: ''};
    if (placeholder) {
      newFilter.placeholder = placeholder;
    }
    this.filters.push(newFilter);
  }

  public getPlaceholder(placeholder: string, suffix?: string): string {
    if (!placeholder) {
      return ' ';
    } else {
      return suffix ? placeholder + ' ' + suffix : placeholder;
    }
  }
}
