import { Component } from '@angular/core';
import { ConfigService } from '../config.service';
import { take } from 'rxjs/operators';
import { JsonConfigService } from '../config/json-config.service';
import { mainMenuRoutes, routesRequiringBackend } from '../app-pages';
import { Route } from '@angular/router';
import { UserRouteAccessService } from '../auth/user-route-access-service';
import { environment } from '../../environments/environment';
import { Layout } from './layout.interface';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
})
export class MainComponent {
  private availablePages: Route[] = [];
  public logo: string;

  constructor(private accessService: UserRouteAccessService, private config: ConfigService, private jsonConfigService: JsonConfigService) {}

  ionViewWillEnter() {
    this.config.getConfig().then((config) => (this.logo = config['logo']));
    this.initPagesByUserType();
  }

  hasAuthority(page: Route) {
    return this.accessService.canActivate(page);
  }

  getPages(): Route[] {
    return this.availablePages;
  }

  initPagesByUserType() {
    this.availablePages = [];

    const authorityCheckPromises: Promise<boolean>[] = [];
    const availableRoutes =
      environment.backendUrl !== null ? mainMenuRoutes : mainMenuRoutes.filter((route) => routesRequiringBackend.indexOf(route.path) === -1);

    availableRoutes.forEach((page) => authorityCheckPromises.push(this.hasAuthority(page)));

    Promise.all(authorityCheckPromises)
      .then((canActivateArray: boolean[]) => {
        canActivateArray.forEach((canActivate: boolean, index: number) => {
          if (canActivate) {
            this.availablePages.push(mainMenuRoutes[index]);
          }
        });
        this.initUserPages();
      })
      .catch((error) => console.error('Error while checking authorities pages', error));
  }

  private initUserPages() {
    this.jsonConfigService
      .getCurrentUserLayout()
      .pipe(take(1))
      .subscribe((currentUserLayout) =>
        currentUserLayout.layout.forEach((layout: Layout) => {
          this.availablePages.push(this.createAvailablePage(layout));
        }),
      );
  }

  private createAvailablePage(layout: Layout): Route {
    const pageData = {};
    pageData['path'] = 'generic-data-view/' + layout.view + '/' + layout.viewId;
    const pageProperties = {};
    pageProperties['icon'] = layout.icon;
    pageProperties['title'] = layout.title;
    pageData['data'] = pageProperties;
    return pageData;
  }
}
