import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LayoutConfig } from '../menu/layout.interface';
import { filter, take, tap } from 'rxjs/operators';
import { TableConfig } from '../../../sellions_layout_renderer/projects/sellions-layout-renderer-lib/src/lib/components/table/interfaces/table-config.interface';

@Injectable()
export class JsonConfigService {
  private layoutUrl = location.origin + '/api/config/engine-layouts';
  private screensUrl = location.origin + '/api/config/engine-screens';

  private currentUserLayout$ = new BehaviorSubject<LayoutConfig>(null);
  private currentUserScreens$ = new BehaviorSubject<Map<string, TableConfig>>(null);

  constructor(private http: HttpClient) {}

  public loadCurrentUserLayout(user: { params: { type: string } }): void {
    if (!!user && !!user.params && !!user.params.type) {
      this.getUserLayoutConfig().subscribe((layouts) => {
        this.currentUserLayout$.next(this.getUserLayout(layouts, user.params.type));
      });
    }
  }

  private getUserLayout(layouts: Map<string, LayoutConfig>, userType: string): LayoutConfig {
    const userLayout = layouts[userType];
    return userLayout;
  }

  private getUserLayoutConfig(): Observable<Map<string, LayoutConfig>> {
    return this.http.get<Map<string, LayoutConfig>>(this.layoutUrl).pipe(take(1));
  }

  public getCurrentUserLayout(): Observable<LayoutConfig> {
    return this.currentUserLayout$.pipe(
      filter((layout) => !!layout),
      take(1),
    );
  }

  public getCurrentUserScreens(): Observable<Map<string, TableConfig>> {
    if (this.currentUserScreens$.value != null) {
      return this.getCurrentUserScreenObservable();
    }
    return this.getUserScreenConfig();
  }

  private getCurrentUserScreenObservable(): Observable<Map<string, TableConfig>> {
    return this.currentUserScreens$.pipe(
      filter((screens) => !!screens),
      take(1),
    );
  }

  private getUserScreenConfig(): Observable<Map<string, TableConfig>> {
    return this.http.get<Map<string, TableConfig>>(this.screensUrl).pipe(
      take(1),
      tap((screens) => {
        this.currentUserScreens$.next(screens);
        return screens;
      }),
    );
  }
}
