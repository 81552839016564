import { Component, forwardRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseFieldComponent } from '../base-field/base-field.component';

@Component({
  selector: 'forms-integer',
  templateUrl: './integer.component.html',
  styleUrls: ['./integer.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => IntegerComponent),
      multi: true,
    },
  ],
})
export class IntegerComponent extends BaseFieldComponent {
  @Input() max: number;
  @Input() min: number;
  public displayValue: string = '';

  public mask(input: HTMLInputElement) {
    this.displayValue = this.filterInput(input.value);

    if (input.value !== '-' && Number.isNaN(parseInt(input.value))) {
      input.value = '';
      this.writeValue(undefined);
    } else {
      input.value = this.displayValue;
      this.writeValue(parseInt(input.value));
    }
  }

  private filterInput(value: string): string {
    const sign = value[0] === '-' ? '-' : '';
    value = value.replace(/[^\d]/g, '');
    return sign + value;
  }

  public handleBlur() {
    this.ensureInRange();
    this.setInactive();
  }

  private ensureInRange() {
    if (this.min != null && parseInt(this._value) < this.min) {
      this.writeValue(this.min);
    }
    if (this.max && parseInt(this._value) > this.max) {
      this.writeValue(this.max);
    }
  }
}
