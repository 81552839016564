import { AuthenticationBaseService } from '../../authentication-base.service';
import { ConfigServiceProvider } from '../config/config.service';
import { DictionaryItem } from '../../interfaces/dictionary-item.interface';
import { FormField } from '../../interfaces/form-field.interface';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { take } from 'rxjs/operators';
import { ContentPage } from '../../interfaces/content-page.interface';
import { Utils } from '../../utils/utils.class';

@Injectable()
export class DataSourceOptionsService {
  constructor(@Inject(ConfigServiceProvider)private confService: ConfigServiceProvider, @Inject(HttpClient)private http: HttpClient) {}

  public downloadFieldsOptions(fields: FormField[], authService: AuthenticationBaseService, backendBaseAPI: string): void {
    if (!authService || !backendBaseAPI || !fields) {
      console.error('DataSourceOptionsService: fields, authService or backendBaseAPI is missing');
      return;
    }

    fields.forEach((field: FormField) => this.downloadFieldOptions(field, authService, backendBaseAPI));
  }

  private downloadFieldOptions(field: FormField, authService: AuthenticationBaseService, backendBaseAPI: string): void {
    if (field.dataSource === 'dataView' || field.type === 'reference') {
      this.getReferenceValue(field, authService, backendBaseAPI);
    } else if ((field.dataSource === 'dictionary' || field.type === 'dictionary') && field.dictionaryId) {
      this.getDictionaryItemValues(field, authService, backendBaseAPI);
    } else if (field.type === 'list') {
      this.downloadFieldsOptions(field.subForm.definition, authService, backendBaseAPI);
    }
  }

  private getReferenceValue(field: FormField, authService: AuthenticationBaseService, backendBaseAPI: string): void {
    authService.getAccessToken().then((token) => {
      const accessToken = typeof token === 'object' ? token.access_token : token;
      this.http
        .get<ContentPage>(this.confService.getUrlWithParameter(backendBaseAPI, 'dataView', field.dataView, field.sort), {
          headers: { Authorization: `Bearer ${accessToken}` },
        })
        .pipe(take(1))
        .subscribe(
          (data: ContentPage) => (field.values = data.content.map((elem) => Utils.evaluateReferenceDisplayStrings(elem, field))),
          (error) => console.error(error),
        );
    });
  }

  private getDictionaryItemValues(field: FormField, authService: AuthenticationBaseService, backendBaseAPI: string): void {
    authService.getAccessToken().then((token) => {
      const accessToken = typeof token === 'object' ? token.access_token : token;
      this.http
        .get<DictionaryItem[]>(this.confService.getUrlWithParameter(backendBaseAPI, 'definition', field.dictionaryId, field.sort), {
          headers: { Authorization: `Bearer ${accessToken}` },
        })
        .pipe(take(1))
        .subscribe(
          (values: DictionaryItem[]) => (field.values = values),
          (error) => console.error(error),
        );
    });
  }
}
