import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';
import { CardViewModule } from '../card-view/card-view.module';
import { SharedModule } from '../shared/shared.module';
import { WINDOW_PROVIDERS } from '../shared/window';
import { IonicStorageModule } from '@ionic/storage';
import { VisModule } from 'ngx-vis';
import { AceEditorModule } from 'ng2-ace-editor';
import { VisibilityModule } from '../visibility/visibility.module';
import { RouterModule } from '@angular/router';
import { processGroupsRoute } from './process-groups.route';
import { ProcessGroupsListPage } from './process-groups-list-page.component';
import { ProcessGroupsEditPage } from './edit/process-groups-edit.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    CardViewModule,
    SharedModule,
    IonicStorageModule.forRoot(),
    VisibilityModule,
    VisModule,
    AceEditorModule,
    RouterModule.forChild(processGroupsRoute),
  ],
  declarations: [ProcessGroupsListPage, ProcessGroupsEditPage],
})
export class ProcessGroupsPageModule {}
