import { AlertController } from '@ionic/angular';
import { AuthService } from '../../auth/auth.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LoginService } from '../../auth/login/login.service';
import { Router } from '@angular/router';
import {environment} from "../../../environments/environment";

@Component({
  selector: 'engine-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
})
export class ToolbarComponent implements OnInit {
  @Input() title: string;
  @Input('buttons') buttons: object[];
  @Input() slides: string[];
  @Output() changeSlideAction = new EventEmitter<number>();

  currentSlide: number;
  user: any;

  constructor(private alertController: AlertController, private auth: AuthService, private loginService: LoginService, private router: Router) {}

  ngOnInit() {
    this.user = this.auth.identity;
  }

  logout() {
    this.alertController
      .create({
        header: 'Logout',
        message: 'Do you really want to logout?',
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
            cssClass: 'secondary',
          },
          {
            text: 'Logout',
            handler: () => {
              this.loginService.logout();
              this.router.navigate(['/login']);
            },
          },
        ],
      })
      .then((alert) => alert.present());
  }

  navigateToProfile() {
      window.open(environment.realmUrl + '/account/#/personal-info', '_blank').focus();
  }

  navigateToUserManagement() {
      window.open(environment.consoleUrl, '_blank').focus();
  }

  changeSlide(slide: number) {
    this.currentSlide = slide;
    this.changeSlideAction.emit(slide);
  }
}
